@font-face {
    font-family: '8-bit pusab';
    src: local('8-bit-pusab.ttf'), url('fonts/glacial-indifference/8-bit-pusab.ttf') format('truetype');
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.accountValueColor {
    color: #F25292;
}


.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

/* RESET CSS BEGIN */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    scroll-behavior: smooth;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    color: white;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

section {
    overflow: hidden;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

ellipse
    /* RESET CSS END */
    /* HELPER CLASSES BEGIN */
* {
    box-sizing: border-box;
}

.flex-column {
    flex-direction: column;
}

.alignLeft {
    float: left;
}

.alignRight {
    float: right;
}

.margin-left-60 {
    margin-left: 60px;
}

.margin-right-60 {
    margin-right: 60px;
}

.text-align__left {
    text-align: left
}

.text-align__right {
    text-align: right;
}

.text-align__center {
    text-align: center;
}

strong,
b {
    font-weight: 700;
}

em,
i {
    font-style: italic;
}

p,
ul li,
ol li {
    font-size: 17px;
    line-height: 24px;
}


/* HELPER CLASSES BEGIN */

.alignLeft {
    float: left;
}

.alignRight {
    float: right;
}


p,
ul li,
ol li {
    font-size: 17px;
    line-height: 24px;
}

.width--50--percent {
    width: 50%;
}
.width--80--percent {
    width: 80%;
}
.width--20--percent {
    width: 20%;
}

/* HELPER CLASSES END */


.content {
    width: 77%;
    margin: 50px auto;
    font-family: '8-bit pusab', serif;
    font-size: 17px;
    color: #6c767a;
    line-height: 1.9;
}

@media (min-width: 500px) {
    .content {
        width: 43%;
    }
}

/* BUY AND UP BUTTONS  END*/


/* GENERAL */

h2 {
    font-size: 52px;
    font-weight: 700;
}

.container {
    max-width: 170rem;
    padding: 20px 10px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

#main-section {
    background-size: cover;
    background-position: center;

}

.logo {
    float: left;
}

.navbar {
    float: right;
}

.navbar li {
    display: inline-block;
    margin-left: 15px;
    font-size: 16px;
    font-weight: bold;
    font-family: '8-bit pusab', sans-serif;
}

.navbar li a {
    text-transform: uppercase;
}

.navbar li a:hover {
    border-bottom: 4px solid #2ed47c;
    transition: border-bottom 0.2s, color 0.2s;
}

.navbar li a {
    color: white;
    text-decoration: none;
    font-size: 16px;
}

.navbar li a:last-child {
    font-size: 14px !important;
}

.main-information {
    padding-top: 120px;
}


.withdraw-button {
    color: white;
    font-family: '8-bit pusab', sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
    border: 4px solid white;
    padding: 14px 40px;
    margin-left: 50px;
    min-width: 200px;
}

.invest-button {
    font-family: '8-bit pusab', sans-serif;
    line-height: 18px;
    font-size: 14px;
    color: white;
    text-transform: uppercase;
    text-decoration: none;
    padding: 18px 40px;
    background: #F25292;
    max-width: 200px;
    min-height: 200px;
    margin-left: 5rem;
}

.referral-input {
    font-family: '8-bit pusab', sans-serif;
    line-height: 18px;
    font-size: 14px;
    color: black;
    text-transform: uppercase;
    text-decoration: none;
    padding: 18px 40px;
    background: #ffffff;
    max-width: 500px;
}

.extraClass {
    font-family: '8-bit pusab', sans-serif;
    line-height: 18px;
    font-size: 14px;
    pointer-events: auto !important;
    opacity: 1 !important;
}

.invest-input {
    font-family: '8-bit pusab', sans-serif;
    line-height: 18px;
    font-size: 14px;
    color: black;
    text-transform: uppercase;
    text-decoration: none;
    padding: 18px 40px;
    background: #ffffff;
    max-width: 200px;
}

.download-whitepaper-btn {
    font-family: '8-bit pusab', sans-serif;
    line-height: 18px;
    font-size: 140px;
    color: white;
    text-transform: uppercase;
    text-decoration: none;
    padding: 18px 40px;
    background: #F25292;
    min-width: 200px;
}

.main-information p span {
    color: #5D16EA;
}

.mi-description {
    font-family: '8-bit pusab', sans-serif;
    font-size: 20px;
    line-height: 24px;
}


/* DESCRIPTION SECTION BEGIN */


.text h2 {
    font-family: '8-bit pusab', sans-serif;
    line-height: 40px;
    font-size: 36px;
}

.text h4 {
    font-family: '8-bit pusab', sans-serif;
    line-height: 40px;
    font-size: 30px;
}

.text h5 {
    font-family: '8-bit pusab', sans-serif;
    line-height: 30px;
    font-size: 24px;
}

.text p {
    font-family: '8-bit pusab', sans-serif;
    line-height: 24px;
    font-size: 18px;
}

.box {
    font-family: '8-bit pusab', sans-serif;
    line-height: 24px;
    font-size: 18px;
}

/* SOCIAL MEDIA BEGIN */

#social-media {
    background-color: #111325;
}

.social-media-items {
    position: relative;
    z-index: 10;
    display: grid;
    grid-template-columns: 2fr 40px 2fr;

}

.box-wrapper {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: 20px;
    background-color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #F25292;
}

/* SOCIAL MEDIA END */

/* BUY BEGIN */

.pancake-banner {
    min-height: 120px;
    background-color: #F25292;
    display: flex;
    justify-content: left;
    align-items: center;
    z-index: 10;
    position: relative;
}


[data-scrolled=true] .c-header-desktop {
    height: var(--header-height-scrolled);
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
}

.c-header-desktop__wrapper {
    position: relative;
    z-index: 200;
}

.c-header-desktop__wrapper {
    flex: 1 1 100%;
    min-width: 0;
    width: 100%;
}

.c-header-desktop__container {
    max-width: 170rem;
}

.c-header-desktop__row {
    display: flex;
    align-items: center;
}

.c-header-desktop__logo {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    margin-right: 1.6rem;
}

.c-header-desktop__logo a {
    flex: 0 0 auto;
    min-width: 0;
    max-width: 100%;
    display: block;
    transition: color 250ms ease;
}

.c-header-desktop__logo a:hover, .c-header-desktop__logo a:focus-visible {
    color: rgb(var(--color-quaternary-500));
}

.c-header-desktop__logo img,
.c-header-desktop__logo svg {
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    height: 6.4rem;
}


.pancake-button__footer {
    font-family: '8-bit pusab', sans-serif;
    font-size: 14px;
    line-height: 18px;
    color: #F25292;
    text-transform: uppercase;
    text-decoration: none;
    padding: 18px 40px;
    background: #ffffff;
    min-width: 200px;
}

/* BUY BEGIN */


#copyright p {
    text-align: center;
}

footer {
    background-color: #111325;
}

@media only screen and (max-width: 1040px) {
    .navbar {
        position: absolute;
        right: 30px;
        top: 50px;
        display: block;
    }

    .burger-nav {
        background: url("img/burger.png") no-repeat 100% center;
        display: block;
        width: 40px;
        height: 33px;
        cursor: pointer;
        float: right;
        margin-bottom: 10px;
    }

    .container nav ul {
        height: 0;
        overflow: hidden;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 5px;
    }

    .container nav ul.open {
        height: auto;
        clear: both;
    }

    .container nav ul li {
        float: none;
        text-align: right;
        width: auto;
        margin: 0;
        display: block;
        padding: 10px;
    }

    .container nav ul li:hover {
        background: #2e2e38;
    }

    .container nav ul li a:hover {
        border: none;
    }

    .sticky nav ul li:hover {
        background: #2e2e38;
    }

    .sticky nav ul li a:hover {
        border: none;
    }

    .text h2 {
        font-size: 32px;
        line-height: 36px;
        font-weight: 700;
    }

    .text h4 {
        font-family: '8-bit pusab', sans-serif;
        padding-top: 10px;
        line-height: 24px;
        font-size: 20px;
    }

    .text p {
        padding-right: 10px;
        padding-top: 0px;
        line-height: 20px;
    }


    .mi-description {
        font-family: '8-bit pusab', sans-serif;
        font-size: 16px;
        line-height: 21px;
    }
}

@media only screen and (max-width: 820px) {

    .navbar {
        position: absolute;
        right: 30px;
        top: 50px;
        display: block;
    }

    .withdraw-button {
        margin-top: 20px;
        margin-left: 0;
    }
}

@media only screen and (max-width: 720px) {

    .box-wrapper {
        margin-top: 40px;
    }
}

@media only screen and (max-width: 550px) {

    .container nav ul {
        background-color: rgba(0, 0, 0, 0.85);
    }

    .pancake-banner {
        display: block;
    }

    .pancake-banner h4 {
        text-align: center;
        width: auto;
    }

    .pancake-banner > div {
        margin-left: 0;
        width: auto;
    }

    .pancake-button__banner {
        text-align: center;
        width: auto;
        margin-right: 0;
        margin-top: 30px;
    }

    .pancake-button__footer {
        padding: 12px 30px;
    }

    .main-information p {
        padding-top: 0;
    }
}


@media only screen and (max-width: 230px) {
    .invest-button, .withdraw-button {
        min-width: 150px;
    }
}

@media screen and (prefers-reduced-motion: reduce) {
    html {
        scroll-behavior: auto;
    }
}


.c-header-desktop__wrapper {
    flex: 1 1 100%;
    min-width: 0;
    width: 100%;
}

.c-header-desktop__row {
    display: flex;
    align-items: center;
}


@charset "UTF-8";
:root {
    --color-other-red: 240, 3, 17;
    --color-other-yellow: 240, 173, 3;
    --color-other-green: 25, 204, 82;
    --color-primary-100: 255, 236, 244;
    --color-primary-200: 254, 198, 222;
    --color-primary-300: 253, 159, 201;
    --color-primary-400: 253, 121, 179;
    --color-primary-500: 242, 82, 146;
    --color-primary-600: 251, 10, 115;
    --color-primary-700: 176, 11, 105;
    --color-primary-800: 110, 2, 49;
    --color-primary-900: 37, 1, 16;
    --color-secondary-100: 195, 212, 203;
    --color-secondary-200: 152, 212, 180;
    --color-secondary-300: 110, 212, 157;
    --color-secondary-400: 89, 212, 146;
    --color-secondary-500: 46, 212, 124;
    --color-secondary-600: 35, 161, 94;
    --color-secondary-700: 24, 110, 64;
    --color-secondary-800: 19, 84, 49;
    --color-secondary-900: 7, 33, 19;
    --color-tertiary-100: 217, 209, 235;
    --color-tertiary-200: 186, 162, 235;
    --color-tertiary-300: 155, 115, 235;
    --color-tertiary-400: 124, 68, 235;
    --color-tertiary-500: 93, 22, 234;
    --color-tertiary-600: 72, 17, 184;
    --color-tertiary-700: 34, 43, 92;
    --color-tertiary-800: 37, 43, 69;
    --color-tertiary-900: 12, 3, 31;
    --color-gray-100: 164, 177, 235;
    --color-gray-200: 146, 158, 209;
    --color-gray-300: 128, 138, 184;
    --color-gray-400: 126, 126, 158;
    --color-gray-500: 93, 100, 133;
    --color-gray-600: 86, 86, 107;
    --color-gray-700: 57, 62, 82;
    --color-gray-800: 39, 42, 56;
    --color-gray-900: 21, 23, 31;
    --color-overline: 68, 55, 235;
    --color-heading-dark: 0, 0, 0;
    --color-heading-light: 255, 255, 255;
    --color-black: 0, 0, 0;
    --color-white: 255, 255, 255;
    --color-link-normal: 242, 82, 146;
    --color-link-hover: 176, 11, 105;
    --color-link-active: 93, 22, 234;
    --color-link-focus: 176, 11, 105;
    --font-family-serif: system-ui-serif, serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-sans-serif: "eight-bit-pusab", system-ui, -apple-system, "Segoe UI", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-sans-serif-alt: "eight-bit-pusab", "Roboto", system-ui, -apple-system, "Segoe UI", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-button: "8-bit-pusab", system-ui, -apple-system, "Segoe UI", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-heading: "8-bit-pusab", system-ui, -apple-system, "Segoe UI", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --text-container-spacer: 0;
}

*,
*::before,
*::after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
}

:root {
    font-size: 62.5%;
}

html {
    line-height: 1.4;
    box-sizing: border-box;
    font-size: 62.5%;
}

body {
    font-family: var(--font-family-sans-serif);
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 2;
    background-color: rgb(var(--color-black));
    background-image: url("img/background.png");
    background-size: 1024px 1024px;
    background-repeat: repeat;
    background-position: 0 0;
    color: rgb(var(--color-white));
    animation-name: bg-animation;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    animation-duration: 60s;
}

@media (min-width: 640px) {
    body {
        font-size: 1.2rem;
    }
}

@keyframes bg-animation {
    from {
        background-position: 0px 0px;
    }
    to {
        background-position: 2048px 1024px;
    }
}

main {
    display: block;
}

h1 {
    font-size: 2em;
}

h1, h2, h3, h4, h5, h6 {
    margin-top: var(--text-container-h-top-spacer, 0);
    margin-bottom: var(--text-container-h-bottom-spacer, 0);
}

p {
    margin-top: var(--text-container-spacer, 0);
    margin-bottom: var(--text-container-spacer, 0);
}

a {
    text-decoration: none;
    background-color: transparent;
    color: inherit;
}

abbr[title] {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
}

code,
kbd,
samp,
pre {
    font-family: var(--font-family-monospace);
    font-size: 1em;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

button,
input,
optgroup,
select,
textarea {
    line-height: inherit;
    border: 0;
    outline: 0;
}

button {
    overflow: visible;
    text-transform: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
    -webkit-appearance: button;
    padding: 0.1rem 0.6rem;
}

[role=button] {
    cursor: pointer;
}

input {
    overflow: visible;
}

input,
textarea {
    padding: 0.1rem;
}

fieldset {
    border: 0.1rem solid currentColor;
    margin: 0 0.2rem;
}

legend {
    color: inherit;
    display: table;
    max-width: 100%;
    white-space: normal;
}

progress {
    display: inline-block;
    vertical-align: baseline;
}

select {
    text-transform: none;
}

textarea {
    overflow: auto;
    vertical-align: top;
    resize: vertical;
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -0.2rem;
}

[type=color] {
    background: inherit;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
    height: auto;
}

::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.5;
}

::-webkit-search-decoration,
::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}

::-moz-focus-inner {
    border: 0;
}

:focus-visible,
:-moz-focusring {
    outline: 0;
}

:-moz-ui-invalid {
    box-shadow: none;
}

hr {
    margin: var(--text-container-sep-spacer, 0) 0;
    box-sizing: content-box;
    height: 0;
    color: inherit;
    overflow: visible;
}

dl,
ol,
ul {
    padding-left: 1.5em;
    margin-top: var(--text-container-list-spacer, 0);
    margin-bottom: var(--text-container-list-spacer, 0);
}

ol ol,
ol ul,
ol dl,
ul ol,
ul ul,
ul dl,
dl ol,
dl ul,
dl dl {
    margin: 0;
}

dd {
    margin-bottom: calc(var(--text-container-list-spacer, 0) / 2);
}

blockquote {
    margin: var(--text-container-quote-spacer, 0) 0;
}

b,
strong {
    font-weight: 600;
}

audio,
video {
    display: inline-block;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

figure {
    margin: var(--text-container-image-spacer, 0) 0;
}

img {
    border: 0;
}

svg:not(:root) {
    overflow: hidden;
}

table {
    text-indent: 0;
    border-color: inherit;
}

address {
    margin-bottom: var(--text-container-spacer, 0);
    font-style: normal;
    line-height: inherit;
}

details {
    display: block;
}

dialog {
    background-color: inherit;
    border: solid;
    color: inherit;
    display: block;
    height: fit-content;
    left: 0;
    margin: auto;
    padding: 1em;
    position: absolute;
    right: 0;
    width: fit-content;
}

dialog:not([open]) {
    display: none;
}

summary {
    display: list-item;
}

canvas {
    display: inline-block;
}

template {
    display: none;
}

.hidden,
[hidden] {
    display: none;
}

.text-container {
    --text-container-spacer: 0;
    --text-container-h-top-spacer: 0;
    --text-container-h-bottom-spacer: 0;
    --text-container-list-spacer: 0;
    --text-container-image-spacer: 0;
    --text-container-quote-spacer: 0;
    --text-container-code-spacer: 0;
    --text-container-sep-spacer: 0;
}

.text-container--block-text-small {
    --text-container-spacer: 0.5em;
    --text-container-h-top-spacer: 0.5em;
    --text-container-h-bottom-spacer: 0.5em;
    --text-container-list-spacer: 0.5em;
    --text-container-image-spacer: 0.5em;
    --text-container-quote-spacer: 0.5em;
    --text-container-code-spacer: 0.5em;
    --text-container-sep-spacer: 0.5em;
}

.text-container--block-text-medium {
    --text-container-spacer: 1.2em;
    --text-container-h-top-spacer: 3.2rem;
    --text-container-h-bottom-spacer: 3.2rem;
    --text-container-list-spacer: 1.2em;
    --text-container-image-spacer: 1.2em;
    --text-container-quote-spacer: 1.2em;
    --text-container-code-spacer: 1.2em;
    --text-container-sep-spacer: 1.2em;
}

.text-container--block-text-large {
    --text-container-spacer: 2em;
    --text-container-h-top-spacer: 3.2rem;
    --text-container-h-bottom-spacer: 3.2rem;
    --text-container-list-spacer: 2em;
    --text-container-image-spacer: 2em;
    --text-container-quote-spacer: 2em;
    --text-container-code-spacer: 2em;
    --text-container-sep-spacer: 2em;
}

.text-container--article-mobile {
    --text-container-spacer: 1.2rem;
    --text-container-h-top-spacer: 6.4rem;
    --text-container-h-bottom-spacer: 2.4rem;
    --text-container-list-spacer: 3.2rem;
    --text-container-image-spacer: 2.4rem;
    --text-container-quote-spacer: 1.6em;
    --text-container-code-spacer: 2.4rem;
    --text-container-sep-spacer: 2.4rem;
}

.text-container--article-desktop {
    --text-container-spacer: 1.6rem;
    --text-container-h-top-spacer: 6.4rem;
    --text-container-h-bottom-spacer: 2.4rem;
    --text-container-list-spacer: 4rem;
    --text-container-image-spacer: 3.2rem;
    --text-container-quote-spacer: 1.6em;
    --text-container-code-spacer: 3.2rem;
    --text-container-sep-spacer: 3.2rem;
}

@media (min-width: 360px) {
    .m1\:text-container--block-text-small {
        --text-container-spacer: 0.5em;
        --text-container-h-top-spacer: 0.5em;
        --text-container-h-bottom-spacer: 0.5em;
        --text-container-list-spacer: 0.5em;
        --text-container-image-spacer: 0.5em;
        --text-container-quote-spacer: 0.5em;
        --text-container-code-spacer: 0.5em;
        --text-container-sep-spacer: 0.5em;
    }

    .m1\:text-container--block-text-medium {
        --text-container-spacer: 1.2em;
        --text-container-h-top-spacer: 3.2rem;
        --text-container-h-bottom-spacer: 3.2rem;
        --text-container-list-spacer: 1.2em;
        --text-container-image-spacer: 1.2em;
        --text-container-quote-spacer: 1.2em;
        --text-container-code-spacer: 1.2em;
        --text-container-sep-spacer: 1.2em;
    }

    .m1\:text-container--block-text-large {
        --text-container-spacer: 2em;
        --text-container-h-top-spacer: 3.2rem;
        --text-container-h-bottom-spacer: 3.2rem;
        --text-container-list-spacer: 2em;
        --text-container-image-spacer: 2em;
        --text-container-quote-spacer: 2em;
        --text-container-code-spacer: 2em;
        --text-container-sep-spacer: 2em;
    }

    .m1\:text-container--article-mobile {
        --text-container-spacer: 1.2rem;
        --text-container-h-top-spacer: 6.4rem;
        --text-container-h-bottom-spacer: 2.4rem;
        --text-container-list-spacer: 3.2rem;
        --text-container-image-spacer: 2.4rem;
        --text-container-quote-spacer: 1.6em;
        --text-container-code-spacer: 2.4rem;
        --text-container-sep-spacer: 2.4rem;
    }

    .m1\:text-container--article-desktop {
        --text-container-spacer: 1.6rem;
        --text-container-h-top-spacer: 6.4rem;
        --text-container-h-bottom-spacer: 2.4rem;
        --text-container-list-spacer: 4rem;
        --text-container-image-spacer: 3.2rem;
        --text-container-quote-spacer: 1.6em;
        --text-container-code-spacer: 3.2rem;
        --text-container-sep-spacer: 3.2rem;
    }
}

@media (min-width: 414px) {
    .m2\:text-container--block-text-small {
        --text-container-spacer: 0.5em;
        --text-container-h-top-spacer: 0.5em;
        --text-container-h-bottom-spacer: 0.5em;
        --text-container-list-spacer: 0.5em;
        --text-container-image-spacer: 0.5em;
        --text-container-quote-spacer: 0.5em;
        --text-container-code-spacer: 0.5em;
        --text-container-sep-spacer: 0.5em;
    }

    .m2\:text-container--block-text-medium {
        --text-container-spacer: 1.2em;
        --text-container-h-top-spacer: 3.2rem;
        --text-container-h-bottom-spacer: 3.2rem;
        --text-container-list-spacer: 1.2em;
        --text-container-image-spacer: 1.2em;
        --text-container-quote-spacer: 1.2em;
        --text-container-code-spacer: 1.2em;
        --text-container-sep-spacer: 1.2em;
    }

    .m2\:text-container--block-text-large {
        --text-container-spacer: 2em;
        --text-container-h-top-spacer: 3.2rem;
        --text-container-h-bottom-spacer: 3.2rem;
        --text-container-list-spacer: 2em;
        --text-container-image-spacer: 2em;
        --text-container-quote-spacer: 2em;
        --text-container-code-spacer: 2em;
        --text-container-sep-spacer: 2em;
    }

    .m2\:text-container--article-mobile {
        --text-container-spacer: 1.2rem;
        --text-container-h-top-spacer: 6.4rem;
        --text-container-h-bottom-spacer: 2.4rem;
        --text-container-list-spacer: 3.2rem;
        --text-container-image-spacer: 2.4rem;
        --text-container-quote-spacer: 1.6em;
        --text-container-code-spacer: 2.4rem;
        --text-container-sep-spacer: 2.4rem;
    }

    .m2\:text-container--article-desktop {
        --text-container-spacer: 1.6rem;
        --text-container-h-top-spacer: 6.4rem;
        --text-container-h-bottom-spacer: 2.4rem;
        --text-container-list-spacer: 4rem;
        --text-container-image-spacer: 3.2rem;
        --text-container-quote-spacer: 1.6em;
        --text-container-code-spacer: 3.2rem;
        --text-container-sep-spacer: 3.2rem;
    }
}

@media (min-width: 480px) {
    .m3\:text-container--block-text-small {
        --text-container-spacer: 0.5em;
        --text-container-h-top-spacer: 0.5em;
        --text-container-h-bottom-spacer: 0.5em;
        --text-container-list-spacer: 0.5em;
        --text-container-image-spacer: 0.5em;
        --text-container-quote-spacer: 0.5em;
        --text-container-code-spacer: 0.5em;
        --text-container-sep-spacer: 0.5em;
    }

    .m3\:text-container--block-text-medium {
        --text-container-spacer: 1.2em;
        --text-container-h-top-spacer: 3.2rem;
        --text-container-h-bottom-spacer: 3.2rem;
        --text-container-list-spacer: 1.2em;
        --text-container-image-spacer: 1.2em;
        --text-container-quote-spacer: 1.2em;
        --text-container-code-spacer: 1.2em;
        --text-container-sep-spacer: 1.2em;
    }

    .m3\:text-container--block-text-large {
        --text-container-spacer: 2em;
        --text-container-h-top-spacer: 3.2rem;
        --text-container-h-bottom-spacer: 3.2rem;
        --text-container-list-spacer: 2em;
        --text-container-image-spacer: 2em;
        --text-container-quote-spacer: 2em;
        --text-container-code-spacer: 2em;
        --text-container-sep-spacer: 2em;
    }

    .m3\:text-container--article-mobile {
        --text-container-spacer: 1.2rem;
        --text-container-h-top-spacer: 6.4rem;
        --text-container-h-bottom-spacer: 2.4rem;
        --text-container-list-spacer: 3.2rem;
        --text-container-image-spacer: 2.4rem;
        --text-container-quote-spacer: 1.6em;
        --text-container-code-spacer: 2.4rem;
        --text-container-sep-spacer: 2.4rem;
    }

    .m3\:text-container--article-desktop {
        --text-container-spacer: 1.6rem;
        --text-container-h-top-spacer: 6.4rem;
        --text-container-h-bottom-spacer: 2.4rem;
        --text-container-list-spacer: 4rem;
        --text-container-image-spacer: 3.2rem;
        --text-container-quote-spacer: 1.6em;
        --text-container-code-spacer: 3.2rem;
        --text-container-sep-spacer: 3.2rem;
    }
}

@media (min-width: 576px) {
    .m4\:text-container--block-text-small {
        --text-container-spacer: 0.5em;
        --text-container-h-top-spacer: 0.5em;
        --text-container-h-bottom-spacer: 0.5em;
        --text-container-list-spacer: 0.5em;
        --text-container-image-spacer: 0.5em;
        --text-container-quote-spacer: 0.5em;
        --text-container-code-spacer: 0.5em;
        --text-container-sep-spacer: 0.5em;
    }

    .m4\:text-container--block-text-medium {
        --text-container-spacer: 1.2em;
        --text-container-h-top-spacer: 3.2rem;
        --text-container-h-bottom-spacer: 3.2rem;
        --text-container-list-spacer: 1.2em;
        --text-container-image-spacer: 1.2em;
        --text-container-quote-spacer: 1.2em;
        --text-container-code-spacer: 1.2em;
        --text-container-sep-spacer: 1.2em;
    }

    .m4\:text-container--block-text-large {
        --text-container-spacer: 2em;
        --text-container-h-top-spacer: 3.2rem;
        --text-container-h-bottom-spacer: 3.2rem;
        --text-container-list-spacer: 2em;
        --text-container-image-spacer: 2em;
        --text-container-quote-spacer: 2em;
        --text-container-code-spacer: 2em;
        --text-container-sep-spacer: 2em;
    }

    .m4\:text-container--article-mobile {
        --text-container-spacer: 1.2rem;
        --text-container-h-top-spacer: 6.4rem;
        --text-container-h-bottom-spacer: 2.4rem;
        --text-container-list-spacer: 3.2rem;
        --text-container-image-spacer: 2.4rem;
        --text-container-quote-spacer: 1.6em;
        --text-container-code-spacer: 2.4rem;
        --text-container-sep-spacer: 2.4rem;
    }

    .m4\:text-container--article-desktop {
        --text-container-spacer: 1.6rem;
        --text-container-h-top-spacer: 6.4rem;
        --text-container-h-bottom-spacer: 2.4rem;
        --text-container-list-spacer: 4rem;
        --text-container-image-spacer: 3.2rem;
        --text-container-quote-spacer: 1.6em;
        --text-container-code-spacer: 3.2rem;
        --text-container-sep-spacer: 3.2rem;
    }
}

@media (min-width: 640px) {
    .t1\:text-container--block-text-small {
        --text-container-spacer: 0.5em;
        --text-container-h-top-spacer: 0.5em;
        --text-container-h-bottom-spacer: 0.5em;
        --text-container-list-spacer: 0.5em;
        --text-container-image-spacer: 0.5em;
        --text-container-quote-spacer: 0.5em;
        --text-container-code-spacer: 0.5em;
        --text-container-sep-spacer: 0.5em;
    }

    .t1\:text-container--block-text-medium {
        --text-container-spacer: 1.2em;
        --text-container-h-top-spacer: 3.2rem;
        --text-container-h-bottom-spacer: 3.2rem;
        --text-container-list-spacer: 1.2em;
        --text-container-image-spacer: 1.2em;
        --text-container-quote-spacer: 1.2em;
        --text-container-code-spacer: 1.2em;
        --text-container-sep-spacer: 1.2em;
    }

    .t1\:text-container--block-text-large {
        --text-container-spacer: 2em;
        --text-container-h-top-spacer: 3.2rem;
        --text-container-h-bottom-spacer: 3.2rem;
        --text-container-list-spacer: 2em;
        --text-container-image-spacer: 2em;
        --text-container-quote-spacer: 2em;
        --text-container-code-spacer: 2em;
        --text-container-sep-spacer: 2em;
    }

    .t1\:text-container--article-mobile {
        --text-container-spacer: 1.2rem;
        --text-container-h-top-spacer: 6.4rem;
        --text-container-h-bottom-spacer: 2.4rem;
        --text-container-list-spacer: 3.2rem;
        --text-container-image-spacer: 2.4rem;
        --text-container-quote-spacer: 1.6em;
        --text-container-code-spacer: 2.4rem;
        --text-container-sep-spacer: 2.4rem;
    }

    .t1\:text-container--article-desktop {
        --text-container-spacer: 1.6rem;
        --text-container-h-top-spacer: 6.4rem;
        --text-container-h-bottom-spacer: 2.4rem;
        --text-container-list-spacer: 4rem;
        --text-container-image-spacer: 3.2rem;
        --text-container-quote-spacer: 1.6em;
        --text-container-code-spacer: 3.2rem;
        --text-container-sep-spacer: 3.2rem;
    }
}

@media (min-width: 768px) {
    .t2\:text-container--block-text-small {
        --text-container-spacer: 0.5em;
        --text-container-h-top-spacer: 0.5em;
        --text-container-h-bottom-spacer: 0.5em;
        --text-container-list-spacer: 0.5em;
        --text-container-image-spacer: 0.5em;
        --text-container-quote-spacer: 0.5em;
        --text-container-code-spacer: 0.5em;
        --text-container-sep-spacer: 0.5em;
    }

    .t2\:text-container--block-text-medium {
        --text-container-spacer: 1.2em;
        --text-container-h-top-spacer: 3.2rem;
        --text-container-h-bottom-spacer: 3.2rem;
        --text-container-list-spacer: 1.2em;
        --text-container-image-spacer: 1.2em;
        --text-container-quote-spacer: 1.2em;
        --text-container-code-spacer: 1.2em;
        --text-container-sep-spacer: 1.2em;
    }

    .t2\:text-container--block-text-large {
        --text-container-spacer: 2em;
        --text-container-h-top-spacer: 3.2rem;
        --text-container-h-bottom-spacer: 3.2rem;
        --text-container-list-spacer: 2em;
        --text-container-image-spacer: 2em;
        --text-container-quote-spacer: 2em;
        --text-container-code-spacer: 2em;
        --text-container-sep-spacer: 2em;
    }

    .t2\:text-container--article-mobile {
        --text-container-spacer: 1.2rem;
        --text-container-h-top-spacer: 6.4rem;
        --text-container-h-bottom-spacer: 2.4rem;
        --text-container-list-spacer: 3.2rem;
        --text-container-image-spacer: 2.4rem;
        --text-container-quote-spacer: 1.6em;
        --text-container-code-spacer: 2.4rem;
        --text-container-sep-spacer: 2.4rem;
    }

    .t2\:text-container--article-desktop {
        --text-container-spacer: 1.6rem;
        --text-container-h-top-spacer: 6.4rem;
        --text-container-h-bottom-spacer: 2.4rem;
        --text-container-list-spacer: 4rem;
        --text-container-image-spacer: 3.2rem;
        --text-container-quote-spacer: 1.6em;
        --text-container-code-spacer: 3.2rem;
        --text-container-sep-spacer: 3.2rem;
    }
}

@media (min-width: 812px) {
    .t3\:text-container--block-text-small {
        --text-container-spacer: 0.5em;
        --text-container-h-top-spacer: 0.5em;
        --text-container-h-bottom-spacer: 0.5em;
        --text-container-list-spacer: 0.5em;
        --text-container-image-spacer: 0.5em;
        --text-container-quote-spacer: 0.5em;
        --text-container-code-spacer: 0.5em;
        --text-container-sep-spacer: 0.5em;
    }

    .t3\:text-container--block-text-medium {
        --text-container-spacer: 1.2em;
        --text-container-h-top-spacer: 3.2rem;
        --text-container-h-bottom-spacer: 3.2rem;
        --text-container-list-spacer: 1.2em;
        --text-container-image-spacer: 1.2em;
        --text-container-quote-spacer: 1.2em;
        --text-container-code-spacer: 1.2em;
        --text-container-sep-spacer: 1.2em;
    }

    .t3\:text-container--block-text-large {
        --text-container-spacer: 2em;
        --text-container-h-top-spacer: 3.2rem;
        --text-container-h-bottom-spacer: 3.2rem;
        --text-container-list-spacer: 2em;
        --text-container-image-spacer: 2em;
        --text-container-quote-spacer: 2em;
        --text-container-code-spacer: 2em;
        --text-container-sep-spacer: 2em;
    }

    .t3\:text-container--article-mobile {
        --text-container-spacer: 1.2rem;
        --text-container-h-top-spacer: 6.4rem;
        --text-container-h-bottom-spacer: 2.4rem;
        --text-container-list-spacer: 3.2rem;
        --text-container-image-spacer: 2.4rem;
        --text-container-quote-spacer: 1.6em;
        --text-container-code-spacer: 2.4rem;
        --text-container-sep-spacer: 2.4rem;
    }

    .t3\:text-container--article-desktop {
        --text-container-spacer: 1.6rem;
        --text-container-h-top-spacer: 6.4rem;
        --text-container-h-bottom-spacer: 2.4rem;
        --text-container-list-spacer: 4rem;
        --text-container-image-spacer: 3.2rem;
        --text-container-quote-spacer: 1.6em;
        --text-container-code-spacer: 3.2rem;
        --text-container-sep-spacer: 3.2rem;
    }
}

@media (min-width: 992px) {
    .t4\:text-container--block-text-small {
        --text-container-spacer: 0.5em;
        --text-container-h-top-spacer: 0.5em;
        --text-container-h-bottom-spacer: 0.5em;
        --text-container-list-spacer: 0.5em;
        --text-container-image-spacer: 0.5em;
        --text-container-quote-spacer: 0.5em;
        --text-container-code-spacer: 0.5em;
        --text-container-sep-spacer: 0.5em;
    }

    .t4\:text-container--block-text-medium {
        --text-container-spacer: 1.2em;
        --text-container-h-top-spacer: 3.2rem;
        --text-container-h-bottom-spacer: 3.2rem;
        --text-container-list-spacer: 1.2em;
        --text-container-image-spacer: 1.2em;
        --text-container-quote-spacer: 1.2em;
        --text-container-code-spacer: 1.2em;
        --text-container-sep-spacer: 1.2em;
    }

    .t4\:text-container--block-text-large {
        --text-container-spacer: 2em;
        --text-container-h-top-spacer: 3.2rem;
        --text-container-h-bottom-spacer: 3.2rem;
        --text-container-list-spacer: 2em;
        --text-container-image-spacer: 2em;
        --text-container-quote-spacer: 2em;
        --text-container-code-spacer: 2em;
        --text-container-sep-spacer: 2em;
    }

    .t4\:text-container--article-mobile {
        --text-container-spacer: 1.2rem;
        --text-container-h-top-spacer: 6.4rem;
        --text-container-h-bottom-spacer: 2.4rem;
        --text-container-list-spacer: 3.2rem;
        --text-container-image-spacer: 2.4rem;
        --text-container-quote-spacer: 1.6em;
        --text-container-code-spacer: 2.4rem;
        --text-container-sep-spacer: 2.4rem;
    }

    .t4\:text-container--article-desktop {
        --text-container-spacer: 1.6rem;
        --text-container-h-top-spacer: 6.4rem;
        --text-container-h-bottom-spacer: 2.4rem;
        --text-container-list-spacer: 4rem;
        --text-container-image-spacer: 3.2rem;
        --text-container-quote-spacer: 1.6em;
        --text-container-code-spacer: 3.2rem;
        --text-container-sep-spacer: 3.2rem;
    }
}

@media (min-width: 1200px) {
    .d1\:text-container--block-text-small {
        --text-container-spacer: 0.5em;
        --text-container-h-top-spacer: 0.5em;
        --text-container-h-bottom-spacer: 0.5em;
        --text-container-list-spacer: 0.5em;
        --text-container-image-spacer: 0.5em;
        --text-container-quote-spacer: 0.5em;
        --text-container-code-spacer: 0.5em;
        --text-container-sep-spacer: 0.5em;
    }

    .d1\:text-container--block-text-medium {
        --text-container-spacer: 1.2em;
        --text-container-h-top-spacer: 3.2rem;
        --text-container-h-bottom-spacer: 3.2rem;
        --text-container-list-spacer: 1.2em;
        --text-container-image-spacer: 1.2em;
        --text-container-quote-spacer: 1.2em;
        --text-container-code-spacer: 1.2em;
        --text-container-sep-spacer: 1.2em;
    }

    .d1\:text-container--block-text-large {
        --text-container-spacer: 2em;
        --text-container-h-top-spacer: 3.2rem;
        --text-container-h-bottom-spacer: 3.2rem;
        --text-container-list-spacer: 2em;
        --text-container-image-spacer: 2em;
        --text-container-quote-spacer: 2em;
        --text-container-code-spacer: 2em;
        --text-container-sep-spacer: 2em;
    }

    .d1\:text-container--article-mobile {
        --text-container-spacer: 1.2rem;
        --text-container-h-top-spacer: 6.4rem;
        --text-container-h-bottom-spacer: 2.4rem;
        --text-container-list-spacer: 3.2rem;
        --text-container-image-spacer: 2.4rem;
        --text-container-quote-spacer: 1.6em;
        --text-container-code-spacer: 2.4rem;
        --text-container-sep-spacer: 2.4rem;
    }

    .d1\:text-container--article-desktop {
        --text-container-spacer: 1.6rem;
        --text-container-h-top-spacer: 6.4rem;
        --text-container-h-bottom-spacer: 2.4rem;
        --text-container-list-spacer: 4rem;
        --text-container-image-spacer: 3.2rem;
        --text-container-quote-spacer: 1.6em;
        --text-container-code-spacer: 3.2rem;
        --text-container-sep-spacer: 3.2rem;
    }
}

@media (min-width: 1400px) {
    .d2\:text-container--block-text-small {
        --text-container-spacer: 0.5em;
        --text-container-h-top-spacer: 0.5em;
        --text-container-h-bottom-spacer: 0.5em;
        --text-container-list-spacer: 0.5em;
        --text-container-image-spacer: 0.5em;
        --text-container-quote-spacer: 0.5em;
        --text-container-code-spacer: 0.5em;
        --text-container-sep-spacer: 0.5em;
    }

    .d2\:text-container--block-text-medium {
        --text-container-spacer: 1.2em;
        --text-container-h-top-spacer: 3.2rem;
        --text-container-h-bottom-spacer: 3.2rem;
        --text-container-list-spacer: 1.2em;
        --text-container-image-spacer: 1.2em;
        --text-container-quote-spacer: 1.2em;
        --text-container-code-spacer: 1.2em;
        --text-container-sep-spacer: 1.2em;
    }

    .d2\:text-container--block-text-large {
        --text-container-spacer: 2em;
        --text-container-h-top-spacer: 3.2rem;
        --text-container-h-bottom-spacer: 3.2rem;
        --text-container-list-spacer: 2em;
        --text-container-image-spacer: 2em;
        --text-container-quote-spacer: 2em;
        --text-container-code-spacer: 2em;
        --text-container-sep-spacer: 2em;
    }

    .d2\:text-container--article-mobile {
        --text-container-spacer: 1.2rem;
        --text-container-h-top-spacer: 6.4rem;
        --text-container-h-bottom-spacer: 2.4rem;
        --text-container-list-spacer: 3.2rem;
        --text-container-image-spacer: 2.4rem;
        --text-container-quote-spacer: 1.6em;
        --text-container-code-spacer: 2.4rem;
        --text-container-sep-spacer: 2.4rem;
    }

    .d2\:text-container--article-desktop {
        --text-container-spacer: 1.6rem;
        --text-container-h-top-spacer: 6.4rem;
        --text-container-h-bottom-spacer: 2.4rem;
        --text-container-list-spacer: 4rem;
        --text-container-image-spacer: 3.2rem;
        --text-container-quote-spacer: 1.6em;
        --text-container-code-spacer: 3.2rem;
        --text-container-sep-spacer: 3.2rem;
    }
}

@media (min-width: 1600px) {
    .d3\:text-container--block-text-small {
        --text-container-spacer: 0.5em;
        --text-container-h-top-spacer: 0.5em;
        --text-container-h-bottom-spacer: 0.5em;
        --text-container-list-spacer: 0.5em;
        --text-container-image-spacer: 0.5em;
        --text-container-quote-spacer: 0.5em;
        --text-container-code-spacer: 0.5em;
        --text-container-sep-spacer: 0.5em;
    }

    .d3\:text-container--block-text-medium {
        --text-container-spacer: 1.2em;
        --text-container-h-top-spacer: 3.2rem;
        --text-container-h-bottom-spacer: 3.2rem;
        --text-container-list-spacer: 1.2em;
        --text-container-image-spacer: 1.2em;
        --text-container-quote-spacer: 1.2em;
        --text-container-code-spacer: 1.2em;
        --text-container-sep-spacer: 1.2em;
    }

    .d3\:text-container--block-text-large {
        --text-container-spacer: 2em;
        --text-container-h-top-spacer: 3.2rem;
        --text-container-h-bottom-spacer: 3.2rem;
        --text-container-list-spacer: 2em;
        --text-container-image-spacer: 2em;
        --text-container-quote-spacer: 2em;
        --text-container-code-spacer: 2em;
        --text-container-sep-spacer: 2em;
    }

    .d3\:text-container--article-mobile {
        --text-container-spacer: 1.2rem;
        --text-container-h-top-spacer: 6.4rem;
        --text-container-h-bottom-spacer: 2.4rem;
        --text-container-list-spacer: 3.2rem;
        --text-container-image-spacer: 2.4rem;
        --text-container-quote-spacer: 1.6em;
        --text-container-code-spacer: 2.4rem;
        --text-container-sep-spacer: 2.4rem;
    }

    .d3\:text-container--article-desktop {
        --text-container-spacer: 1.6rem;
        --text-container-h-top-spacer: 6.4rem;
        --text-container-h-bottom-spacer: 2.4rem;
        --text-container-list-spacer: 4rem;
        --text-container-image-spacer: 3.2rem;
        --text-container-quote-spacer: 1.6em;
        --text-container-code-spacer: 3.2rem;
        --text-container-sep-spacer: 3.2rem;
    }
}

.text-container h1, .text-container h2, .text-container h3, .text-container h4, .text-container h5, .text-container h6 {
    font-family: var(--font-family-heading);
    font-weight: 600;
    font-style: normal;
    line-height: 1.4;
}

.text-container h1, .text-container .h1 {
    font-size: 2.6rem;
}

@media (min-width: 640px) {
    .text-container h1, .text-container .h1 {
        font-size: 3.2rem;
    }
}

.text-container h2, .text-container .h2 {
    font-size: 2.3rem;
}

@media (min-width: 640px) {
    .text-container h2, .text-container .h2 {
        font-size: 2.8rem;
    }
}

.text-container h3, .text-container .h3 {
    font-size: 2rem;
}

@media (min-width: 640px) {
    .text-container h3, .text-container .h3 {
        font-size: 2.4rem;
    }
}

.text-container h4, .text-container .h4 {
    font-size: 1.85rem;
}

@media (min-width: 640px) {
    .text-container h4, .text-container .h4 {
        font-size: 2.2rem;
    }
}

.text-container h5, .text-container .h5 {
    font-size: 1.75rem;
}

@media (min-width: 640px) {
    .text-container h5, .text-container .h5 {
        font-size: 2rem;
    }
}

.text-container h6, .text-container .h6 {
    font-size: 1.6rem;
}

@media (min-width: 640px) {
    .text-container h6, .text-container .h6 {
        font-size: 1.8rem;
    }
}

.text-container img {
    max-width: 100%;
    height: auto;
}

.text-container p.has-image {
    margin-top: var(--text-container-image-spacer);
    margin-bottom: var(--text-container-image-spacer);
}

.text-container p.has-image img {
    margin: 0;
}

.text-container figure img {
    width: 100%;
}

.text-container .wp-block-image {
    margin-top: var(--text-container-image-spacer);
    margin-bottom: var(--text-container-image-spacer);
}

.text-container .wp-block-image.size-full {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text-container .wp-block-image.size-full img {
    max-width: none;
    width: 100%;
    height: auto;
    border-radius: 0.8rem;
}

@media (max-width: 991.98px) {
    .text-container .wp-block-image.size-full img {
        border-radius: 0;
        width: 100vw;
    }
}

.text-container .wp-block-image figcaption {
    align-items: flex-start;
    margin: 0.8rem 0 0;
    width: 100%;
    display: block;
    font-size: 1.2rem;
    line-height: 1.5;
    color: rgb(var(--color-gray-700));
}

@media (min-width: 640px) {
    .text-container .wp-block-image figcaption {
        font-size: 1.4rem;
        margin-top: 1rem;
    }
}

.text-container ol > li, .text-container ul > li {
    margin-bottom: 1.2rem;
}

.text-container ul {
    list-style: none;
    padding-left: 0;
}

.text-container ul > li {
    padding-left: 2.6rem;
}

.text-container ul > li::before {
    margin-left: -2.6rem;
    content: "●";
    display: inline-block;
    width: 2.6rem;
    color: rgb(var(--color-primary-500));
}

@media (min-width: 640px) {
    .text-container ul > li::before {
        font-size: 0.5em;
        vertical-align: 0.2em;
    }
}

.text-container > *:first-child {
    margin-top: 0 !important;
}

.text-container > *:last-child {
    margin-bottom: 0 !important;
}

.text-links--blank-primary a[href]:not(.btn):not(.button) {
    color: inherit;
    transition: color 250ms ease;
}

.text-links--blank-primary a[href]:not(.btn):not(.button):hover {
    color: rgb(var(--color-link-normal));
}

.text-links--blank-primary a[href]:not(.btn):not(.button):active {
    color: rgb(var(--color-link-active));
}

.text-links--blank-primary a[href]:not(.btn):not(.button):focus-visible {
    color: rgb(var(--color-link-normal));
}

.text-links--blank-secondary a[href]:not(.btn):not(.button) {
    color: inherit;
    transition: color 250ms ease;
}

.text-links--blank-secondary a[href]:not(.btn):not(.button):hover {
    color: rgb(var(--color-secondary-500));
}

.text-links--blank-secondary a[href]:not(.btn):not(.button):active {
    color: rgb(var(--color-secondary-400));
}

.text-links--blank-secondary a[href]:not(.btn):not(.button):focus-visible {
    color: rgb(var(--color-secondary-500));
}

.text-links--blank-light a[href]:not(.btn):not(.button) {
    color: inherit;
    transition: color 250ms ease;
}

.text-links--blank-light a[href]:not(.btn):not(.button):hover {
    color: rgb(var(--color-white));
}

.text-links--blank-light a[href]:not(.btn):not(.button):active {
    color: rgb(var(--color-white));
}

.text-links--blank-light a[href]:not(.btn):not(.button):focus-visible {
    color: rgb(var(--color-white));
}

.text-links--normal a[href]:not(.btn):not(.button),
.text-links--primary a[href]:not(.btn):not(.button) {
    color: rgb(var(--color-link-normal));
    transition: color 250ms ease;
}

.text-links--normal a[href]:not(.btn):not(.button):hover,
.text-links--primary a[href]:not(.btn):not(.button):hover {
    color: rgb(var(--color-link-hover));
}

.text-links--normal a[href]:not(.btn):not(.button):active,
.text-links--primary a[href]:not(.btn):not(.button):active {
    color: rgb(var(--color-link-active));
}

.text-links--normal a[href]:not(.btn):not(.button):focus-visible,
.text-links--primary a[href]:not(.btn):not(.button):focus-visible {
    color: rgb(var(--color-link-focus));
}

.text-links--secondary a[href]:not(.btn):not(.button) {
    color: rgb(var(--color-secondary-500));
    transition: color 250ms ease;
}

.text-links--secondary a[href]:not(.btn):not(.button):hover {
    color: rgb(var(--color-secondary-700));
}

.text-links--secondary a[href]:not(.btn):not(.button):active {
    color: rgb(var(--color-secondary-400));
}

.text-links--secondary a[href]:not(.btn):not(.button):focus-visible {
    color: rgb(var(--color-secondary-700));
}

.text-links--underline a[href]:not(.btn):not(.button) {
    text-decoration: underline;
}

.text-links--underline-hover a[href]:not(.btn):not(.button):hover, .text-links--underline-hover a[href]:not(.btn):not(.button):focus-visible {
    text-decoration: underline;
}

.list-none {
    list-style: none;
    padding: 0;
}

.text-heading-1 {
    font-family: var(--font-family-heading);
    font-style: normal;
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 1.5;
    letter-spacing: 0;
}

.text-heading-1 strong, .text-heading-1 b {
    font-weight: 700;
}

@media (min-width: 640px) {
    .text-heading-1 {
        font-size: 4rem;
        line-height: 1.5;
        letter-spacing: 0;
    }
}

@media (min-width: 1200px) {
    .text-heading-1 {
        font-size: 5.6rem;
        line-height: 1.5;
        letter-spacing: 0;
    }
}

.text-heading-2 {
    font-family: var(--font-family-heading);
    font-style: normal;
    font-weight: 400;
    font-size: 2.2rem;
    line-height: 1.5;
    letter-spacing: 0;
}

.text-heading-2 strong, .text-heading-2 b {
    font-weight: 700;
}

@media (min-width: 640px) {
    .text-heading-2 {
        font-size: 2.6rem;
        line-height: 1.5;
        letter-spacing: 0;
    }
}

@media (min-width: 1200px) {
    .text-heading-2 {
        font-size: 3.2rem;
        line-height: 1.5;
        letter-spacing: 0;
    }
}

.text-heading-3 {
    font-family: var(--font-family-heading);
    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    line-height: 1.5;
    letter-spacing: 0;
}

.text-heading-3 strong, .text-heading-3 b {
    font-weight: 700;
}

@media (min-width: 640px) {
    .text-heading-3 {
        font-size: 2.2rem;
        line-height: 1.5;
        letter-spacing: 0;
    }
}

@media (min-width: 1200px) {
    .text-heading-3 {
        font-size: 2.4rem;
        line-height: 1.5;
        letter-spacing: 0;
    }
}

.text-heading-4 {
    font-family: var(--font-family-heading);
    font-style: normal;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 1.5;
    letter-spacing: 0;
}

.text-heading-4 strong, .text-heading-4 b {
    font-weight: 700;
}

@media (min-width: 640px) {
    .text-heading-4 {
        font-size: 2rem;
        line-height: 1.5;
        letter-spacing: 0;
    }
}

@media (min-width: 1200px) {
    .text-heading-4 {
        font-size: 2.2rem;
        line-height: 1.5;
        letter-spacing: 0;
    }
}

.text-heading-5 {
    font-family: var(--font-family-heading);
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.5;
    letter-spacing: 0;
}

.text-heading-5 strong, .text-heading-5 b {
    font-weight: 700;
}

@media (min-width: 640px) {
    .text-heading-5 {
        font-size: 1.8rem;
        line-height: 1.5;
        letter-spacing: 0;
    }
}

@media (min-width: 1200px) {
    .text-heading-5 {
        font-size: 2rem;
        line-height: 1.5;
        letter-spacing: 0;
    }
}

.text-heading-6 {
    font-family: var(--font-family-heading);
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.5;
    letter-spacing: 0;
}

.text-heading-6 strong, .text-heading-6 b {
    font-weight: 700;
}

@media (min-width: 640px) {
    .text-heading-6 {
        font-size: 1.6rem;
        line-height: 1.5;
        letter-spacing: 0;
    }
}

@media (min-width: 1200px) {
    .text-heading-6 {
        font-size: 1.8rem;
        line-height: 1.5;
        letter-spacing: 0;
    }
}

.text-subheading-1 {
    font-family: var(--font-family-heading);
    font-style: normal;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 1.25;
    letter-spacing: 0;
}

.text-subheading-2 {
    font-family: var(--font-family-heading);
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 1.6;
    letter-spacing: 0;
}

@media (min-width: 640px) {
    .text-subheading-2 {
        font-size: 1.8rem;
    }
}

.text-subheading-2 strong, .text-subheading-2 b {
    font-weight: 600;
}

.text-body-very-small {
    font-family: var(--font-family-sans-serif);
    font-style: normal;
    font-weight: normal;
    font-size: 0.7rem;
    line-height: 2;
}

@media (min-width: 640px) {
    .text-body-very-small {
        font-size: 0.8rem;
    }
}

.text-body-small {
    font-family: var(--font-family-sans-serif);
    font-style: normal;
    font-weight: normal;
    font-size: 0.8rem;
    line-height: 2;
}

@media (min-width: 640px) {
    .text-body-small {
        font-size: 1rem;
    }
}

.text-body-normal {
    font-family: var(--font-family-sans-serif);
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 2;
}

@media (min-width: 640px) {
    .text-body-normal {
        font-size: 1.2rem;
    }
}

.text-body-large {
    font-family: var(--font-family-sans-serif);
    font-style: normal;
    font-weight: normal;
    font-size: 1.8rem;
    line-height: 1.5;
    letter-spacing: 0.01em;
}

.text-caption {
    font-family: var(--font-family-heading);
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 1.3333333333;
    letter-spacing: 0.004em;
}

.text-overline {
    font-family: var(--font-family-heading);
    font-style: normal;
    font-weight: 800;
    font-size: 1.4rem;
    line-height: 1.4;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

@media (min-width: 768px) {
    .text-overline {
        font-size: 1.6rem;
    }
}

.text-button {
    font-family: var(--font-family-button);
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.1428571429;
    letter-spacing: 0.015em;
    text-transform: uppercase;
}

.section-header--spacer-small {
    margin-bottom: 3.2rem;
}

.section-header--spacer-normal {
    margin-bottom: 4.8rem;
}

@media (min-width: 768px) {
    .section-header--spacer-normal {
        margin-bottom: 5.6rem;
    }
}

@media (min-width: 1200px) {
    .section-header--spacer-normal {
        margin-bottom: 6.4rem;
    }
}

.section-header--spacer-large {
    margin-bottom: 4rem;
}

@media (min-width: 768px) {
    .section-header--spacer-large {
        margin-bottom: 5.2rem;
    }
}

@media (min-width: 768px) {
    .section-header--spacer-large {
        margin-bottom: 6.4rem;
    }
}

.section-header__overline {
    margin-bottom: 0.8rem;
}

.section-header__heading {
    color: rgb(var(--color-heading-light));
    position: relative;
    z-index: 1;
    margin-bottom: 1.6rem;
}

.section-header__heading::before {
    content: "";
    display: block;
}

.section-header__separator {
    display: inline-block;
    width: 5.6rem;
    height: 0.5rem;
    background-color: rgb(var(--color-primary-500));
    margin-bottom: 2rem;
}

.section-header__text {
    color: rgba(var(--color-white), 0.7);
}

.section-header__text .text-color-lighter {
    color: rgb(var(--color-white));
}

.text-container.text-white-65 .text-color-lighter, .text-container.text-white-70 .text-color-lighter {
    color: rgb(var(--color-white));
}

.text-white-70 {
    color: rgba(var(--color-white), 0.7);
}

.text-white-65 {
    color: rgba(var(--color-white), 0.65);
}

.custom-separator {
    margin-left: 0.5em;
    margin-right: 0.5em;
    color: rgb(var(--color-primary-500));
}

.img-fluid {
    display: block;
    max-width: 100%;
    width: auto;
    height: auto;
}

.img-full-width {
    display: block;
    max-width: 100%;
    width: 100%;
    height: auto;
}

.no-thumb {
    object-fit: contain !important;
}

.container,
.container-fluid {
    width: 100%;
    padding-right: 2.4rem;
    padding-left: 2.4rem;
    margin-right: auto;
    margin-left: auto;
}

.container,
.container-fluid {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
}

.container {
    max-width: 100%;
}

@media (min-width: 640px) {
    .container {
        max-width: 608px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 736px;
    }
}

@media (min-width: 812px) {
    .container {
        max-width: 780px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

@media (min-width: 1400px) {
    .container {
        max-width: 1218px;
    }
}

.container.container-m0 {
    max-width: 100%;
}

.container-fluid.container-m0 {
    max-width: 100%;
}

@media (min-width: 640px) {
    .container.container-t1 {
        max-width: 608px;
    }
}

.container-fluid.container-t1 {
    max-width: 608px;
}

@media (min-width: 768px) {
    .container.container-t2 {
        max-width: 736px;
    }
}

.container-fluid.container-t2 {
    max-width: 736px;
}

@media (min-width: 812px) {
    .container.container-t3 {
        max-width: 780px;
    }
}

.container-fluid.container-t3 {
    max-width: 780px;
}

@media (min-width: 992px) {
    .container.container-t4 {
        max-width: 960px;
    }
}

.container-fluid.container-t4 {
    max-width: 960px;
}

@media (min-width: 1200px) {
    .container.container-d1 {
        max-width: 1140px;
    }
}

.container-fluid.container-d1 {
    max-width: 1140px;
}

@media (min-width: 1400px) {
    .container.container-d2 {
        max-width: 1218px;
    }
}

.container-fluid.container-d2 {
    max-width: 1218px;
}

.row,
.btns {
    --tn-row-columns: 1;
    --tn-row-gap-x: 0rem;
    --tn-row-gap-y: 0rem;
    display: flex;
    flex: 1 0 100%;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin-top: calc(var(--tn-row-gap-y, 0rem) * -1);
    margin-left: calc(var(--tn-row-gap-x, 0rem) / -2);
    margin-right: calc(var(--tn-row-gap-x, 0rem) / -2);
}

.col {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: calc(var(--tn-row-gap-x, 0rem) / 2);
    padding-left: calc(var(--tn-row-gap-x, 0rem) / 2);
    margin-top: var(--tn-row-gap-y, 0rem);
    min-width: 0;
    max-width: 100%;
    width: calc(1 / var(--tn-row-columns, 12) * 100%);
}

.cols-1 {
    --tn-row-columns: 1;
}

.cols-2 {
    --tn-row-columns: 2;
}

.cols-3 {
    --tn-row-columns: 3;
}

.cols-4 {
    --tn-row-columns: 4;
}

.cols-5 {
    --tn-row-columns: 5;
}

.cols-6 {
    --tn-row-columns: 6;
}

.cols-7 {
    --tn-row-columns: 7;
}

.cols-8 {
    --tn-row-columns: 8;
}

.cols-9 {
    --tn-row-columns: 9;
}

.cols-10 {
    --tn-row-columns: 10;
}

.cols-11 {
    --tn-row-columns: 11;
}

.cols-12 {
    --tn-row-columns: 12;
}

.col-auto {
    max-width: 100%;
    width: auto;
    flex: 0 0 auto;
}

.col-auto-fit {
    max-width: 100%;
    width: 100%;
    flex: 1 0 0%;
}

.col-auto-fill {
    max-width: 100%;
    width: auto;
    flex: 1 1 auto;
}

.col-full {
    max-width: 100%;
    width: 100%;
    flex: 0 0 auto;
}

.col-1 {
    max-width: none;
    width: calc(1 / var(--tn-row-columns, 12) * 100%);
    flex: 0 0 auto;
}

.col-2 {
    max-width: none;
    width: calc(2 / var(--tn-row-columns, 12) * 100%);
    flex: 0 0 auto;
}

.col-3 {
    max-width: none;
    width: calc(3 / var(--tn-row-columns, 12) * 100%);
    flex: 0 0 auto;
}

.col-4 {
    max-width: none;
    width: calc(4 / var(--tn-row-columns, 12) * 100%);
    flex: 0 0 auto;
}

.col-5 {
    max-width: none;
    width: calc(5 / var(--tn-row-columns, 12) * 100%);
    flex: 0 0 auto;
}

.col-6 {
    max-width: none;
    width: calc(6 / var(--tn-row-columns, 12) * 100%);
    flex: 0 0 auto;
}

.col-7 {
    max-width: none;
    width: calc(7 / var(--tn-row-columns, 12) * 100%);
    flex: 0 0 auto;
}

.col-8 {
    max-width: none;
    width: calc(8 / var(--tn-row-columns, 12) * 100%);
    flex: 0 0 auto;
}

.col-9 {
    max-width: none;
    width: calc(9 / var(--tn-row-columns, 12) * 100%);
    flex: 0 0 auto;
}

.col-10 {
    max-width: none;
    width: calc(10 / var(--tn-row-columns, 12) * 100%);
    flex: 0 0 auto;
}

.col-11 {
    max-width: none;
    width: calc(11 / var(--tn-row-columns, 12) * 100%);
    flex: 0 0 auto;
}

.col-12 {
    max-width: none;
    width: calc(12 / var(--tn-row-columns, 12) * 100%);
    flex: 0 0 auto;
}

.offset-1 {
    margin-left: calc(1 / var(--tn-row-columns, 12) * 100%);
}

.offset-2 {
    margin-left: calc(2 / var(--tn-row-columns, 12) * 100%);
}

.offset-3 {
    margin-left: calc(3 / var(--tn-row-columns, 12) * 100%);
}

.offset-4 {
    margin-left: calc(4 / var(--tn-row-columns, 12) * 100%);
}

.offset-5 {
    margin-left: calc(5 / var(--tn-row-columns, 12) * 100%);
}

.offset-6 {
    margin-left: calc(6 / var(--tn-row-columns, 12) * 100%);
}

.offset-7 {
    margin-left: calc(7 / var(--tn-row-columns, 12) * 100%);
}

.offset-8 {
    margin-left: calc(8 / var(--tn-row-columns, 12) * 100%);
}

.offset-9 {
    margin-left: calc(9 / var(--tn-row-columns, 12) * 100%);
}

.offset-10 {
    margin-left: calc(10 / var(--tn-row-columns, 12) * 100%);
}

.offset-11 {
    margin-left: calc(11 / var(--tn-row-columns, 12) * 100%);
}

.row.gap-0,
.row.gap-x-0 {
    --tn-row-gap-x: 0rem;
}

.row.gap-0,
.row.gap-y-0 {
    --tn-row-gap-y: 0rem;
}

.row.gap-8,
.row.gap-x-8 {
    --tn-row-gap-x: 0.8rem;
}

.row.gap-8,
.row.gap-y-8 {
    --tn-row-gap-y: 0.8rem;
}

.row.gap-16,
.row.gap-x-16 {
    --tn-row-gap-x: 1.6rem;
}

.row.gap-16,
.row.gap-y-16 {
    --tn-row-gap-y: 1.6rem;
}

.row.gap-24,
.row.gap-x-24 {
    --tn-row-gap-x: 2.4rem;
}

.row.gap-24,
.row.gap-y-24 {
    --tn-row-gap-y: 2.4rem;
}

.row.gap-32,
.row.gap-x-32 {
    --tn-row-gap-x: 3.2rem;
}

.row.gap-32,
.row.gap-y-32 {
    --tn-row-gap-y: 3.2rem;
}

.row.gap-40,
.row.gap-x-40 {
    --tn-row-gap-x: 4rem;
}

.row.gap-40,
.row.gap-y-40 {
    --tn-row-gap-y: 4rem;
}

.row.gap-48,
.row.gap-x-48 {
    --tn-row-gap-x: 4.8rem;
}

.row.gap-48,
.row.gap-y-48 {
    --tn-row-gap-y: 4.8rem;
}

.btns.gap-0,
.btns.gap-x-0 {
    --tn-row-gap-x: 0rem;
}

.btns.gap-0,
.btns.gap-y-0 {
    --tn-row-gap-y: 0rem;
}

@media (min-width: 360px) {
    .m1\:cols-1 {
        --tn-row-columns: 1;
    }

    .m1\:cols-2 {
        --tn-row-columns: 2;
    }

    .m1\:cols-3 {
        --tn-row-columns: 3;
    }

    .m1\:cols-4 {
        --tn-row-columns: 4;
    }

    .m1\:cols-5 {
        --tn-row-columns: 5;
    }

    .m1\:cols-6 {
        --tn-row-columns: 6;
    }

    .m1\:cols-7 {
        --tn-row-columns: 7;
    }

    .m1\:cols-8 {
        --tn-row-columns: 8;
    }

    .m1\:cols-9 {
        --tn-row-columns: 9;
    }

    .m1\:cols-10 {
        --tn-row-columns: 10;
    }

    .m1\:cols-11 {
        --tn-row-columns: 11;
    }

    .m1\:cols-12 {
        --tn-row-columns: 12;
    }

    .m1\:col-auto {
        max-width: 100%;
        width: auto;
        flex: 0 0 auto;
    }

    .m1\:col-auto-fit {
        max-width: 100%;
        width: 100%;
        flex: 1 0 0%;
    }

    .m1\:col-auto-fill {
        max-width: 100%;
        width: auto;
        flex: 1 1 auto;
    }

    .m1\:col-full {
        max-width: 100%;
        width: 100%;
        flex: 0 0 auto;
    }

    .m1\:col-1 {
        max-width: none;
        width: calc(1 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m1\:col-2 {
        max-width: none;
        width: calc(2 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m1\:col-3 {
        max-width: none;
        width: calc(3 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m1\:col-4 {
        max-width: none;
        width: calc(4 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m1\:col-5 {
        max-width: none;
        width: calc(5 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m1\:col-6 {
        max-width: none;
        width: calc(6 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m1\:col-7 {
        max-width: none;
        width: calc(7 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m1\:col-8 {
        max-width: none;
        width: calc(8 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m1\:col-9 {
        max-width: none;
        width: calc(9 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m1\:col-10 {
        max-width: none;
        width: calc(10 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m1\:col-11 {
        max-width: none;
        width: calc(11 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m1\:col-12 {
        max-width: none;
        width: calc(12 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m1\:offset-0 {
        margin-left: 0;
    }

    .m1\:offset-1 {
        margin-left: calc(1 / var(--tn-row-columns, 12) * 100%);
    }

    .m1\:offset-2 {
        margin-left: calc(2 / var(--tn-row-columns, 12) * 100%);
    }

    .m1\:offset-3 {
        margin-left: calc(3 / var(--tn-row-columns, 12) * 100%);
    }

    .m1\:offset-4 {
        margin-left: calc(4 / var(--tn-row-columns, 12) * 100%);
    }

    .m1\:offset-5 {
        margin-left: calc(5 / var(--tn-row-columns, 12) * 100%);
    }

    .m1\:offset-6 {
        margin-left: calc(6 / var(--tn-row-columns, 12) * 100%);
    }

    .m1\:offset-7 {
        margin-left: calc(7 / var(--tn-row-columns, 12) * 100%);
    }

    .m1\:offset-8 {
        margin-left: calc(8 / var(--tn-row-columns, 12) * 100%);
    }

    .m1\:offset-9 {
        margin-left: calc(9 / var(--tn-row-columns, 12) * 100%);
    }

    .m1\:offset-10 {
        margin-left: calc(10 / var(--tn-row-columns, 12) * 100%);
    }

    .m1\:offset-11 {
        margin-left: calc(11 / var(--tn-row-columns, 12) * 100%);
    }

    .row.m1\:gap-0,
    .row.m1\:gap-x-0 {
        --tn-row-gap-x: 0rem;
    }

    .row.m1\:gap-0,
    .row.m1\:gap-y-0 {
        --tn-row-gap-y: 0rem;
    }

    .row.m1\:gap-8,
    .row.m1\:gap-x-8 {
        --tn-row-gap-x: 0.8rem;
    }

    .row.m1\:gap-8,
    .row.m1\:gap-y-8 {
        --tn-row-gap-y: 0.8rem;
    }

    .row.m1\:gap-16,
    .row.m1\:gap-x-16 {
        --tn-row-gap-x: 1.6rem;
    }

    .row.m1\:gap-16,
    .row.m1\:gap-y-16 {
        --tn-row-gap-y: 1.6rem;
    }

    .row.m1\:gap-24,
    .row.m1\:gap-x-24 {
        --tn-row-gap-x: 2.4rem;
    }

    .row.m1\:gap-24,
    .row.m1\:gap-y-24 {
        --tn-row-gap-y: 2.4rem;
    }

    .row.m1\:gap-32,
    .row.m1\:gap-x-32 {
        --tn-row-gap-x: 3.2rem;
    }

    .row.m1\:gap-32,
    .row.m1\:gap-y-32 {
        --tn-row-gap-y: 3.2rem;
    }

    .row.m1\:gap-40,
    .row.m1\:gap-x-40 {
        --tn-row-gap-x: 4rem;
    }

    .row.m1\:gap-40,
    .row.m1\:gap-y-40 {
        --tn-row-gap-y: 4rem;
    }

    .row.m1\:gap-48,
    .row.m1\:gap-x-48 {
        --tn-row-gap-x: 4.8rem;
    }

    .row.m1\:gap-48,
    .row.m1\:gap-y-48 {
        --tn-row-gap-y: 4.8rem;
    }

    .btns.m1\:gap-0,
    .btns.m1\:gap-x-0 {
        --tn-row-gap-x: 0rem;
    }

    .btns.m1\:gap-0,
    .btns.m1\:gap-y-0 {
        --tn-row-gap-y: 0rem;
    }
}

@media (min-width: 414px) {
    .m2\:cols-1 {
        --tn-row-columns: 1;
    }

    .m2\:cols-2 {
        --tn-row-columns: 2;
    }

    .m2\:cols-3 {
        --tn-row-columns: 3;
    }

    .m2\:cols-4 {
        --tn-row-columns: 4;
    }

    .m2\:cols-5 {
        --tn-row-columns: 5;
    }

    .m2\:cols-6 {
        --tn-row-columns: 6;
    }

    .m2\:cols-7 {
        --tn-row-columns: 7;
    }

    .m2\:cols-8 {
        --tn-row-columns: 8;
    }

    .m2\:cols-9 {
        --tn-row-columns: 9;
    }

    .m2\:cols-10 {
        --tn-row-columns: 10;
    }

    .m2\:cols-11 {
        --tn-row-columns: 11;
    }

    .m2\:cols-12 {
        --tn-row-columns: 12;
    }

    .m2\:col-auto {
        max-width: 100%;
        width: auto;
        flex: 0 0 auto;
    }

    .m2\:col-auto-fit {
        max-width: 100%;
        width: 100%;
        flex: 1 0 0%;
    }

    .m2\:col-auto-fill {
        max-width: 100%;
        width: auto;
        flex: 1 1 auto;
    }

    .m2\:col-full {
        max-width: 100%;
        width: 100%;
        flex: 0 0 auto;
    }

    .m2\:col-1 {
        max-width: none;
        width: calc(1 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m2\:col-2 {
        max-width: none;
        width: calc(2 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m2\:col-3 {
        max-width: none;
        width: calc(3 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m2\:col-4 {
        max-width: none;
        width: calc(4 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m2\:col-5 {
        max-width: none;
        width: calc(5 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m2\:col-6 {
        max-width: none;
        width: calc(6 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m2\:col-7 {
        max-width: none;
        width: calc(7 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m2\:col-8 {
        max-width: none;
        width: calc(8 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m2\:col-9 {
        max-width: none;
        width: calc(9 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m2\:col-10 {
        max-width: none;
        width: calc(10 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m2\:col-11 {
        max-width: none;
        width: calc(11 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m2\:col-12 {
        max-width: none;
        width: calc(12 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m2\:offset-0 {
        margin-left: 0;
    }

    .m2\:offset-1 {
        margin-left: calc(1 / var(--tn-row-columns, 12) * 100%);
    }

    .m2\:offset-2 {
        margin-left: calc(2 / var(--tn-row-columns, 12) * 100%);
    }

    .m2\:offset-3 {
        margin-left: calc(3 / var(--tn-row-columns, 12) * 100%);
    }

    .m2\:offset-4 {
        margin-left: calc(4 / var(--tn-row-columns, 12) * 100%);
    }

    .m2\:offset-5 {
        margin-left: calc(5 / var(--tn-row-columns, 12) * 100%);
    }

    .m2\:offset-6 {
        margin-left: calc(6 / var(--tn-row-columns, 12) * 100%);
    }

    .m2\:offset-7 {
        margin-left: calc(7 / var(--tn-row-columns, 12) * 100%);
    }

    .m2\:offset-8 {
        margin-left: calc(8 / var(--tn-row-columns, 12) * 100%);
    }

    .m2\:offset-9 {
        margin-left: calc(9 / var(--tn-row-columns, 12) * 100%);
    }

    .m2\:offset-10 {
        margin-left: calc(10 / var(--tn-row-columns, 12) * 100%);
    }

    .m2\:offset-11 {
        margin-left: calc(11 / var(--tn-row-columns, 12) * 100%);
    }

    .row.m2\:gap-0,
    .row.m2\:gap-x-0 {
        --tn-row-gap-x: 0rem;
    }

    .row.m2\:gap-0,
    .row.m2\:gap-y-0 {
        --tn-row-gap-y: 0rem;
    }

    .row.m2\:gap-8,
    .row.m2\:gap-x-8 {
        --tn-row-gap-x: 0.8rem;
    }

    .row.m2\:gap-8,
    .row.m2\:gap-y-8 {
        --tn-row-gap-y: 0.8rem;
    }

    .row.m2\:gap-16,
    .row.m2\:gap-x-16 {
        --tn-row-gap-x: 1.6rem;
    }

    .row.m2\:gap-16,
    .row.m2\:gap-y-16 {
        --tn-row-gap-y: 1.6rem;
    }

    .row.m2\:gap-24,
    .row.m2\:gap-x-24 {
        --tn-row-gap-x: 2.4rem;
    }

    .row.m2\:gap-24,
    .row.m2\:gap-y-24 {
        --tn-row-gap-y: 2.4rem;
    }

    .row.m2\:gap-32,
    .row.m2\:gap-x-32 {
        --tn-row-gap-x: 3.2rem;
    }

    .row.m2\:gap-32,
    .row.m2\:gap-y-32 {
        --tn-row-gap-y: 3.2rem;
    }

    .row.m2\:gap-40,
    .row.m2\:gap-x-40 {
        --tn-row-gap-x: 4rem;
    }

    .row.m2\:gap-40,
    .row.m2\:gap-y-40 {
        --tn-row-gap-y: 4rem;
    }

    .row.m2\:gap-48,
    .row.m2\:gap-x-48 {
        --tn-row-gap-x: 4.8rem;
    }

    .row.m2\:gap-48,
    .row.m2\:gap-y-48 {
        --tn-row-gap-y: 4.8rem;
    }

    .btns.m2\:gap-0,
    .btns.m2\:gap-x-0 {
        --tn-row-gap-x: 0rem;
    }

    .btns.m2\:gap-0,
    .btns.m2\:gap-y-0 {
        --tn-row-gap-y: 0rem;
    }
}

@media (min-width: 480px) {
    .m3\:cols-1 {
        --tn-row-columns: 1;
    }

    .m3\:cols-2 {
        --tn-row-columns: 2;
    }

    .m3\:cols-3 {
        --tn-row-columns: 3;
    }

    .m3\:cols-4 {
        --tn-row-columns: 4;
    }

    .m3\:cols-5 {
        --tn-row-columns: 5;
    }

    .m3\:cols-6 {
        --tn-row-columns: 6;
    }

    .m3\:cols-7 {
        --tn-row-columns: 7;
    }

    .m3\:cols-8 {
        --tn-row-columns: 8;
    }

    .m3\:cols-9 {
        --tn-row-columns: 9;
    }

    .m3\:cols-10 {
        --tn-row-columns: 10;
    }

    .m3\:cols-11 {
        --tn-row-columns: 11;
    }

    .m3\:cols-12 {
        --tn-row-columns: 12;
    }

    .m3\:col-auto {
        max-width: 100%;
        width: auto;
        flex: 0 0 auto;
    }

    .m3\:col-auto-fit {
        max-width: 100%;
        width: 100%;
        flex: 1 0 0%;
    }

    .m3\:col-auto-fill {
        max-width: 100%;
        width: auto;
        flex: 1 1 auto;
    }

    .m3\:col-full {
        max-width: 100%;
        width: 100%;
        flex: 0 0 auto;
    }

    .m3\:col-1 {
        max-width: none;
        width: calc(1 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m3\:col-2 {
        max-width: none;
        width: calc(2 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m3\:col-3 {
        max-width: none;
        width: calc(3 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m3\:col-4 {
        max-width: none;
        width: calc(4 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m3\:col-5 {
        max-width: none;
        width: calc(5 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m3\:col-6 {
        max-width: none;
        width: calc(6 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m3\:col-7 {
        max-width: none;
        width: calc(7 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m3\:col-8 {
        max-width: none;
        width: calc(8 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m3\:col-9 {
        max-width: none;
        width: calc(9 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m3\:col-10 {
        max-width: none;
        width: calc(10 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m3\:col-11 {
        max-width: none;
        width: calc(11 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m3\:col-12 {
        max-width: none;
        width: calc(12 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m3\:offset-0 {
        margin-left: 0;
    }

    .m3\:offset-1 {
        margin-left: calc(1 / var(--tn-row-columns, 12) * 100%);
    }

    .m3\:offset-2 {
        margin-left: calc(2 / var(--tn-row-columns, 12) * 100%);
    }

    .m3\:offset-3 {
        margin-left: calc(3 / var(--tn-row-columns, 12) * 100%);
    }

    .m3\:offset-4 {
        margin-left: calc(4 / var(--tn-row-columns, 12) * 100%);
    }

    .m3\:offset-5 {
        margin-left: calc(5 / var(--tn-row-columns, 12) * 100%);
    }

    .m3\:offset-6 {
        margin-left: calc(6 / var(--tn-row-columns, 12) * 100%);
    }

    .m3\:offset-7 {
        margin-left: calc(7 / var(--tn-row-columns, 12) * 100%);
    }

    .m3\:offset-8 {
        margin-left: calc(8 / var(--tn-row-columns, 12) * 100%);
    }

    .m3\:offset-9 {
        margin-left: calc(9 / var(--tn-row-columns, 12) * 100%);
    }

    .m3\:offset-10 {
        margin-left: calc(10 / var(--tn-row-columns, 12) * 100%);
    }

    .m3\:offset-11 {
        margin-left: calc(11 / var(--tn-row-columns, 12) * 100%);
    }

    .row.m3\:gap-0,
    .row.m3\:gap-x-0 {
        --tn-row-gap-x: 0rem;
    }

    .row.m3\:gap-0,
    .row.m3\:gap-y-0 {
        --tn-row-gap-y: 0rem;
    }

    .row.m3\:gap-8,
    .row.m3\:gap-x-8 {
        --tn-row-gap-x: 0.8rem;
    }

    .row.m3\:gap-8,
    .row.m3\:gap-y-8 {
        --tn-row-gap-y: 0.8rem;
    }

    .row.m3\:gap-16,
    .row.m3\:gap-x-16 {
        --tn-row-gap-x: 1.6rem;
    }

    .row.m3\:gap-16,
    .row.m3\:gap-y-16 {
        --tn-row-gap-y: 1.6rem;
    }

    .row.m3\:gap-24,
    .row.m3\:gap-x-24 {
        --tn-row-gap-x: 2.4rem;
    }

    .row.m3\:gap-24,
    .row.m3\:gap-y-24 {
        --tn-row-gap-y: 2.4rem;
    }

    .row.m3\:gap-32,
    .row.m3\:gap-x-32 {
        --tn-row-gap-x: 3.2rem;
    }

    .row.m3\:gap-32,
    .row.m3\:gap-y-32 {
        --tn-row-gap-y: 3.2rem;
    }

    .row.m3\:gap-40,
    .row.m3\:gap-x-40 {
        --tn-row-gap-x: 4rem;
    }

    .row.m3\:gap-40,
    .row.m3\:gap-y-40 {
        --tn-row-gap-y: 4rem;
    }

    .row.m3\:gap-48,
    .row.m3\:gap-x-48 {
        --tn-row-gap-x: 4.8rem;
    }

    .row.m3\:gap-48,
    .row.m3\:gap-y-48 {
        --tn-row-gap-y: 4.8rem;
    }

    .btns.m3\:gap-0,
    .btns.m3\:gap-x-0 {
        --tn-row-gap-x: 0rem;
    }

    .btns.m3\:gap-0,
    .btns.m3\:gap-y-0 {
        --tn-row-gap-y: 0rem;
    }
}

@media (min-width: 576px) {
    .m4\:cols-1 {
        --tn-row-columns: 1;
    }

    .m4\:cols-2 {
        --tn-row-columns: 2;
    }

    .m4\:cols-3 {
        --tn-row-columns: 3;
    }

    .m4\:cols-4 {
        --tn-row-columns: 4;
    }

    .m4\:cols-5 {
        --tn-row-columns: 5;
    }

    .m4\:cols-6 {
        --tn-row-columns: 6;
    }

    .m4\:cols-7 {
        --tn-row-columns: 7;
    }

    .m4\:cols-8 {
        --tn-row-columns: 8;
    }

    .m4\:cols-9 {
        --tn-row-columns: 9;
    }

    .m4\:cols-10 {
        --tn-row-columns: 10;
    }

    .m4\:cols-11 {
        --tn-row-columns: 11;
    }

    .m4\:cols-12 {
        --tn-row-columns: 12;
    }

    .m4\:col-auto {
        max-width: 100%;
        width: auto;
        flex: 0 0 auto;
    }

    .m4\:col-auto-fit {
        max-width: 100%;
        width: 100%;
        flex: 1 0 0%;
    }

    .m4\:col-auto-fill {
        max-width: 100%;
        width: auto;
        flex: 1 1 auto;
    }

    .m4\:col-full {
        max-width: 100%;
        width: 100%;
        flex: 0 0 auto;
    }

    .m4\:col-1 {
        max-width: none;
        width: calc(1 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m4\:col-2 {
        max-width: none;
        width: calc(2 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m4\:col-3 {
        max-width: none;
        width: calc(3 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m4\:col-4 {
        max-width: none;
        width: calc(4 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m4\:col-5 {
        max-width: none;
        width: calc(5 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m4\:col-6 {
        max-width: none;
        width: calc(6 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m4\:col-7 {
        max-width: none;
        width: calc(7 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m4\:col-8 {
        max-width: none;
        width: calc(8 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m4\:col-9 {
        max-width: none;
        width: calc(9 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m4\:col-10 {
        max-width: none;
        width: calc(10 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m4\:col-11 {
        max-width: none;
        width: calc(11 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m4\:col-12 {
        max-width: none;
        width: calc(12 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .m4\:offset-0 {
        margin-left: 0;
    }

    .m4\:offset-1 {
        margin-left: calc(1 / var(--tn-row-columns, 12) * 100%);
    }

    .m4\:offset-2 {
        margin-left: calc(2 / var(--tn-row-columns, 12) * 100%);
    }

    .m4\:offset-3 {
        margin-left: calc(3 / var(--tn-row-columns, 12) * 100%);
    }

    .m4\:offset-4 {
        margin-left: calc(4 / var(--tn-row-columns, 12) * 100%);
    }

    .m4\:offset-5 {
        margin-left: calc(5 / var(--tn-row-columns, 12) * 100%);
    }

    .m4\:offset-6 {
        margin-left: calc(6 / var(--tn-row-columns, 12) * 100%);
    }

    .m4\:offset-7 {
        margin-left: calc(7 / var(--tn-row-columns, 12) * 100%);
    }

    .m4\:offset-8 {
        margin-left: calc(8 / var(--tn-row-columns, 12) * 100%);
    }

    .m4\:offset-9 {
        margin-left: calc(9 / var(--tn-row-columns, 12) * 100%);
    }

    .m4\:offset-10 {
        margin-left: calc(10 / var(--tn-row-columns, 12) * 100%);
    }

    .m4\:offset-11 {
        margin-left: calc(11 / var(--tn-row-columns, 12) * 100%);
    }

    .row.m4\:gap-0,
    .row.m4\:gap-x-0 {
        --tn-row-gap-x: 0rem;
    }

    .row.m4\:gap-0,
    .row.m4\:gap-y-0 {
        --tn-row-gap-y: 0rem;
    }

    .row.m4\:gap-8,
    .row.m4\:gap-x-8 {
        --tn-row-gap-x: 0.8rem;
    }

    .row.m4\:gap-8,
    .row.m4\:gap-y-8 {
        --tn-row-gap-y: 0.8rem;
    }

    .row.m4\:gap-16,
    .row.m4\:gap-x-16 {
        --tn-row-gap-x: 1.6rem;
    }

    .row.m4\:gap-16,
    .row.m4\:gap-y-16 {
        --tn-row-gap-y: 1.6rem;
    }

    .row.m4\:gap-24,
    .row.m4\:gap-x-24 {
        --tn-row-gap-x: 2.4rem;
    }

    .row.m4\:gap-24,
    .row.m4\:gap-y-24 {
        --tn-row-gap-y: 2.4rem;
    }

    .row.m4\:gap-32,
    .row.m4\:gap-x-32 {
        --tn-row-gap-x: 3.2rem;
    }

    .row.m4\:gap-32,
    .row.m4\:gap-y-32 {
        --tn-row-gap-y: 3.2rem;
    }

    .row.m4\:gap-40,
    .row.m4\:gap-x-40 {
        --tn-row-gap-x: 4rem;
    }

    .row.m4\:gap-40,
    .row.m4\:gap-y-40 {
        --tn-row-gap-y: 4rem;
    }

    .row.m4\:gap-48,
    .row.m4\:gap-x-48 {
        --tn-row-gap-x: 4.8rem;
    }

    .row.m4\:gap-48,
    .row.m4\:gap-y-48 {
        --tn-row-gap-y: 4.8rem;
    }

    .btns.m4\:gap-0,
    .btns.m4\:gap-x-0 {
        --tn-row-gap-x: 0rem;
    }

    .btns.m4\:gap-0,
    .btns.m4\:gap-y-0 {
        --tn-row-gap-y: 0rem;
    }
}

@media (min-width: 640px) {
    .t1\:cols-1 {
        --tn-row-columns: 1;
    }

    .t1\:cols-2 {
        --tn-row-columns: 2;
    }

    .t1\:cols-3 {
        --tn-row-columns: 3;
    }

    .t1\:cols-4 {
        --tn-row-columns: 4;
    }

    .t1\:cols-5 {
        --tn-row-columns: 5;
    }

    .t1\:cols-6 {
        --tn-row-columns: 6;
    }

    .t1\:cols-7 {
        --tn-row-columns: 7;
    }

    .t1\:cols-8 {
        --tn-row-columns: 8;
    }

    .t1\:cols-9 {
        --tn-row-columns: 9;
    }

    .t1\:cols-10 {
        --tn-row-columns: 10;
    }

    .t1\:cols-11 {
        --tn-row-columns: 11;
    }

    .t1\:cols-12 {
        --tn-row-columns: 12;
    }

    .t1\:col-auto {
        max-width: 100%;
        width: auto;
        flex: 0 0 auto;
    }

    .t1\:col-auto-fit {
        max-width: 100%;
        width: 100%;
        flex: 1 0 0%;
    }

    .t1\:col-auto-fill {
        max-width: 100%;
        width: auto;
        flex: 1 1 auto;
    }

    .t1\:col-full {
        max-width: 100%;
        width: 100%;
        flex: 0 0 auto;
    }

    .t1\:col-1 {
        max-width: none;
        width: calc(1 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t1\:col-2 {
        max-width: none;
        width: calc(2 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t1\:col-3 {
        max-width: none;
        width: calc(3 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t1\:col-4 {
        max-width: none;
        width: calc(4 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t1\:col-5 {
        max-width: none;
        width: calc(5 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t1\:col-6 {
        max-width: none;
        width: calc(6 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t1\:col-7 {
        max-width: none;
        width: calc(7 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t1\:col-8 {
        max-width: none;
        width: calc(8 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t1\:col-9 {
        max-width: none;
        width: calc(9 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t1\:col-10 {
        max-width: none;
        width: calc(10 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t1\:col-11 {
        max-width: none;
        width: calc(11 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t1\:col-12 {
        max-width: none;
        width: calc(12 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t1\:offset-0 {
        margin-left: 0;
    }

    .t1\:offset-1 {
        margin-left: calc(1 / var(--tn-row-columns, 12) * 100%);
    }

    .t1\:offset-2 {
        margin-left: calc(2 / var(--tn-row-columns, 12) * 100%);
    }

    .t1\:offset-3 {
        margin-left: calc(3 / var(--tn-row-columns, 12) * 100%);
    }

    .t1\:offset-4 {
        margin-left: calc(4 / var(--tn-row-columns, 12) * 100%);
    }

    .t1\:offset-5 {
        margin-left: calc(5 / var(--tn-row-columns, 12) * 100%);
    }

    .t1\:offset-6 {
        margin-left: calc(6 / var(--tn-row-columns, 12) * 100%);
    }

    .t1\:offset-7 {
        margin-left: calc(7 / var(--tn-row-columns, 12) * 100%);
    }

    .t1\:offset-8 {
        margin-left: calc(8 / var(--tn-row-columns, 12) * 100%);
    }

    .t1\:offset-9 {
        margin-left: calc(9 / var(--tn-row-columns, 12) * 100%);
    }

    .t1\:offset-10 {
        margin-left: calc(10 / var(--tn-row-columns, 12) * 100%);
    }

    .t1\:offset-11 {
        margin-left: calc(11 / var(--tn-row-columns, 12) * 100%);
    }

    .row.t1\:gap-0,
    .row.t1\:gap-x-0 {
        --tn-row-gap-x: 0rem;
    }

    .row.t1\:gap-0,
    .row.t1\:gap-y-0 {
        --tn-row-gap-y: 0rem;
    }

    .row.t1\:gap-8,
    .row.t1\:gap-x-8 {
        --tn-row-gap-x: 0.8rem;
    }

    .row.t1\:gap-8,
    .row.t1\:gap-y-8 {
        --tn-row-gap-y: 0.8rem;
    }

    .row.t1\:gap-16,
    .row.t1\:gap-x-16 {
        --tn-row-gap-x: 1.6rem;
    }

    .row.t1\:gap-16,
    .row.t1\:gap-y-16 {
        --tn-row-gap-y: 1.6rem;
    }

    .row.t1\:gap-24,
    .row.t1\:gap-x-24 {
        --tn-row-gap-x: 2.4rem;
    }

    .row.t1\:gap-24,
    .row.t1\:gap-y-24 {
        --tn-row-gap-y: 2.4rem;
    }

    .row.t1\:gap-32,
    .row.t1\:gap-x-32 {
        --tn-row-gap-x: 3.2rem;
    }

    .row.t1\:gap-32,
    .row.t1\:gap-y-32 {
        --tn-row-gap-y: 3.2rem;
    }

    .row.t1\:gap-40,
    .row.t1\:gap-x-40 {
        --tn-row-gap-x: 4rem;
    }

    .row.t1\:gap-40,
    .row.t1\:gap-y-40 {
        --tn-row-gap-y: 4rem;
    }

    .row.t1\:gap-48,
    .row.t1\:gap-x-48 {
        --tn-row-gap-x: 4.8rem;
    }

    .row.t1\:gap-48,
    .row.t1\:gap-y-48 {
        --tn-row-gap-y: 4.8rem;
    }

    .btns.t1\:gap-0,
    .btns.t1\:gap-x-0 {
        --tn-row-gap-x: 0rem;
    }

    .btns.t1\:gap-0,
    .btns.t1\:gap-y-0 {
        --tn-row-gap-y: 0rem;
    }
}

@media (min-width: 768px) {
    .t2\:cols-1 {
        --tn-row-columns: 1;
    }

    .t2\:cols-2 {
        --tn-row-columns: 2;
    }

    .t2\:cols-3 {
        --tn-row-columns: 3;
    }

    .t2\:cols-4 {
        --tn-row-columns: 4;
    }

    .t2\:cols-5 {
        --tn-row-columns: 5;
    }

    .t2\:cols-6 {
        --tn-row-columns: 6;
    }

    .t2\:cols-7 {
        --tn-row-columns: 7;
    }

    .t2\:cols-8 {
        --tn-row-columns: 8;
    }

    .t2\:cols-9 {
        --tn-row-columns: 9;
    }

    .t2\:cols-10 {
        --tn-row-columns: 10;
    }

    .t2\:cols-11 {
        --tn-row-columns: 11;
    }

    .t2\:cols-12 {
        --tn-row-columns: 12;
    }

    .t2\:col-auto {
        max-width: 100%;
        width: auto;
        flex: 0 0 auto;
    }

    .t2\:col-auto-fit {
        max-width: 100%;
        width: 100%;
        flex: 1 0 0%;
    }

    .t2\:col-auto-fill {
        max-width: 100%;
        width: auto;
        flex: 1 1 auto;
    }

    .t2\:col-full {
        max-width: 100%;
        width: 100%;
        flex: 0 0 auto;
    }

    .t2\:col-1 {
        max-width: none;
        width: calc(1 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t2\:col-2 {
        max-width: none;
        width: calc(2 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t2\:col-3 {
        max-width: none;
        width: calc(3 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t2\:col-4 {
        max-width: none;
        width: calc(4 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t2\:col-5 {
        max-width: none;
        width: calc(5 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t2\:col-6 {
        max-width: none;
        width: calc(6 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t2\:col-7 {
        max-width: none;
        width: calc(7 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t2\:col-8 {
        max-width: none;
        width: calc(8 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t2\:col-9 {
        max-width: none;
        width: calc(9 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t2\:col-10 {
        max-width: none;
        width: calc(10 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t2\:col-11 {
        max-width: none;
        width: calc(11 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t2\:col-12 {
        max-width: none;
        width: calc(12 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t2\:offset-0 {
        margin-left: 0;
    }

    .t2\:offset-1 {
        margin-left: calc(1 / var(--tn-row-columns, 12) * 100%);
    }

    .t2\:offset-2 {
        margin-left: calc(2 / var(--tn-row-columns, 12) * 100%);
    }

    .t2\:offset-3 {
        margin-left: calc(3 / var(--tn-row-columns, 12) * 100%);
    }

    .t2\:offset-4 {
        margin-left: calc(4 / var(--tn-row-columns, 12) * 100%);
    }

    .t2\:offset-5 {
        margin-left: calc(5 / var(--tn-row-columns, 12) * 100%);
    }

    .t2\:offset-6 {
        margin-left: calc(6 / var(--tn-row-columns, 12) * 100%);
    }

    .t2\:offset-7 {
        margin-left: calc(7 / var(--tn-row-columns, 12) * 100%);
    }

    .t2\:offset-8 {
        margin-left: calc(8 / var(--tn-row-columns, 12) * 100%);
    }

    .t2\:offset-9 {
        margin-left: calc(9 / var(--tn-row-columns, 12) * 100%);
    }

    .t2\:offset-10 {
        margin-left: calc(10 / var(--tn-row-columns, 12) * 100%);
    }

    .t2\:offset-11 {
        margin-left: calc(11 / var(--tn-row-columns, 12) * 100%);
    }

    .row.t2\:gap-0,
    .row.t2\:gap-x-0 {
        --tn-row-gap-x: 0rem;
    }

    .row.t2\:gap-0,
    .row.t2\:gap-y-0 {
        --tn-row-gap-y: 0rem;
    }

    .row.t2\:gap-8,
    .row.t2\:gap-x-8 {
        --tn-row-gap-x: 0.8rem;
    }

    .row.t2\:gap-8,
    .row.t2\:gap-y-8 {
        --tn-row-gap-y: 0.8rem;
    }

    .row.t2\:gap-16,
    .row.t2\:gap-x-16 {
        --tn-row-gap-x: 1.6rem;
    }

    .row.t2\:gap-16,
    .row.t2\:gap-y-16 {
        --tn-row-gap-y: 1.6rem;
    }

    .row.t2\:gap-24,
    .row.t2\:gap-x-24 {
        --tn-row-gap-x: 2.4rem;
    }

    .row.t2\:gap-24,
    .row.t2\:gap-y-24 {
        --tn-row-gap-y: 2.4rem;
    }

    .row.t2\:gap-32,
    .row.t2\:gap-x-32 {
        --tn-row-gap-x: 3.2rem;
    }

    .row.t2\:gap-32,
    .row.t2\:gap-y-32 {
        --tn-row-gap-y: 3.2rem;
    }

    .row.t2\:gap-40,
    .row.t2\:gap-x-40 {
        --tn-row-gap-x: 4rem;
    }

    .row.t2\:gap-40,
    .row.t2\:gap-y-40 {
        --tn-row-gap-y: 4rem;
    }

    .row.t2\:gap-48,
    .row.t2\:gap-x-48 {
        --tn-row-gap-x: 4.8rem;
    }

    .row.t2\:gap-48,
    .row.t2\:gap-y-48 {
        --tn-row-gap-y: 4.8rem;
    }

    .btns.t2\:gap-0,
    .btns.t2\:gap-x-0 {
        --tn-row-gap-x: 0rem;
    }

    .btns.t2\:gap-0,
    .btns.t2\:gap-y-0 {
        --tn-row-gap-y: 0rem;
    }
}

@media (min-width: 812px) {
    .t3\:cols-1 {
        --tn-row-columns: 1;
    }

    .t3\:cols-2 {
        --tn-row-columns: 2;
    }

    .t3\:cols-3 {
        --tn-row-columns: 3;
    }

    .t3\:cols-4 {
        --tn-row-columns: 4;
    }

    .t3\:cols-5 {
        --tn-row-columns: 5;
    }

    .t3\:cols-6 {
        --tn-row-columns: 6;
    }

    .t3\:cols-7 {
        --tn-row-columns: 7;
    }

    .t3\:cols-8 {
        --tn-row-columns: 8;
    }

    .t3\:cols-9 {
        --tn-row-columns: 9;
    }

    .t3\:cols-10 {
        --tn-row-columns: 10;
    }

    .t3\:cols-11 {
        --tn-row-columns: 11;
    }

    .t3\:cols-12 {
        --tn-row-columns: 12;
    }

    .t3\:col-auto {
        max-width: 100%;
        width: auto;
        flex: 0 0 auto;
    }

    .t3\:col-auto-fit {
        max-width: 100%;
        width: 100%;
        flex: 1 0 0%;
    }

    .t3\:col-auto-fill {
        max-width: 100%;
        width: auto;
        flex: 1 1 auto;
    }

    .t3\:col-full {
        max-width: 100%;
        width: 100%;
        flex: 0 0 auto;
    }

    .t3\:col-1 {
        max-width: none;
        width: calc(1 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t3\:col-2 {
        max-width: none;
        width: calc(2 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t3\:col-3 {
        max-width: none;
        width: calc(3 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t3\:col-4 {
        max-width: none;
        width: calc(4 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t3\:col-5 {
        max-width: none;
        width: calc(5 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t3\:col-6 {
        max-width: none;
        width: calc(6 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t3\:col-7 {
        max-width: none;
        width: calc(7 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t3\:col-8 {
        max-width: none;
        width: calc(8 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t3\:col-9 {
        max-width: none;
        width: calc(9 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t3\:col-10 {
        max-width: none;
        width: calc(10 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t3\:col-11 {
        max-width: none;
        width: calc(11 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t3\:col-12 {
        max-width: none;
        width: calc(12 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t3\:offset-0 {
        margin-left: 0;
    }

    .t3\:offset-1 {
        margin-left: calc(1 / var(--tn-row-columns, 12) * 100%);
    }

    .t3\:offset-2 {
        margin-left: calc(2 / var(--tn-row-columns, 12) * 100%);
    }

    .t3\:offset-3 {
        margin-left: calc(3 / var(--tn-row-columns, 12) * 100%);
    }

    .t3\:offset-4 {
        margin-left: calc(4 / var(--tn-row-columns, 12) * 100%);
    }

    .t3\:offset-5 {
        margin-left: calc(5 / var(--tn-row-columns, 12) * 100%);
    }

    .t3\:offset-6 {
        margin-left: calc(6 / var(--tn-row-columns, 12) * 100%);
    }

    .t3\:offset-7 {
        margin-left: calc(7 / var(--tn-row-columns, 12) * 100%);
    }

    .t3\:offset-8 {
        margin-left: calc(8 / var(--tn-row-columns, 12) * 100%);
    }

    .t3\:offset-9 {
        margin-left: calc(9 / var(--tn-row-columns, 12) * 100%);
    }

    .t3\:offset-10 {
        margin-left: calc(10 / var(--tn-row-columns, 12) * 100%);
    }

    .t3\:offset-11 {
        margin-left: calc(11 / var(--tn-row-columns, 12) * 100%);
    }

    .row.t3\:gap-0,
    .row.t3\:gap-x-0 {
        --tn-row-gap-x: 0rem;
    }

    .row.t3\:gap-0,
    .row.t3\:gap-y-0 {
        --tn-row-gap-y: 0rem;
    }

    .row.t3\:gap-8,
    .row.t3\:gap-x-8 {
        --tn-row-gap-x: 0.8rem;
    }

    .row.t3\:gap-8,
    .row.t3\:gap-y-8 {
        --tn-row-gap-y: 0.8rem;
    }

    .row.t3\:gap-16,
    .row.t3\:gap-x-16 {
        --tn-row-gap-x: 1.6rem;
    }

    .row.t3\:gap-16,
    .row.t3\:gap-y-16 {
        --tn-row-gap-y: 1.6rem;
    }

    .row.t3\:gap-24,
    .row.t3\:gap-x-24 {
        --tn-row-gap-x: 2.4rem;
    }

    .row.t3\:gap-24,
    .row.t3\:gap-y-24 {
        --tn-row-gap-y: 2.4rem;
    }

    .row.t3\:gap-32,
    .row.t3\:gap-x-32 {
        --tn-row-gap-x: 3.2rem;
    }

    .row.t3\:gap-32,
    .row.t3\:gap-y-32 {
        --tn-row-gap-y: 3.2rem;
    }

    .row.t3\:gap-40,
    .row.t3\:gap-x-40 {
        --tn-row-gap-x: 4rem;
    }

    .row.t3\:gap-40,
    .row.t3\:gap-y-40 {
        --tn-row-gap-y: 4rem;
    }

    .row.t3\:gap-48,
    .row.t3\:gap-x-48 {
        --tn-row-gap-x: 4.8rem;
    }

    .row.t3\:gap-48,
    .row.t3\:gap-y-48 {
        --tn-row-gap-y: 4.8rem;
    }

    .btns.t3\:gap-0,
    .btns.t3\:gap-x-0 {
        --tn-row-gap-x: 0rem;
    }

    .btns.t3\:gap-0,
    .btns.t3\:gap-y-0 {
        --tn-row-gap-y: 0rem;
    }
}

@media (min-width: 992px) {
    .t4\:cols-1 {
        --tn-row-columns: 1;
    }

    .t4\:cols-2 {
        --tn-row-columns: 2;
    }

    .t4\:cols-3 {
        --tn-row-columns: 3;
    }

    .t4\:cols-4 {
        --tn-row-columns: 4;
    }

    .t4\:cols-5 {
        --tn-row-columns: 5;
    }

    .t4\:cols-6 {
        --tn-row-columns: 6;
    }

    .t4\:cols-7 {
        --tn-row-columns: 7;
    }

    .t4\:cols-8 {
        --tn-row-columns: 8;
    }

    .t4\:cols-9 {
        --tn-row-columns: 9;
    }

    .t4\:cols-10 {
        --tn-row-columns: 10;
    }

    .t4\:cols-11 {
        --tn-row-columns: 11;
    }

    .t4\:cols-12 {
        --tn-row-columns: 12;
    }

    .t4\:col-auto {
        max-width: 100%;
        width: auto;
        flex: 0 0 auto;
    }

    .t4\:col-auto-fit {
        max-width: 100%;
        width: 100%;
        flex: 1 0 0%;
    }

    .t4\:col-auto-fill {
        max-width: 100%;
        width: auto;
        flex: 1 1 auto;
    }

    .t4\:col-full {
        max-width: 100%;
        width: 100%;
        flex: 0 0 auto;
    }

    .t4\:col-1 {
        max-width: none;
        width: calc(1 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t4\:col-2 {
        max-width: none;
        width: calc(2 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t4\:col-3 {
        max-width: none;
        width: calc(3 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t4\:col-4 {
        max-width: none;
        width: calc(4 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t4\:col-5 {
        max-width: none;
        width: calc(5 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t4\:col-6 {
        max-width: none;
        width: calc(6 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t4\:col-7 {
        max-width: none;
        width: calc(7 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t4\:col-8 {
        max-width: none;
        width: calc(8 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t4\:col-9 {
        max-width: none;
        width: calc(9 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t4\:col-10 {
        max-width: none;
        width: calc(10 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t4\:col-11 {
        max-width: none;
        width: calc(11 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t4\:col-12 {
        max-width: none;
        width: calc(12 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .t4\:offset-0 {
        margin-left: 0;
    }

    .t4\:offset-1 {
        margin-left: calc(1 / var(--tn-row-columns, 12) * 100%);
    }

    .t4\:offset-2 {
        margin-left: calc(2 / var(--tn-row-columns, 12) * 100%);
    }

    .t4\:offset-3 {
        margin-left: calc(3 / var(--tn-row-columns, 12) * 100%);
    }

    .t4\:offset-4 {
        margin-left: calc(4 / var(--tn-row-columns, 12) * 100%);
    }

    .t4\:offset-5 {
        margin-left: calc(5 / var(--tn-row-columns, 12) * 100%);
    }

    .t4\:offset-6 {
        margin-left: calc(6 / var(--tn-row-columns, 12) * 100%);
    }

    .t4\:offset-7 {
        margin-left: calc(7 / var(--tn-row-columns, 12) * 100%);
    }

    .t4\:offset-8 {
        margin-left: calc(8 / var(--tn-row-columns, 12) * 100%);
    }

    .t4\:offset-9 {
        margin-left: calc(9 / var(--tn-row-columns, 12) * 100%);
    }

    .t4\:offset-10 {
        margin-left: calc(10 / var(--tn-row-columns, 12) * 100%);
    }

    .t4\:offset-11 {
        margin-left: calc(11 / var(--tn-row-columns, 12) * 100%);
    }

    .row.t4\:gap-0,
    .row.t4\:gap-x-0 {
        --tn-row-gap-x: 0rem;
    }

    .row.t4\:gap-0,
    .row.t4\:gap-y-0 {
        --tn-row-gap-y: 0rem;
    }

    .row.t4\:gap-8,
    .row.t4\:gap-x-8 {
        --tn-row-gap-x: 0.8rem;
    }

    .row.t4\:gap-8,
    .row.t4\:gap-y-8 {
        --tn-row-gap-y: 0.8rem;
    }

    .row.t4\:gap-16,
    .row.t4\:gap-x-16 {
        --tn-row-gap-x: 1.6rem;
    }

    .row.t4\:gap-16,
    .row.t4\:gap-y-16 {
        --tn-row-gap-y: 1.6rem;
    }

    .row.t4\:gap-24,
    .row.t4\:gap-x-24 {
        --tn-row-gap-x: 2.4rem;
    }

    .row.t4\:gap-24,
    .row.t4\:gap-y-24 {
        --tn-row-gap-y: 2.4rem;
    }

    .row.t4\:gap-32,
    .row.t4\:gap-x-32 {
        --tn-row-gap-x: 3.2rem;
    }

    .row.t4\:gap-32,
    .row.t4\:gap-y-32 {
        --tn-row-gap-y: 3.2rem;
    }

    .row.t4\:gap-40,
    .row.t4\:gap-x-40 {
        --tn-row-gap-x: 4rem;
    }

    .row.t4\:gap-40,
    .row.t4\:gap-y-40 {
        --tn-row-gap-y: 4rem;
    }

    .row.t4\:gap-48,
    .row.t4\:gap-x-48 {
        --tn-row-gap-x: 4.8rem;
    }

    .row.t4\:gap-48,
    .row.t4\:gap-y-48 {
        --tn-row-gap-y: 4.8rem;
    }

    .btns.t4\:gap-0,
    .btns.t4\:gap-x-0 {
        --tn-row-gap-x: 0rem;
    }

    .btns.t4\:gap-0,
    .btns.t4\:gap-y-0 {
        --tn-row-gap-y: 0rem;
    }
}

@media (min-width: 1200px) {
    .d1\:cols-1 {
        --tn-row-columns: 1;
    }

    .d1\:cols-2 {
        --tn-row-columns: 2;
    }

    .d1\:cols-3 {
        --tn-row-columns: 3;
    }

    .d1\:cols-4 {
        --tn-row-columns: 4;
    }

    .d1\:cols-5 {
        --tn-row-columns: 5;
    }

    .d1\:cols-6 {
        --tn-row-columns: 6;
    }

    .d1\:cols-7 {
        --tn-row-columns: 7;
    }

    .d1\:cols-8 {
        --tn-row-columns: 8;
    }

    .d1\:cols-9 {
        --tn-row-columns: 9;
    }

    .d1\:cols-10 {
        --tn-row-columns: 10;
    }

    .d1\:cols-11 {
        --tn-row-columns: 11;
    }

    .d1\:cols-12 {
        --tn-row-columns: 12;
    }

    .d1\:col-auto {
        max-width: 100%;
        width: auto;
        flex: 0 0 auto;
    }

    .d1\:col-auto-fit {
        max-width: 100%;
        width: 100%;
        flex: 1 0 0%;
    }

    .d1\:col-auto-fill {
        max-width: 100%;
        width: auto;
        flex: 1 1 auto;
    }

    .d1\:col-full {
        max-width: 100%;
        width: 100%;
        flex: 0 0 auto;
    }

    .d1\:col-1 {
        max-width: none;
        width: calc(1 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .d1\:col-2 {
        max-width: none;
        width: calc(2 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .d1\:col-3 {
        max-width: none;
        width: calc(3 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .d1\:col-4 {
        max-width: none;
        width: calc(4 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .d1\:col-5 {
        max-width: none;
        width: calc(5 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .d1\:col-6 {
        max-width: none;
        width: calc(6 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .d1\:col-7 {
        max-width: none;
        width: calc(7 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .d1\:col-8 {
        max-width: none;
        width: calc(8 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .d1\:col-9 {
        max-width: none;
        width: calc(9 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .d1\:col-10 {
        max-width: none;
        width: calc(10 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .d1\:col-11 {
        max-width: none;
        width: calc(11 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .d1\:col-12 {
        max-width: none;
        width: calc(12 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .d1\:offset-0 {
        margin-left: 0;
    }

    .d1\:offset-1 {
        margin-left: calc(1 / var(--tn-row-columns, 12) * 100%);
    }

    .d1\:offset-2 {
        margin-left: calc(2 / var(--tn-row-columns, 12) * 100%);
    }

    .d1\:offset-3 {
        margin-left: calc(3 / var(--tn-row-columns, 12) * 100%);
    }

    .d1\:offset-4 {
        margin-left: calc(4 / var(--tn-row-columns, 12) * 100%);
    }

    .d1\:offset-5 {
        margin-left: calc(5 / var(--tn-row-columns, 12) * 100%);
    }

    .d1\:offset-6 {
        margin-left: calc(6 / var(--tn-row-columns, 12) * 100%);
    }

    .d1\:offset-7 {
        margin-left: calc(7 / var(--tn-row-columns, 12) * 100%);
    }

    .d1\:offset-8 {
        margin-left: calc(8 / var(--tn-row-columns, 12) * 100%);
    }

    .d1\:offset-9 {
        margin-left: calc(9 / var(--tn-row-columns, 12) * 100%);
    }

    .d1\:offset-10 {
        margin-left: calc(10 / var(--tn-row-columns, 12) * 100%);
    }

    .d1\:offset-11 {
        margin-left: calc(11 / var(--tn-row-columns, 12) * 100%);
    }

    .row.d1\:gap-0,
    .row.d1\:gap-x-0 {
        --tn-row-gap-x: 0rem;
    }

    .row.d1\:gap-0,
    .row.d1\:gap-y-0 {
        --tn-row-gap-y: 0rem;
    }

    .row.d1\:gap-8,
    .row.d1\:gap-x-8 {
        --tn-row-gap-x: 0.8rem;
    }

    .row.d1\:gap-8,
    .row.d1\:gap-y-8 {
        --tn-row-gap-y: 0.8rem;
    }

    .row.d1\:gap-16,
    .row.d1\:gap-x-16 {
        --tn-row-gap-x: 1.6rem;
    }

    .row.d1\:gap-16,
    .row.d1\:gap-y-16 {
        --tn-row-gap-y: 1.6rem;
    }

    .row.d1\:gap-24,
    .row.d1\:gap-x-24 {
        --tn-row-gap-x: 2.4rem;
    }

    .row.d1\:gap-24,
    .row.d1\:gap-y-24 {
        --tn-row-gap-y: 2.4rem;
    }

    .row.d1\:gap-32,
    .row.d1\:gap-x-32 {
        --tn-row-gap-x: 3.2rem;
    }

    .row.d1\:gap-32,
    .row.d1\:gap-y-32 {
        --tn-row-gap-y: 3.2rem;
    }

    .row.d1\:gap-40,
    .row.d1\:gap-x-40 {
        --tn-row-gap-x: 4rem;
    }

    .row.d1\:gap-40,
    .row.d1\:gap-y-40 {
        --tn-row-gap-y: 4rem;
    }

    .row.d1\:gap-48,
    .row.d1\:gap-x-48 {
        --tn-row-gap-x: 4.8rem;
    }

    .row.d1\:gap-48,
    .row.d1\:gap-y-48 {
        --tn-row-gap-y: 4.8rem;
    }

    .btns.d1\:gap-0,
    .btns.d1\:gap-x-0 {
        --tn-row-gap-x: 0rem;
    }

    .btns.d1\:gap-0,
    .btns.d1\:gap-y-0 {
        --tn-row-gap-y: 0rem;
    }
}

@media (min-width: 1400px) {
    .d2\:cols-1 {
        --tn-row-columns: 1;
    }

    .d2\:cols-2 {
        --tn-row-columns: 2;
    }

    .d2\:cols-3 {
        --tn-row-columns: 3;
    }

    .d2\:cols-4 {
        --tn-row-columns: 4;
    }

    .d2\:cols-5 {
        --tn-row-columns: 5;
    }

    .d2\:cols-6 {
        --tn-row-columns: 6;
    }

    .d2\:cols-7 {
        --tn-row-columns: 7;
    }

    .d2\:cols-8 {
        --tn-row-columns: 8;
    }

    .d2\:cols-9 {
        --tn-row-columns: 9;
    }

    .d2\:cols-10 {
        --tn-row-columns: 10;
    }

    .d2\:cols-11 {
        --tn-row-columns: 11;
    }

    .d2\:cols-12 {
        --tn-row-columns: 12;
    }

    .d2\:col-auto {
        max-width: 100%;
        width: auto;
        flex: 0 0 auto;
    }

    .d2\:col-auto-fit {
        max-width: 100%;
        width: 100%;
        flex: 1 0 0%;
    }

    .d2\:col-auto-fill {
        max-width: 100%;
        width: auto;
        flex: 1 1 auto;
    }

    .d2\:col-full {
        max-width: 100%;
        width: 100%;
        flex: 0 0 auto;
    }

    .d2\:col-1 {
        max-width: none;
        width: calc(1 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .d2\:col-2 {
        max-width: none;
        width: calc(2 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .d2\:col-3 {
        max-width: none;
        width: calc(3 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .d2\:col-4 {
        max-width: none;
        width: calc(4 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .d2\:col-5 {
        max-width: none;
        width: calc(5 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .d2\:col-6 {
        max-width: none;
        width: calc(6 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .d2\:col-7 {
        max-width: none;
        width: calc(7 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .d2\:col-8 {
        max-width: none;
        width: calc(8 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .d2\:col-9 {
        max-width: none;
        width: calc(9 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .d2\:col-10 {
        max-width: none;
        width: calc(10 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .d2\:col-11 {
        max-width: none;
        width: calc(11 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .d2\:col-12 {
        max-width: none;
        width: calc(12 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .d2\:offset-0 {
        margin-left: 0;
    }

    .d2\:offset-1 {
        margin-left: calc(1 / var(--tn-row-columns, 12) * 100%);
    }

    .d2\:offset-2 {
        margin-left: calc(2 / var(--tn-row-columns, 12) * 100%);
    }

    .d2\:offset-3 {
        margin-left: calc(3 / var(--tn-row-columns, 12) * 100%);
    }

    .d2\:offset-4 {
        margin-left: calc(4 / var(--tn-row-columns, 12) * 100%);
    }

    .d2\:offset-5 {
        margin-left: calc(5 / var(--tn-row-columns, 12) * 100%);
    }

    .d2\:offset-6 {
        margin-left: calc(6 / var(--tn-row-columns, 12) * 100%);
    }

    .d2\:offset-7 {
        margin-left: calc(7 / var(--tn-row-columns, 12) * 100%);
    }

    .d2\:offset-8 {
        margin-left: calc(8 / var(--tn-row-columns, 12) * 100%);
    }

    .d2\:offset-9 {
        margin-left: calc(9 / var(--tn-row-columns, 12) * 100%);
    }

    .d2\:offset-10 {
        margin-left: calc(10 / var(--tn-row-columns, 12) * 100%);
    }

    .d2\:offset-11 {
        margin-left: calc(11 / var(--tn-row-columns, 12) * 100%);
    }

    .row.d2\:gap-0,
    .row.d2\:gap-x-0 {
        --tn-row-gap-x: 0rem;
    }

    .row.d2\:gap-0,
    .row.d2\:gap-y-0 {
        --tn-row-gap-y: 0rem;
    }

    .row.d2\:gap-8,
    .row.d2\:gap-x-8 {
        --tn-row-gap-x: 0.8rem;
    }

    .row.d2\:gap-8,
    .row.d2\:gap-y-8 {
        --tn-row-gap-y: 0.8rem;
    }

    .row.d2\:gap-16,
    .row.d2\:gap-x-16 {
        --tn-row-gap-x: 1.6rem;
    }

    .row.d2\:gap-16,
    .row.d2\:gap-y-16 {
        --tn-row-gap-y: 1.6rem;
    }

    .row.d2\:gap-24,
    .row.d2\:gap-x-24 {
        --tn-row-gap-x: 2.4rem;
    }

    .row.d2\:gap-24,
    .row.d2\:gap-y-24 {
        --tn-row-gap-y: 2.4rem;
    }

    .row.d2\:gap-32,
    .row.d2\:gap-x-32 {
        --tn-row-gap-x: 3.2rem;
    }

    .row.d2\:gap-32,
    .row.d2\:gap-y-32 {
        --tn-row-gap-y: 3.2rem;
    }

    .row.d2\:gap-40,
    .row.d2\:gap-x-40 {
        --tn-row-gap-x: 4rem;
    }

    .row.d2\:gap-40,
    .row.d2\:gap-y-40 {
        --tn-row-gap-y: 4rem;
    }

    .row.d2\:gap-48,
    .row.d2\:gap-x-48 {
        --tn-row-gap-x: 4.8rem;
    }

    .row.d2\:gap-48,
    .row.d2\:gap-y-48 {
        --tn-row-gap-y: 4.8rem;
    }

    .btns.d2\:gap-0,
    .btns.d2\:gap-x-0 {
        --tn-row-gap-x: 0rem;
    }

    .btns.d2\:gap-0,
    .btns.d2\:gap-y-0 {
        --tn-row-gap-y: 0rem;
    }
}

@media (min-width: 1600px) {
    .d3\:cols-1 {
        --tn-row-columns: 1;
    }

    .d3\:cols-2 {
        --tn-row-columns: 2;
    }

    .d3\:cols-3 {
        --tn-row-columns: 3;
    }

    .d3\:cols-4 {
        --tn-row-columns: 4;
    }

    .d3\:cols-5 {
        --tn-row-columns: 5;
    }

    .d3\:cols-6 {
        --tn-row-columns: 6;
    }

    .d3\:cols-7 {
        --tn-row-columns: 7;
    }

    .d3\:cols-8 {
        --tn-row-columns: 8;
    }

    .d3\:cols-9 {
        --tn-row-columns: 9;
    }

    .d3\:cols-10 {
        --tn-row-columns: 10;
    }

    .d3\:cols-11 {
        --tn-row-columns: 11;
    }

    .d3\:cols-12 {
        --tn-row-columns: 12;
    }

    .d3\:col-auto {
        max-width: 100%;
        width: auto;
        flex: 0 0 auto;
    }

    .d3\:col-auto-fit {
        max-width: 100%;
        width: 100%;
        flex: 1 0 0%;
    }

    .d3\:col-auto-fill {
        max-width: 100%;
        width: auto;
        flex: 1 1 auto;
    }

    .d3\:col-full {
        max-width: 100%;
        width: 100%;
        flex: 0 0 auto;
    }

    .d3\:col-1 {
        max-width: none;
        width: calc(1 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .d3\:col-2 {
        max-width: none;
        width: calc(2 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .d3\:col-3 {
        max-width: none;
        width: calc(3 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .d3\:col-4 {
        max-width: none;
        width: calc(4 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .d3\:col-5 {
        max-width: none;
        width: calc(5 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .d3\:col-6 {
        max-width: none;
        width: calc(6 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .d3\:col-7 {
        max-width: none;
        width: calc(7 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .d3\:col-8 {
        max-width: none;
        width: calc(8 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .d3\:col-9 {
        max-width: none;
        width: calc(9 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .d3\:col-10 {
        max-width: none;
        width: calc(10 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .d3\:col-11 {
        max-width: none;
        width: calc(11 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .d3\:col-12 {
        max-width: none;
        width: calc(12 / var(--tn-row-columns, 12) * 100%);
        flex: 0 0 auto;
    }

    .d3\:offset-0 {
        margin-left: 0;
    }

    .d3\:offset-1 {
        margin-left: calc(1 / var(--tn-row-columns, 12) * 100%);
    }

    .d3\:offset-2 {
        margin-left: calc(2 / var(--tn-row-columns, 12) * 100%);
    }

    .d3\:offset-3 {
        margin-left: calc(3 / var(--tn-row-columns, 12) * 100%);
    }

    .d3\:offset-4 {
        margin-left: calc(4 / var(--tn-row-columns, 12) * 100%);
    }

    .d3\:offset-5 {
        margin-left: calc(5 / var(--tn-row-columns, 12) * 100%);
    }

    .d3\:offset-6 {
        margin-left: calc(6 / var(--tn-row-columns, 12) * 100%);
    }

    .d3\:offset-7 {
        margin-left: calc(7 / var(--tn-row-columns, 12) * 100%);
    }

    .d3\:offset-8 {
        margin-left: calc(8 / var(--tn-row-columns, 12) * 100%);
    }

    .d3\:offset-9 {
        margin-left: calc(9 / var(--tn-row-columns, 12) * 100%);
    }

    .d3\:offset-10 {
        margin-left: calc(10 / var(--tn-row-columns, 12) * 100%);
    }

    .d3\:offset-11 {
        margin-left: calc(11 / var(--tn-row-columns, 12) * 100%);
    }

    .row.d3\:gap-0,
    .row.d3\:gap-x-0 {
        --tn-row-gap-x: 0rem;
    }

    .row.d3\:gap-0,
    .row.d3\:gap-y-0 {
        --tn-row-gap-y: 0rem;
    }

    .row.d3\:gap-8,
    .row.d3\:gap-x-8 {
        --tn-row-gap-x: 0.8rem;
    }

    .row.d3\:gap-8,
    .row.d3\:gap-y-8 {
        --tn-row-gap-y: 0.8rem;
    }

    .row.d3\:gap-16,
    .row.d3\:gap-x-16 {
        --tn-row-gap-x: 1.6rem;
    }

    .row.d3\:gap-16,
    .row.d3\:gap-y-16 {
        --tn-row-gap-y: 1.6rem;
    }

    .row.d3\:gap-24,
    .row.d3\:gap-x-24 {
        --tn-row-gap-x: 2.4rem;
    }

    .row.d3\:gap-24,
    .row.d3\:gap-y-24 {
        --tn-row-gap-y: 2.4rem;
    }

    .row.d3\:gap-32,
    .row.d3\:gap-x-32 {
        --tn-row-gap-x: 3.2rem;
    }

    .row.d3\:gap-32,
    .row.d3\:gap-y-32 {
        --tn-row-gap-y: 3.2rem;
    }

    .row.d3\:gap-40,
    .row.d3\:gap-x-40 {
        --tn-row-gap-x: 4rem;
    }

    .row.d3\:gap-40,
    .row.d3\:gap-y-40 {
        --tn-row-gap-y: 4rem;
    }

    .row.d3\:gap-48,
    .row.d3\:gap-x-48 {
        --tn-row-gap-x: 4.8rem;
    }

    .row.d3\:gap-48,
    .row.d3\:gap-y-48 {
        --tn-row-gap-y: 4.8rem;
    }

    .btns.d3\:gap-0,
    .btns.d3\:gap-x-0 {
        --tn-row-gap-x: 0rem;
    }

    .btns.d3\:gap-0,
    .btns.d3\:gap-y-0 {
        --tn-row-gap-y: 0rem;
    }
}

.btns.gap-default {
    --tn-row-gap-x: 2.4rem;
    --tn-row-gap-y: 1.2rem;
}

.btn {
    font: 600 1.4rem/1.4rem var(--font-family-button);
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0.2rem;
    cursor: pointer;
    transition-timing-function: ease;
    transition-duration: 250ms;
    transition-property: background-color, border-color, color, box-shadow, transform, background-position, filter, text-shadow;
    text-align: center;
    text-transform: uppercase;
    border: 0 solid transparent;
    border-radius: 0;
    outline: 0;
    color: inherit;
    background: transparent;
    box-shadow: none;
    text-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.btn::before {
    display: block;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    border: 0.2rem solid transparent;
    transition-property: background-color, border-color, border-width, color, box-shadow, transform, background-position, filter;
}

.btn.btn--disabled, .btn[disabled] {
    cursor: auto;
    background-image: none !important;
    box-shadow: none !important;
    border-color: transparent !important;
    filter: none !important;
}

.btn.btn--disabled::before, .btn[disabled]::before {
    content: none !important;
}

.btn--disabled {
    cursor: not-allowed !important;
    filter: grayscale(1);
    opacity: 0.72;
    pointer-events: none;
}

.btn__icon {
    font-size: 1.6rem;
    line-height: 1;
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    justify-content: center;
    min-width: 0;
    max-width: 100%;
    margin: 0;
    padding: 0;
}

.btn__icon img, .btn__icon svg {
    display: block;
    height: 1em;
    width: auto;
}

.btn__text {
    display: block;
    flex: 0 1 auto;
    min-width: 0;
    max-width: 100%;
}

.btn--type-link {
    border-radius: 0;
    border-width: 0;
    padding: 0.6rem 0.8rem;
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 1.6rem;
    letter-spacing: 0.02;
}

.btn--type-link .btn__icon {
    width: auto;
    height: 1.6rem;
    font-size: 2.2rem;
}

.btn--type-link .btn__icon:first-child:not(:last-child) {
    margin-right: 0.8rem;
}

.btn--type-link .btn__icon:last-child:not(:first-child) {
    margin-left: 0.8rem;
}

.btn--type-link.btn--icon {
    padding: 0.6rem;
}

.btn--type-link.btn--icon .btn__icon {
    width: 1.6rem;
}

.btn--small {
    border-radius: 0;
    border-width: 0;
    padding: 0.5rem 2.6rem;
    font-weight: 400;
    font-size: 0.7rem;
    line-height: 2.6rem;
    letter-spacing: 0;
}

.btn--small .btn__icon {
    width: auto;
    height: 2.6rem;
    font-size: 2.4rem;
}

.btn--small .btn__icon:first-child:not(:last-child) {
    margin-right: 0.8rem;
}

.btn--small .btn__icon:last-child:not(:first-child) {
    margin-left: 0.8rem;
}

.btn--small.btn--icon {
    padding: 0.5rem;
}

.btn--small.btn--icon .btn__icon {
    width: 2.6rem;
}

.btn--medium {
    border-radius: 0;
    border-width: 0;
    padding: 1.1rem 2.6rem;
    font-weight: 400;
    font-size: 0.7rem;
    line-height: 2.6rem;
    letter-spacing: 0;
}

.btn--medium .btn__icon {
    width: auto;
    height: 2.6rem;
    font-size: 2.4rem;
}

.btn--medium .btn__icon:first-child:not(:last-child) {
    margin-right: 0.8rem;
}

.btn--medium .btn__icon:last-child:not(:first-child) {
    margin-left: 0.8rem;
}

.btn--medium.btn--icon {
    padding: 1.1rem;
}

.btn--medium.btn--icon .btn__icon {
    width: 2.6rem;
}

.btn--large {
    border-radius: 0;
    border-width: 0;
    padding: 1.3rem 3.2rem;
    font-weight: 400;
    font-size: 1rem;
    line-height: 3rem;
    letter-spacing: 0;
}

.btn--large .btn__icon {
    width: auto;
    height: 3rem;
    font-size: 2.4rem;
}

.btn--large .btn__icon:first-child:not(:last-child) {
    margin-right: 1rem;
}

.btn--large .btn__icon:last-child:not(:first-child) {
    margin-left: 1rem;
}

.btn--large.btn--icon {
    padding: 1.3rem;
}

.btn--large.btn--icon .btn__icon {
    width: 3rem;
}

@media (min-width: 360px) {
    .m1\:btn--type-link {
        border-radius: 0;
        border-width: 0;
        padding: 0.6rem 0.8rem;
        font-weight: 500;
        font-size: 1.3rem;
        line-height: 1.6rem;
        letter-spacing: 0.02;
    }

    .m1\:btn--type-link .btn__icon {
        width: auto;
        height: 1.6rem;
        font-size: 2.2rem;
    }

    .m1\:btn--type-link .btn__icon:first-child:not(:last-child) {
        margin-right: 0.8rem;
    }

    .m1\:btn--type-link .btn__icon:last-child:not(:first-child) {
        margin-left: 0.8rem;
    }

    .m1\:btn--type-link.btn--icon {
        padding: 0.6rem;
    }

    .m1\:btn--type-link.btn--icon .btn__icon {
        width: 1.6rem;
    }

    .m1\:btn--small {
        border-radius: 0;
        border-width: 0;
        padding: 0.5rem 2.6rem;
        font-weight: 400;
        font-size: 0.7rem;
        line-height: 2.6rem;
        letter-spacing: 0;
    }

    .m1\:btn--small .btn__icon {
        width: auto;
        height: 2.6rem;
        font-size: 2.4rem;
    }

    .m1\:btn--small .btn__icon:first-child:not(:last-child) {
        margin-right: 0.8rem;
    }

    .m1\:btn--small .btn__icon:last-child:not(:first-child) {
        margin-left: 0.8rem;
    }

    .m1\:btn--small.btn--icon {
        padding: 0.5rem;
    }

    .m1\:btn--small.btn--icon .btn__icon {
        width: 2.6rem;
    }

    .m1\:btn--medium {
        border-radius: 0;
        border-width: 0;
        padding: 1.1rem 2.6rem;
        font-weight: 400;
        font-size: 0.7rem;
        line-height: 2.6rem;
        letter-spacing: 0;
    }

    .m1\:btn--medium .btn__icon {
        width: auto;
        height: 2.6rem;
        font-size: 2.4rem;
    }

    .m1\:btn--medium .btn__icon:first-child:not(:last-child) {
        margin-right: 0.8rem;
    }

    .m1\:btn--medium .btn__icon:last-child:not(:first-child) {
        margin-left: 0.8rem;
    }

    .m1\:btn--medium.btn--icon {
        padding: 1.1rem;
    }

    .m1\:btn--medium.btn--icon .btn__icon {
        width: 2.6rem;
    }

    .m1\:btn--large {
        border-radius: 0;
        border-width: 0;
        padding: 1.3rem 3.2rem;
        font-weight: 400;
        font-size: 1rem;
        line-height: 3rem;
        letter-spacing: 0;
    }

    .m1\:btn--large .btn__icon {
        width: auto;
        height: 3rem;
        font-size: 2.4rem;
    }

    .m1\:btn--large .btn__icon:first-child:not(:last-child) {
        margin-right: 1rem;
    }

    .m1\:btn--large .btn__icon:last-child:not(:first-child) {
        margin-left: 1rem;
    }

    .m1\:btn--large.btn--icon {
        padding: 1.3rem;
    }

    .m1\:btn--large.btn--icon .btn__icon {
        width: 3rem;
    }
}

@media (min-width: 414px) {
    .m2\:btn--type-link {
        border-radius: 0;
        border-width: 0;
        padding: 0.6rem 0.8rem;
        font-weight: 500;
        font-size: 1.3rem;
        line-height: 1.6rem;
        letter-spacing: 0.02;
    }

    .m2\:btn--type-link .btn__icon {
        width: auto;
        height: 1.6rem;
        font-size: 2.2rem;
    }

    .m2\:btn--type-link .btn__icon:first-child:not(:last-child) {
        margin-right: 0.8rem;
    }

    .m2\:btn--type-link .btn__icon:last-child:not(:first-child) {
        margin-left: 0.8rem;
    }

    .m2\:btn--type-link.btn--icon {
        padding: 0.6rem;
    }

    .m2\:btn--type-link.btn--icon .btn__icon {
        width: 1.6rem;
    }

    .m2\:btn--small {
        border-radius: 0;
        border-width: 0;
        padding: 0.5rem 2.6rem;
        font-weight: 400;
        font-size: 0.7rem;
        line-height: 2.6rem;
        letter-spacing: 0;
    }

    .m2\:btn--small .btn__icon {
        width: auto;
        height: 2.6rem;
        font-size: 2.4rem;
    }

    .m2\:btn--small .btn__icon:first-child:not(:last-child) {
        margin-right: 0.8rem;
    }

    .m2\:btn--small .btn__icon:last-child:not(:first-child) {
        margin-left: 0.8rem;
    }

    .m2\:btn--small.btn--icon {
        padding: 0.5rem;
    }

    .m2\:btn--small.btn--icon .btn__icon {
        width: 2.6rem;
    }

    .m2\:btn--medium {
        border-radius: 0;
        border-width: 0;
        padding: 1.1rem 2.6rem;
        font-weight: 400;
        font-size: 0.7rem;
        line-height: 2.6rem;
        letter-spacing: 0;
    }

    .m2\:btn--medium .btn__icon {
        width: auto;
        height: 2.6rem;
        font-size: 2.4rem;
    }

    .m2\:btn--medium .btn__icon:first-child:not(:last-child) {
        margin-right: 0.8rem;
    }

    .m2\:btn--medium .btn__icon:last-child:not(:first-child) {
        margin-left: 0.8rem;
    }

    .m2\:btn--medium.btn--icon {
        padding: 1.1rem;
    }

    .m2\:btn--medium.btn--icon .btn__icon {
        width: 2.6rem;
    }

    .m2\:btn--large {
        border-radius: 0;
        border-width: 0;
        padding: 1.3rem 3.2rem;
        font-weight: 400;
        font-size: 1rem;
        line-height: 3rem;
        letter-spacing: 0;
    }

    .m2\:btn--large .btn__icon {
        width: auto;
        height: 3rem;
        font-size: 2.4rem;
    }

    .m2\:btn--large .btn__icon:first-child:not(:last-child) {
        margin-right: 1rem;
    }

    .m2\:btn--large .btn__icon:last-child:not(:first-child) {
        margin-left: 1rem;
    }

    .m2\:btn--large.btn--icon {
        padding: 1.3rem;
    }

    .m2\:btn--large.btn--icon .btn__icon {
        width: 3rem;
    }
}

@media (min-width: 480px) {
    .m3\:btn--type-link {
        border-radius: 0;
        border-width: 0;
        padding: 0.6rem 0.8rem;
        font-weight: 500;
        font-size: 1.3rem;
        line-height: 1.6rem;
        letter-spacing: 0.02;
    }

    .m3\:btn--type-link .btn__icon {
        width: auto;
        height: 1.6rem;
        font-size: 2.2rem;
    }

    .m3\:btn--type-link .btn__icon:first-child:not(:last-child) {
        margin-right: 0.8rem;
    }

    .m3\:btn--type-link .btn__icon:last-child:not(:first-child) {
        margin-left: 0.8rem;
    }

    .m3\:btn--type-link.btn--icon {
        padding: 0.6rem;
    }

    .m3\:btn--type-link.btn--icon .btn__icon {
        width: 1.6rem;
    }

    .m3\:btn--small {
        border-radius: 0;
        border-width: 0;
        padding: 0.5rem 2.6rem;
        font-weight: 400;
        font-size: 0.7rem;
        line-height: 2.6rem;
        letter-spacing: 0;
    }

    .m3\:btn--small .btn__icon {
        width: auto;
        height: 2.6rem;
        font-size: 2.4rem;
    }

    .m3\:btn--small .btn__icon:first-child:not(:last-child) {
        margin-right: 0.8rem;
    }

    .m3\:btn--small .btn__icon:last-child:not(:first-child) {
        margin-left: 0.8rem;
    }

    .m3\:btn--small.btn--icon {
        padding: 0.5rem;
    }

    .m3\:btn--small.btn--icon .btn__icon {
        width: 2.6rem;
    }

    .m3\:btn--medium {
        border-radius: 0;
        border-width: 0;
        padding: 1.1rem 2.6rem;
        font-weight: 400;
        font-size: 0.7rem;
        line-height: 2.6rem;
        letter-spacing: 0;
    }

    .m3\:btn--medium .btn__icon {
        width: auto;
        height: 2.6rem;
        font-size: 2.4rem;
    }

    .m3\:btn--medium .btn__icon:first-child:not(:last-child) {
        margin-right: 0.8rem;
    }

    .m3\:btn--medium .btn__icon:last-child:not(:first-child) {
        margin-left: 0.8rem;
    }

    .m3\:btn--medium.btn--icon {
        padding: 1.1rem;
    }

    .m3\:btn--medium.btn--icon .btn__icon {
        width: 2.6rem;
    }

    .m3\:btn--large {
        border-radius: 0;
        border-width: 0;
        padding: 1.3rem 3.2rem;
        font-weight: 400;
        font-size: 1rem;
        line-height: 3rem;
        letter-spacing: 0;
    }

    .m3\:btn--large .btn__icon {
        width: auto;
        height: 3rem;
        font-size: 2.4rem;
    }

    .m3\:btn--large .btn__icon:first-child:not(:last-child) {
        margin-right: 1rem;
    }

    .m3\:btn--large .btn__icon:last-child:not(:first-child) {
        margin-left: 1rem;
    }

    .m3\:btn--large.btn--icon {
        padding: 1.3rem;
    }

    .m3\:btn--large.btn--icon .btn__icon {
        width: 3rem;
    }
}

@media (min-width: 576px) {
    .m4\:btn--type-link {
        border-radius: 0;
        border-width: 0;
        padding: 0.6rem 0.8rem;
        font-weight: 500;
        font-size: 1.3rem;
        line-height: 1.6rem;
        letter-spacing: 0.02;
    }

    .m4\:btn--type-link .btn__icon {
        width: auto;
        height: 1.6rem;
        font-size: 2.2rem;
    }

    .m4\:btn--type-link .btn__icon:first-child:not(:last-child) {
        margin-right: 0.8rem;
    }

    .m4\:btn--type-link .btn__icon:last-child:not(:first-child) {
        margin-left: 0.8rem;
    }

    .m4\:btn--type-link.btn--icon {
        padding: 0.6rem;
    }

    .m4\:btn--type-link.btn--icon .btn__icon {
        width: 1.6rem;
    }

    .m4\:btn--small {
        border-radius: 0;
        border-width: 0;
        padding: 0.5rem 2.6rem;
        font-weight: 400;
        font-size: 0.7rem;
        line-height: 2.6rem;
        letter-spacing: 0;
    }

    .m4\:btn--small .btn__icon {
        width: auto;
        height: 2.6rem;
        font-size: 2.4rem;
    }

    .m4\:btn--small .btn__icon:first-child:not(:last-child) {
        margin-right: 0.8rem;
    }

    .m4\:btn--small .btn__icon:last-child:not(:first-child) {
        margin-left: 0.8rem;
    }

    .m4\:btn--small.btn--icon {
        padding: 0.5rem;
    }

    .m4\:btn--small.btn--icon .btn__icon {
        width: 2.6rem;
    }

    .m4\:btn--medium {
        border-radius: 0;
        border-width: 0;
        padding: 1.1rem 2.6rem;
        font-weight: 400;
        font-size: 0.7rem;
        line-height: 2.6rem;
        letter-spacing: 0;
    }

    .m4\:btn--medium .btn__icon {
        width: auto;
        height: 2.6rem;
        font-size: 2.4rem;
    }

    .m4\:btn--medium .btn__icon:first-child:not(:last-child) {
        margin-right: 0.8rem;
    }

    .m4\:btn--medium .btn__icon:last-child:not(:first-child) {
        margin-left: 0.8rem;
    }

    .m4\:btn--medium.btn--icon {
        padding: 1.1rem;
    }

    .m4\:btn--medium.btn--icon .btn__icon {
        width: 2.6rem;
    }

    .m4\:btn--large {
        border-radius: 0;
        border-width: 0;
        padding: 1.3rem 3.2rem;
        font-weight: 400;
        font-size: 1rem;
        line-height: 3rem;
        letter-spacing: 0;
    }

    .m4\:btn--large .btn__icon {
        width: auto;
        height: 3rem;
        font-size: 2.4rem;
    }

    .m4\:btn--large .btn__icon:first-child:not(:last-child) {
        margin-right: 1rem;
    }

    .m4\:btn--large .btn__icon:last-child:not(:first-child) {
        margin-left: 1rem;
    }

    .m4\:btn--large.btn--icon {
        padding: 1.3rem;
    }

    .m4\:btn--large.btn--icon .btn__icon {
        width: 3rem;
    }
}

@media (min-width: 640px) {
    .t1\:btn--type-link {
        border-radius: 0;
        border-width: 0;
        padding: 0.6rem 0.8rem;
        font-weight: 500;
        font-size: 1.3rem;
        line-height: 1.6rem;
        letter-spacing: 0.02;
    }

    .t1\:btn--type-link .btn__icon {
        width: auto;
        height: 1.6rem;
        font-size: 2.2rem;
    }

    .t1\:btn--type-link .btn__icon:first-child:not(:last-child) {
        margin-right: 0.8rem;
    }

    .t1\:btn--type-link .btn__icon:last-child:not(:first-child) {
        margin-left: 0.8rem;
    }

    .t1\:btn--type-link.btn--icon {
        padding: 0.6rem;
    }

    .t1\:btn--type-link.btn--icon .btn__icon {
        width: 1.6rem;
    }

    .t1\:btn--small {
        border-radius: 0;
        border-width: 0;
        padding: 0.5rem 2.6rem;
        font-weight: 400;
        font-size: 0.7rem;
        line-height: 2.6rem;
        letter-spacing: 0;
    }

    .t1\:btn--small .btn__icon {
        width: auto;
        height: 2.6rem;
        font-size: 2.4rem;
    }

    .t1\:btn--small .btn__icon:first-child:not(:last-child) {
        margin-right: 0.8rem;
    }

    .t1\:btn--small .btn__icon:last-child:not(:first-child) {
        margin-left: 0.8rem;
    }

    .t1\:btn--small.btn--icon {
        padding: 0.5rem;
    }

    .t1\:btn--small.btn--icon .btn__icon {
        width: 2.6rem;
    }

    .t1\:btn--medium {
        border-radius: 0;
        border-width: 0;
        padding: 1.1rem 2.6rem;
        font-weight: 400;
        font-size: 0.7rem;
        line-height: 2.6rem;
        letter-spacing: 0;
    }

    .t1\:btn--medium .btn__icon {
        width: auto;
        height: 2.6rem;
        font-size: 2.4rem;
    }

    .t1\:btn--medium .btn__icon:first-child:not(:last-child) {
        margin-right: 0.8rem;
    }

    .t1\:btn--medium .btn__icon:last-child:not(:first-child) {
        margin-left: 0.8rem;
    }

    .t1\:btn--medium.btn--icon {
        padding: 1.1rem;
    }

    .t1\:btn--medium.btn--icon .btn__icon {
        width: 2.6rem;
    }

    .t1\:btn--large {
        border-radius: 0;
        border-width: 0;
        padding: 1.3rem 3.2rem;
        font-weight: 400;
        font-size: 1rem;
        line-height: 3rem;
        letter-spacing: 0;
    }

    .t1\:btn--large .btn__icon {
        width: auto;
        height: 3rem;
        font-size: 2.4rem;
    }

    .t1\:btn--large .btn__icon:first-child:not(:last-child) {
        margin-right: 1rem;
    }

    .t1\:btn--large .btn__icon:last-child:not(:first-child) {
        margin-left: 1rem;
    }

    .t1\:btn--large.btn--icon {
        padding: 1.3rem;
    }

    .t1\:btn--large.btn--icon .btn__icon {
        width: 3rem;
    }
}

@media (min-width: 768px) {
    .t2\:btn--type-link {
        border-radius: 0;
        border-width: 0;
        padding: 0.6rem 0.8rem;
        font-weight: 500;
        font-size: 1.3rem;
        line-height: 1.6rem;
        letter-spacing: 0.02;
    }

    .t2\:btn--type-link .btn__icon {
        width: auto;
        height: 1.6rem;
        font-size: 2.2rem;
    }

    .t2\:btn--type-link .btn__icon:first-child:not(:last-child) {
        margin-right: 0.8rem;
    }

    .t2\:btn--type-link .btn__icon:last-child:not(:first-child) {
        margin-left: 0.8rem;
    }

    .t2\:btn--type-link.btn--icon {
        padding: 0.6rem;
    }

    .t2\:btn--type-link.btn--icon .btn__icon {
        width: 1.6rem;
    }

    .t2\:btn--small {
        border-radius: 0;
        border-width: 0;
        padding: 0.5rem 2.6rem;
        font-weight: 400;
        font-size: 0.7rem;
        line-height: 2.6rem;
        letter-spacing: 0;
    }

    .t2\:btn--small .btn__icon {
        width: auto;
        height: 2.6rem;
        font-size: 2.4rem;
    }

    .t2\:btn--small .btn__icon:first-child:not(:last-child) {
        margin-right: 0.8rem;
    }

    .t2\:btn--small .btn__icon:last-child:not(:first-child) {
        margin-left: 0.8rem;
    }

    .t2\:btn--small.btn--icon {
        padding: 0.5rem;
    }

    .t2\:btn--small.btn--icon .btn__icon {
        width: 2.6rem;
    }

    .t2\:btn--medium {
        border-radius: 0;
        border-width: 0;
        padding: 1.1rem 2.6rem;
        font-weight: 400;
        font-size: 0.7rem;
        line-height: 2.6rem;
        letter-spacing: 0;
    }

    .t2\:btn--medium .btn__icon {
        width: auto;
        height: 2.6rem;
        font-size: 2.4rem;
    }

    .t2\:btn--medium .btn__icon:first-child:not(:last-child) {
        margin-right: 0.8rem;
    }

    .t2\:btn--medium .btn__icon:last-child:not(:first-child) {
        margin-left: 0.8rem;
    }

    .t2\:btn--medium.btn--icon {
        padding: 1.1rem;
    }

    .t2\:btn--medium.btn--icon .btn__icon {
        width: 2.6rem;
    }

    .t2\:btn--large {
        border-radius: 0;
        border-width: 0;
        padding: 1.3rem 3.2rem;
        font-weight: 400;
        font-size: 1rem;
        line-height: 3rem;
        letter-spacing: 0;
    }

    .t2\:btn--large .btn__icon {
        width: auto;
        height: 3rem;
        font-size: 2.4rem;
    }

    .t2\:btn--large .btn__icon:first-child:not(:last-child) {
        margin-right: 1rem;
    }

    .t2\:btn--large .btn__icon:last-child:not(:first-child) {
        margin-left: 1rem;
    }

    .t2\:btn--large.btn--icon {
        padding: 1.3rem;
    }

    .t2\:btn--large.btn--icon .btn__icon {
        width: 3rem;
    }
}

@media (min-width: 812px) {
    .t3\:btn--type-link {
        border-radius: 0;
        border-width: 0;
        padding: 0.6rem 0.8rem;
        font-weight: 500;
        font-size: 1.3rem;
        line-height: 1.6rem;
        letter-spacing: 0.02;
    }

    .t3\:btn--type-link .btn__icon {
        width: auto;
        height: 1.6rem;
        font-size: 2.2rem;
    }

    .t3\:btn--type-link .btn__icon:first-child:not(:last-child) {
        margin-right: 0.8rem;
    }

    .t3\:btn--type-link .btn__icon:last-child:not(:first-child) {
        margin-left: 0.8rem;
    }

    .t3\:btn--type-link.btn--icon {
        padding: 0.6rem;
    }

    .t3\:btn--type-link.btn--icon .btn__icon {
        width: 1.6rem;
    }

    .t3\:btn--small {
        border-radius: 0;
        border-width: 0;
        padding: 0.5rem 2.6rem;
        font-weight: 400;
        font-size: 0.7rem;
        line-height: 2.6rem;
        letter-spacing: 0;
    }

    .t3\:btn--small .btn__icon {
        width: auto;
        height: 2.6rem;
        font-size: 2.4rem;
    }

    .t3\:btn--small .btn__icon:first-child:not(:last-child) {
        margin-right: 0.8rem;
    }

    .t3\:btn--small .btn__icon:last-child:not(:first-child) {
        margin-left: 0.8rem;
    }

    .t3\:btn--small.btn--icon {
        padding: 0.5rem;
    }

    .t3\:btn--small.btn--icon .btn__icon {
        width: 2.6rem;
    }

    .t3\:btn--medium {
        border-radius: 0;
        border-width: 0;
        padding: 1.1rem 2.6rem;
        font-weight: 400;
        font-size: 0.7rem;
        line-height: 2.6rem;
        letter-spacing: 0;
    }

    .t3\:btn--medium .btn__icon {
        width: auto;
        height: 2.6rem;
        font-size: 2.4rem;
    }

    .t3\:btn--medium .btn__icon:first-child:not(:last-child) {
        margin-right: 0.8rem;
    }

    .t3\:btn--medium .btn__icon:last-child:not(:first-child) {
        margin-left: 0.8rem;
    }

    .t3\:btn--medium.btn--icon {
        padding: 1.1rem;
    }

    .t3\:btn--medium.btn--icon .btn__icon {
        width: 2.6rem;
    }

    .t3\:btn--large {
        border-radius: 0;
        border-width: 0;
        padding: 1.3rem 3.2rem;
        font-weight: 400;
        font-size: 1rem;
        line-height: 3rem;
        letter-spacing: 0;
    }

    .t3\:btn--large .btn__icon {
        width: auto;
        height: 3rem;
        font-size: 2.4rem;
    }

    .t3\:btn--large .btn__icon:first-child:not(:last-child) {
        margin-right: 1rem;
    }

    .t3\:btn--large .btn__icon:last-child:not(:first-child) {
        margin-left: 1rem;
    }

    .t3\:btn--large.btn--icon {
        padding: 1.3rem;
    }

    .t3\:btn--large.btn--icon .btn__icon {
        width: 3rem;
    }
}

@media (min-width: 992px) {
    .t4\:btn--type-link {
        border-radius: 0;
        border-width: 0;
        padding: 0.6rem 0.8rem;
        font-weight: 500;
        font-size: 1.3rem;
        line-height: 1.6rem;
        letter-spacing: 0.02;
    }

    .t4\:btn--type-link .btn__icon {
        width: auto;
        height: 1.6rem;
        font-size: 2.2rem;
    }

    .t4\:btn--type-link .btn__icon:first-child:not(:last-child) {
        margin-right: 0.8rem;
    }

    .t4\:btn--type-link .btn__icon:last-child:not(:first-child) {
        margin-left: 0.8rem;
    }

    .t4\:btn--type-link.btn--icon {
        padding: 0.6rem;
    }

    .t4\:btn--type-link.btn--icon .btn__icon {
        width: 1.6rem;
    }

    .t4\:btn--small {
        border-radius: 0;
        border-width: 0;
        padding: 0.5rem 2.6rem;
        font-weight: 400;
        font-size: 0.7rem;
        line-height: 2.6rem;
        letter-spacing: 0;
    }

    .t4\:btn--small .btn__icon {
        width: auto;
        height: 2.6rem;
        font-size: 2.4rem;
    }

    .t4\:btn--small .btn__icon:first-child:not(:last-child) {
        margin-right: 0.8rem;
    }

    .t4\:btn--small .btn__icon:last-child:not(:first-child) {
        margin-left: 0.8rem;
    }

    .t4\:btn--small.btn--icon {
        padding: 0.5rem;
    }

    .t4\:btn--small.btn--icon .btn__icon {
        width: 2.6rem;
    }

    .t4\:btn--medium {
        border-radius: 0;
        border-width: 0;
        padding: 1.1rem 2.6rem;
        font-weight: 400;
        font-size: 0.7rem;
        line-height: 2.6rem;
        letter-spacing: 0;
    }

    .t4\:btn--medium .btn__icon {
        width: auto;
        height: 2.6rem;
        font-size: 2.4rem;
    }

    .t4\:btn--medium .btn__icon:first-child:not(:last-child) {
        margin-right: 0.8rem;
    }

    .t4\:btn--medium .btn__icon:last-child:not(:first-child) {
        margin-left: 0.8rem;
    }

    .t4\:btn--medium.btn--icon {
        padding: 1.1rem;
    }

    .t4\:btn--medium.btn--icon .btn__icon {
        width: 2.6rem;
    }

    .t4\:btn--large {
        border-radius: 0;
        border-width: 0;
        padding: 1.3rem 3.2rem;
        font-weight: 400;
        font-size: 1rem;
        line-height: 3rem;
        letter-spacing: 0;
    }

    .t4\:btn--large .btn__icon {
        width: auto;
        height: 3rem;
        font-size: 2.4rem;
    }

    .t4\:btn--large .btn__icon:first-child:not(:last-child) {
        margin-right: 1rem;
    }

    .t4\:btn--large .btn__icon:last-child:not(:first-child) {
        margin-left: 1rem;
    }

    .t4\:btn--large.btn--icon {
        padding: 1.3rem;
    }

    .t4\:btn--large.btn--icon .btn__icon {
        width: 3rem;
    }
}

@media (min-width: 1200px) {
    .d1\:btn--type-link {
        border-radius: 0;
        border-width: 0;
        padding: 0.6rem 0.8rem;
        font-weight: 500;
        font-size: 1.3rem;
        line-height: 1.6rem;
        letter-spacing: 0.02;
    }

    .d1\:btn--type-link .btn__icon {
        width: auto;
        height: 1.6rem;
        font-size: 2.2rem;
    }

    .d1\:btn--type-link .btn__icon:first-child:not(:last-child) {
        margin-right: 0.8rem;
    }

    .d1\:btn--type-link .btn__icon:last-child:not(:first-child) {
        margin-left: 0.8rem;
    }

    .d1\:btn--type-link.btn--icon {
        padding: 0.6rem;
    }

    .d1\:btn--type-link.btn--icon .btn__icon {
        width: 1.6rem;
    }

    .d1\:btn--small {
        border-radius: 0;
        border-width: 0;
        padding: 0.5rem 2.6rem;
        font-weight: 400;
        font-size: 0.7rem;
        line-height: 2.6rem;
        letter-spacing: 0;
    }

    .d1\:btn--small .btn__icon {
        width: auto;
        height: 2.6rem;
        font-size: 2.4rem;
    }

    .d1\:btn--small .btn__icon:first-child:not(:last-child) {
        margin-right: 0.8rem;
    }

    .d1\:btn--small .btn__icon:last-child:not(:first-child) {
        margin-left: 0.8rem;
    }

    .d1\:btn--small.btn--icon {
        padding: 0.5rem;
    }

    .d1\:btn--small.btn--icon .btn__icon {
        width: 2.6rem;
    }

    .d1\:btn--medium {
        border-radius: 0;
        border-width: 0;
        padding: 1.1rem 2.6rem;
        font-weight: 400;
        font-size: 0.7rem;
        line-height: 2.6rem;
        letter-spacing: 0;
    }

    .d1\:btn--medium .btn__icon {
        width: auto;
        height: 2.6rem;
        font-size: 2.4rem;
    }

    .d1\:btn--medium .btn__icon:first-child:not(:last-child) {
        margin-right: 0.8rem;
    }

    .d1\:btn--medium .btn__icon:last-child:not(:first-child) {
        margin-left: 0.8rem;
    }

    .d1\:btn--medium.btn--icon {
        padding: 1.1rem;
    }

    .d1\:btn--medium.btn--icon .btn__icon {
        width: 2.6rem;
    }

    .d1\:btn--large {
        border-radius: 0;
        border-width: 0;
        padding: 1.3rem 3.2rem;
        font-weight: 400;
        font-size: 1rem;
        line-height: 3rem;
        letter-spacing: 0;
    }

    .d1\:btn--large .btn__icon {
        width: auto;
        height: 3rem;
        font-size: 2.4rem;
    }

    .d1\:btn--large .btn__icon:first-child:not(:last-child) {
        margin-right: 1rem;
    }

    .d1\:btn--large .btn__icon:last-child:not(:first-child) {
        margin-left: 1rem;
    }

    .d1\:btn--large.btn--icon {
        padding: 1.3rem;
    }

    .d1\:btn--large.btn--icon .btn__icon {
        width: 3rem;
    }
}

@media (min-width: 1400px) {
    .d2\:btn--type-link {
        border-radius: 0;
        border-width: 0;
        padding: 0.6rem 0.8rem;
        font-weight: 500;
        font-size: 1.3rem;
        line-height: 1.6rem;
        letter-spacing: 0.02;
    }

    .d2\:btn--type-link .btn__icon {
        width: auto;
        height: 1.6rem;
        font-size: 2.2rem;
    }

    .d2\:btn--type-link .btn__icon:first-child:not(:last-child) {
        margin-right: 0.8rem;
    }

    .d2\:btn--type-link .btn__icon:last-child:not(:first-child) {
        margin-left: 0.8rem;
    }

    .d2\:btn--type-link.btn--icon {
        padding: 0.6rem;
    }

    .d2\:btn--type-link.btn--icon .btn__icon {
        width: 1.6rem;
    }

    .d2\:btn--small {
        border-radius: 0;
        border-width: 0;
        padding: 0.5rem 2.6rem;
        font-weight: 400;
        font-size: 0.7rem;
        line-height: 2.6rem;
        letter-spacing: 0;
    }

    .d2\:btn--small .btn__icon {
        width: auto;
        height: 2.6rem;
        font-size: 2.4rem;
    }

    .d2\:btn--small .btn__icon:first-child:not(:last-child) {
        margin-right: 0.8rem;
    }

    .d2\:btn--small .btn__icon:last-child:not(:first-child) {
        margin-left: 0.8rem;
    }

    .d2\:btn--small.btn--icon {
        padding: 0.5rem;
    }

    .d2\:btn--small.btn--icon .btn__icon {
        width: 2.6rem;
    }

    .d2\:btn--medium {
        border-radius: 0;
        border-width: 0;
        padding: 1.1rem 2.6rem;
        font-weight: 400;
        font-size: 0.7rem;
        line-height: 2.6rem;
        letter-spacing: 0;
    }

    .d2\:btn--medium .btn__icon {
        width: auto;
        height: 2.6rem;
        font-size: 2.4rem;
    }

    .d2\:btn--medium .btn__icon:first-child:not(:last-child) {
        margin-right: 0.8rem;
    }

    .d2\:btn--medium .btn__icon:last-child:not(:first-child) {
        margin-left: 0.8rem;
    }

    .d2\:btn--medium.btn--icon {
        padding: 1.1rem;
    }

    .d2\:btn--medium.btn--icon .btn__icon {
        width: 2.6rem;
    }

    .d2\:btn--large {
        border-radius: 0;
        border-width: 0;
        padding: 1.3rem 3.2rem;
        font-weight: 400;
        font-size: 1rem;
        line-height: 3rem;
        letter-spacing: 0;
    }

    .d2\:btn--large .btn__icon {
        width: auto;
        height: 3rem;
        font-size: 2.4rem;
    }

    .d2\:btn--large .btn__icon:first-child:not(:last-child) {
        margin-right: 1rem;
    }

    .d2\:btn--large .btn__icon:last-child:not(:first-child) {
        margin-left: 1rem;
    }

    .d2\:btn--large.btn--icon {
        padding: 1.3rem;
    }

    .d2\:btn--large.btn--icon .btn__icon {
        width: 3rem;
    }
}

@media (min-width: 1600px) {
    .d3\:btn--type-link {
        border-radius: 0;
        border-width: 0;
        padding: 0.6rem 0.8rem;
        font-weight: 500;
        font-size: 1.3rem;
        line-height: 1.6rem;
        letter-spacing: 0.02;
    }

    .d3\:btn--type-link .btn__icon {
        width: auto;
        height: 1.6rem;
        font-size: 2.2rem;
    }

    .d3\:btn--type-link .btn__icon:first-child:not(:last-child) {
        margin-right: 0.8rem;
    }

    .d3\:btn--type-link .btn__icon:last-child:not(:first-child) {
        margin-left: 0.8rem;
    }

    .d3\:btn--type-link.btn--icon {
        padding: 0.6rem;
    }

    .d3\:btn--type-link.btn--icon .btn__icon {
        width: 1.6rem;
    }

    .d3\:btn--small {
        border-radius: 0;
        border-width: 0;
        padding: 0.5rem 2.6rem;
        font-weight: 400;
        font-size: 0.7rem;
        line-height: 2.6rem;
        letter-spacing: 0;
    }

    .d3\:btn--small .btn__icon {
        width: auto;
        height: 2.6rem;
        font-size: 2.4rem;
    }

    .d3\:btn--small .btn__icon:first-child:not(:last-child) {
        margin-right: 0.8rem;
    }

    .d3\:btn--small .btn__icon:last-child:not(:first-child) {
        margin-left: 0.8rem;
    }

    .d3\:btn--small.btn--icon {
        padding: 0.5rem;
    }

    .d3\:btn--small.btn--icon .btn__icon {
        width: 2.6rem;
    }

    .d3\:btn--medium {
        border-radius: 0;
        border-width: 0;
        padding: 1.1rem 2.6rem;
        font-weight: 400;
        font-size: 0.7rem;
        line-height: 2.6rem;
        letter-spacing: 0;
    }

    .d3\:btn--medium .btn__icon {
        width: auto;
        height: 2.6rem;
        font-size: 2.4rem;
    }

    .d3\:btn--medium .btn__icon:first-child:not(:last-child) {
        margin-right: 0.8rem;
    }

    .d3\:btn--medium .btn__icon:last-child:not(:first-child) {
        margin-left: 0.8rem;
    }

    .d3\:btn--medium.btn--icon {
        padding: 1.1rem;
    }

    .d3\:btn--medium.btn--icon .btn__icon {
        width: 2.6rem;
    }

    .d3\:btn--large {
        border-radius: 0;
        border-width: 0;
        padding: 1.3rem 3.2rem;
        font-weight: 400;
        font-size: 1rem;
        line-height: 3rem;
        letter-spacing: 0;
    }

    .d3\:btn--large .btn__icon {
        width: auto;
        height: 3rem;
        font-size: 2.4rem;
    }

    .d3\:btn--large .btn__icon:first-child:not(:last-child) {
        margin-right: 1rem;
    }

    .d3\:btn--large .btn__icon:last-child:not(:first-child) {
        margin-left: 1rem;
    }

    .d3\:btn--large.btn--icon {
        padding: 1.3rem;
    }

    .d3\:btn--large.btn--icon .btn__icon {
        width: 3rem;
    }
}

.btn--primary {
    background-color: rgb(var(--color-primary-500));
    color: rgb(var(--color-white));
}

.btn--primary:hover {
    background-color: rgb(var(--color-primary-700));
    color: rgb(var(--color-white));
}

.btn--primary:active {
    background-color: rgb(var(--color-primary-400));
    color: rgb(var(--color-white));
}

.btn--primary:focus-visible {
    background-color: rgb(var(--color-primary-700));
    color: rgb(var(--color-white));
}

.btn--primary-outline {
    background-color: rgb(var(--color-primary-500));
    color: rgb(var(--color-white));
}

.btn--primary-outline:hover {
    background-color: rgb(var(--color-primary-700));
    color: rgb(var(--color-white));
}

.btn--primary-outline:active {
    background-color: rgb(var(--color-primary-400));
    color: rgb(var(--color-white));
}

.btn--primary-outline:focus-visible {
    background-color: rgb(var(--color-primary-700));
    color: rgb(var(--color-white));
}

.btn--light {
    background-color: rgb(var(--color-white));
    color: rgb(var(--color-black));
}

.btn--light:hover {
    background-color: rgb(var(--color-primary-700));
    color: rgb(var(--color-white));
}

.btn--light:active {
    background-color: rgb(var(--color-primary-400));
    color: rgb(var(--color-white));
}

.btn--light:focus-visible {
    background-color: rgb(var(--color-primary-700));
    color: rgb(var(--color-white));
}

.btn--light-outline {
    background-color: transparent;
    color: rgb(var(--color-white));
}

.btn--light-outline::before {
    content: "";
    border-color: rgb(var(--color-white));
}

.btn--light-outline:hover {
    background-color: rgb(var(--color-white));
    color: rgb(var(--color-black));
}

.btn--light-outline:active {
    background-color: rgb(var(--color-white));
    color: rgb(var(--color-black));
}

.btn--light-outline:focus-visible {
    background-color: rgb(var(--color-white));
    color: rgb(var(--color-black));
}

.layout__main {
    overflow: hidden;
}

[data-show-contact] .icon {
    display: inline-block;
    margin-left: 0.33em;
}

.toggle-button:not(.active) .toggle-button-active,
.toggle-button.active .toggle-button-inactive {
    display: none;
}

img.img--transparent {
    opacity: 0;
}

.svg--current-color path,
.svg--current-color circle,
.svg--current-color rect {
    fill: currentColor;
}

::selection {
    background-color: rgb(var(--color-primary-500));
    color: rgb(var(--color-white));
}

.show-email,
.show-phone {
    display: inline-block;
}

.show-email *,
.show-phone * {
    pointer-events: none;
}

.block-ratio {
    position: relative;
    z-index: 1;
    width: 100%;
    display: grid;
    grid-template: 100%/100%;
    grid-auto-rows: auto;
    grid-auto-columns: auto;
    overflow: hidden;
}

.block-ratio::before {
    content: "";
    grid-area: 1/1/2/2;
    display: block;
    pointer-events: none;
}

.block-ratio__map, .block-ratio__link, .block-ratio__content, .block-ratio__media,
.block-ratio__media-hover {
    display: block;
    position: relative;
    grid-area: 1/1/2/2;
}

.block-ratio__map, .block-ratio__media,
.block-ratio__media-hover {
    z-index: 2;
}

.block-ratio__map-wrapper, .block-ratio__media video,
.block-ratio__media picture,
.block-ratio__media img,
.block-ratio__media-hover video,
.block-ratio__media-hover picture,
.block-ratio__media-hover img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.block-ratio__media,
.block-ratio__media-hover {
    transition-property: opacity;
    transition-duration: 250ms;
    transition-timing-function: ease;
}

.block-ratio__media video,
.block-ratio__media picture,
.block-ratio__media img,
.block-ratio__media-hover video,
.block-ratio__media-hover picture,
.block-ratio__media-hover img {
    display: block;
    object-fit: cover;
    object-position: center;
    transition: transform 250ms ease;
}

.block-ratio__media-hover {
    opacity: 0;
}

.block-ratio__media-hover ~ .block-ratio__media {
    opacity: 1;
}

.block-ratio__content {
    z-index: 3;
}

.block-ratio__link {
    z-index: 4;
}

.block-ratio__map-wrapper iframe {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
}

.block-ratio--contain .block-ratio__media > video,
.block-ratio--contain .block-ratio__media > picture,
.block-ratio--contain .block-ratio__media > img {
    object-fit: contain;
}

.block-ratio:not(.block-ratio--contain):not(.block-ratio--without-animation) .block-ratio__link:hover ~ .block-ratio__media > video,
.block-ratio:not(.block-ratio--contain):not(.block-ratio--without-animation) .block-ratio__link:hover ~ .block-ratio__media > picture,
.block-ratio:not(.block-ratio--contain):not(.block-ratio--without-animation) .block-ratio__link:hover ~ .block-ratio__media > img,
.block-ratio:not(.block-ratio--contain):not(.block-ratio--without-animation) .block-ratio__link:hover ~ .block-ratio__media-hover > video,
.block-ratio:not(.block-ratio--contain):not(.block-ratio--without-animation) .block-ratio__link:hover ~ .block-ratio__media-hover > picture,
.block-ratio:not(.block-ratio--contain):not(.block-ratio--without-animation) .block-ratio__link:hover ~ .block-ratio__media-hover > img, .block-ratio:not(.block-ratio--contain):not(.block-ratio--without-animation) .block-ratio__link:focus ~ .block-ratio__media > video,
.block-ratio:not(.block-ratio--contain):not(.block-ratio--without-animation) .block-ratio__link:focus ~ .block-ratio__media > picture,
.block-ratio:not(.block-ratio--contain):not(.block-ratio--without-animation) .block-ratio__link:focus ~ .block-ratio__media > img,
.block-ratio:not(.block-ratio--contain):not(.block-ratio--without-animation) .block-ratio__link:focus ~ .block-ratio__media-hover > video,
.block-ratio:not(.block-ratio--contain):not(.block-ratio--without-animation) .block-ratio__link:focus ~ .block-ratio__media-hover > picture,
.block-ratio:not(.block-ratio--contain):not(.block-ratio--without-animation) .block-ratio__link:focus ~ .block-ratio__media-hover > img {
    transform: scale(1.05);
}

.block-ratio .block-ratio__link {
    opacity: 0;
    user-select: none;
}

.block-ratio .block-ratio__link:hover ~ .block-ratio__media-hover, .block-ratio .block-ratio__link:focus ~ .block-ratio__media-hover {
    opacity: 1;
}

.block-ratio .block-ratio__link:hover ~ .block-ratio__media-hover ~ .block-ratio__media, .block-ratio .block-ratio__link:focus ~ .block-ratio__media-hover ~ .block-ratio__media {
    opacity: 0;
}

.block-ratio--16x9::before {
    padding-top: 56.25%;
}

.block-ratio--16x9 .block-ratio__media > video,
.block-ratio--16x9 .block-ratio__media > picture,
.block-ratio--16x9 .block-ratio__media > img {
    position: absolute;
}

.block-ratio--9x16::before {
    padding-top: 177.7777777778%;
}

.block-ratio--9x16 .block-ratio__media > video,
.block-ratio--9x16 .block-ratio__media > picture,
.block-ratio--9x16 .block-ratio__media > img {
    position: absolute;
}

.block-ratio--4x3::before {
    padding-top: 75%;
}

.block-ratio--4x3 .block-ratio__media > video,
.block-ratio--4x3 .block-ratio__media > picture,
.block-ratio--4x3 .block-ratio__media > img {
    position: absolute;
}

.block-ratio--3x4::before {
    padding-top: 133.3333333333%;
}

.block-ratio--3x4 .block-ratio__media > video,
.block-ratio--3x4 .block-ratio__media > picture,
.block-ratio--3x4 .block-ratio__media > img {
    position: absolute;
}

.block-ratio--3x2::before {
    padding-top: 66.6666666667%;
}

.block-ratio--3x2 .block-ratio__media > video,
.block-ratio--3x2 .block-ratio__media > picture,
.block-ratio--3x2 .block-ratio__media > img {
    position: absolute;
}

.block-ratio--2x3::before {
    padding-top: 150%;
}

.block-ratio--2x3 .block-ratio__media > video,
.block-ratio--2x3 .block-ratio__media > picture,
.block-ratio--2x3 .block-ratio__media > img {
    position: absolute;
}

.block-ratio--2x1::before {
    padding-top: 50%;
}

.block-ratio--2x1 .block-ratio__media > video,
.block-ratio--2x1 .block-ratio__media > picture,
.block-ratio--2x1 .block-ratio__media > img {
    position: absolute;
}

.block-ratio--1x2::before {
    padding-top: 200%;
}

.block-ratio--1x2 .block-ratio__media > video,
.block-ratio--1x2 .block-ratio__media > picture,
.block-ratio--1x2 .block-ratio__media > img {
    position: absolute;
}

.block-ratio--1x1::before {
    padding-top: 100%;
}

.block-ratio--1x1 .block-ratio__media > video,
.block-ratio--1x1 .block-ratio__media > picture,
.block-ratio--1x1 .block-ratio__media > img {
    position: absolute;
}

.block-ratio--auto::before {
    padding-top: 0%;
}

.block-ratio--auto .block-ratio__media > video,
.block-ratio--auto .block-ratio__media > picture,
.block-ratio--auto .block-ratio__media > img {
    position: relative;
}

@media (min-width: 360px) {
    .m1\:block-ratio--16x9::before {
        padding-top: 56.25%;
    }

    .m1\:block-ratio--16x9 .block-ratio__media > video,
    .m1\:block-ratio--16x9 .block-ratio__media > picture,
    .m1\:block-ratio--16x9 .block-ratio__media > img {
        position: absolute;
    }

    .m1\:block-ratio--9x16::before {
        padding-top: 177.7777777778%;
    }

    .m1\:block-ratio--9x16 .block-ratio__media > video,
    .m1\:block-ratio--9x16 .block-ratio__media > picture,
    .m1\:block-ratio--9x16 .block-ratio__media > img {
        position: absolute;
    }

    .m1\:block-ratio--4x3::before {
        padding-top: 75%;
    }

    .m1\:block-ratio--4x3 .block-ratio__media > video,
    .m1\:block-ratio--4x3 .block-ratio__media > picture,
    .m1\:block-ratio--4x3 .block-ratio__media > img {
        position: absolute;
    }

    .m1\:block-ratio--3x4::before {
        padding-top: 133.3333333333%;
    }

    .m1\:block-ratio--3x4 .block-ratio__media > video,
    .m1\:block-ratio--3x4 .block-ratio__media > picture,
    .m1\:block-ratio--3x4 .block-ratio__media > img {
        position: absolute;
    }

    .m1\:block-ratio--3x2::before {
        padding-top: 66.6666666667%;
    }

    .m1\:block-ratio--3x2 .block-ratio__media > video,
    .m1\:block-ratio--3x2 .block-ratio__media > picture,
    .m1\:block-ratio--3x2 .block-ratio__media > img {
        position: absolute;
    }

    .m1\:block-ratio--2x3::before {
        padding-top: 150%;
    }

    .m1\:block-ratio--2x3 .block-ratio__media > video,
    .m1\:block-ratio--2x3 .block-ratio__media > picture,
    .m1\:block-ratio--2x3 .block-ratio__media > img {
        position: absolute;
    }

    .m1\:block-ratio--2x1::before {
        padding-top: 50%;
    }

    .m1\:block-ratio--2x1 .block-ratio__media > video,
    .m1\:block-ratio--2x1 .block-ratio__media > picture,
    .m1\:block-ratio--2x1 .block-ratio__media > img {
        position: absolute;
    }

    .m1\:block-ratio--1x2::before {
        padding-top: 200%;
    }

    .m1\:block-ratio--1x2 .block-ratio__media > video,
    .m1\:block-ratio--1x2 .block-ratio__media > picture,
    .m1\:block-ratio--1x2 .block-ratio__media > img {
        position: absolute;
    }

    .m1\:block-ratio--1x1::before {
        padding-top: 100%;
    }

    .m1\:block-ratio--1x1 .block-ratio__media > video,
    .m1\:block-ratio--1x1 .block-ratio__media > picture,
    .m1\:block-ratio--1x1 .block-ratio__media > img {
        position: absolute;
    }

    .m1\:block-ratio--auto::before {
        padding-top: 0%;
    }

    .m1\:block-ratio--auto .block-ratio__media > video,
    .m1\:block-ratio--auto .block-ratio__media > picture,
    .m1\:block-ratio--auto .block-ratio__media > img {
        position: relative;
    }
}

@media (min-width: 414px) {
    .m2\:block-ratio--16x9::before {
        padding-top: 56.25%;
    }

    .m2\:block-ratio--16x9 .block-ratio__media > video,
    .m2\:block-ratio--16x9 .block-ratio__media > picture,
    .m2\:block-ratio--16x9 .block-ratio__media > img {
        position: absolute;
    }

    .m2\:block-ratio--9x16::before {
        padding-top: 177.7777777778%;
    }

    .m2\:block-ratio--9x16 .block-ratio__media > video,
    .m2\:block-ratio--9x16 .block-ratio__media > picture,
    .m2\:block-ratio--9x16 .block-ratio__media > img {
        position: absolute;
    }

    .m2\:block-ratio--4x3::before {
        padding-top: 75%;
    }

    .m2\:block-ratio--4x3 .block-ratio__media > video,
    .m2\:block-ratio--4x3 .block-ratio__media > picture,
    .m2\:block-ratio--4x3 .block-ratio__media > img {
        position: absolute;
    }

    .m2\:block-ratio--3x4::before {
        padding-top: 133.3333333333%;
    }

    .m2\:block-ratio--3x4 .block-ratio__media > video,
    .m2\:block-ratio--3x4 .block-ratio__media > picture,
    .m2\:block-ratio--3x4 .block-ratio__media > img {
        position: absolute;
    }

    .m2\:block-ratio--3x2::before {
        padding-top: 66.6666666667%;
    }

    .m2\:block-ratio--3x2 .block-ratio__media > video,
    .m2\:block-ratio--3x2 .block-ratio__media > picture,
    .m2\:block-ratio--3x2 .block-ratio__media > img {
        position: absolute;
    }

    .m2\:block-ratio--2x3::before {
        padding-top: 150%;
    }

    .m2\:block-ratio--2x3 .block-ratio__media > video,
    .m2\:block-ratio--2x3 .block-ratio__media > picture,
    .m2\:block-ratio--2x3 .block-ratio__media > img {
        position: absolute;
    }

    .m2\:block-ratio--2x1::before {
        padding-top: 50%;
    }

    .m2\:block-ratio--2x1 .block-ratio__media > video,
    .m2\:block-ratio--2x1 .block-ratio__media > picture,
    .m2\:block-ratio--2x1 .block-ratio__media > img {
        position: absolute;
    }

    .m2\:block-ratio--1x2::before {
        padding-top: 200%;
    }

    .m2\:block-ratio--1x2 .block-ratio__media > video,
    .m2\:block-ratio--1x2 .block-ratio__media > picture,
    .m2\:block-ratio--1x2 .block-ratio__media > img {
        position: absolute;
    }

    .m2\:block-ratio--1x1::before {
        padding-top: 100%;
    }

    .m2\:block-ratio--1x1 .block-ratio__media > video,
    .m2\:block-ratio--1x1 .block-ratio__media > picture,
    .m2\:block-ratio--1x1 .block-ratio__media > img {
        position: absolute;
    }

    .m2\:block-ratio--auto::before {
        padding-top: 0%;
    }

    .m2\:block-ratio--auto .block-ratio__media > video,
    .m2\:block-ratio--auto .block-ratio__media > picture,
    .m2\:block-ratio--auto .block-ratio__media > img {
        position: relative;
    }
}

@media (min-width: 480px) {
    .m3\:block-ratio--16x9::before {
        padding-top: 56.25%;
    }

    .m3\:block-ratio--16x9 .block-ratio__media > video,
    .m3\:block-ratio--16x9 .block-ratio__media > picture,
    .m3\:block-ratio--16x9 .block-ratio__media > img {
        position: absolute;
    }

    .m3\:block-ratio--9x16::before {
        padding-top: 177.7777777778%;
    }

    .m3\:block-ratio--9x16 .block-ratio__media > video,
    .m3\:block-ratio--9x16 .block-ratio__media > picture,
    .m3\:block-ratio--9x16 .block-ratio__media > img {
        position: absolute;
    }

    .m3\:block-ratio--4x3::before {
        padding-top: 75%;
    }

    .m3\:block-ratio--4x3 .block-ratio__media > video,
    .m3\:block-ratio--4x3 .block-ratio__media > picture,
    .m3\:block-ratio--4x3 .block-ratio__media > img {
        position: absolute;
    }

    .m3\:block-ratio--3x4::before {
        padding-top: 133.3333333333%;
    }

    .m3\:block-ratio--3x4 .block-ratio__media > video,
    .m3\:block-ratio--3x4 .block-ratio__media > picture,
    .m3\:block-ratio--3x4 .block-ratio__media > img {
        position: absolute;
    }

    .m3\:block-ratio--3x2::before {
        padding-top: 66.6666666667%;
    }

    .m3\:block-ratio--3x2 .block-ratio__media > video,
    .m3\:block-ratio--3x2 .block-ratio__media > picture,
    .m3\:block-ratio--3x2 .block-ratio__media > img {
        position: absolute;
    }

    .m3\:block-ratio--2x3::before {
        padding-top: 150%;
    }

    .m3\:block-ratio--2x3 .block-ratio__media > video,
    .m3\:block-ratio--2x3 .block-ratio__media > picture,
    .m3\:block-ratio--2x3 .block-ratio__media > img {
        position: absolute;
    }

    .m3\:block-ratio--2x1::before {
        padding-top: 50%;
    }

    .m3\:block-ratio--2x1 .block-ratio__media > video,
    .m3\:block-ratio--2x1 .block-ratio__media > picture,
    .m3\:block-ratio--2x1 .block-ratio__media > img {
        position: absolute;
    }

    .m3\:block-ratio--1x2::before {
        padding-top: 200%;
    }

    .m3\:block-ratio--1x2 .block-ratio__media > video,
    .m3\:block-ratio--1x2 .block-ratio__media > picture,
    .m3\:block-ratio--1x2 .block-ratio__media > img {
        position: absolute;
    }

    .m3\:block-ratio--1x1::before {
        padding-top: 100%;
    }

    .m3\:block-ratio--1x1 .block-ratio__media > video,
    .m3\:block-ratio--1x1 .block-ratio__media > picture,
    .m3\:block-ratio--1x1 .block-ratio__media > img {
        position: absolute;
    }

    .m3\:block-ratio--auto::before {
        padding-top: 0%;
    }

    .m3\:block-ratio--auto .block-ratio__media > video,
    .m3\:block-ratio--auto .block-ratio__media > picture,
    .m3\:block-ratio--auto .block-ratio__media > img {
        position: relative;
    }
}

@media (min-width: 576px) {
    .m4\:block-ratio--16x9::before {
        padding-top: 56.25%;
    }

    .m4\:block-ratio--16x9 .block-ratio__media > video,
    .m4\:block-ratio--16x9 .block-ratio__media > picture,
    .m4\:block-ratio--16x9 .block-ratio__media > img {
        position: absolute;
    }

    .m4\:block-ratio--9x16::before {
        padding-top: 177.7777777778%;
    }

    .m4\:block-ratio--9x16 .block-ratio__media > video,
    .m4\:block-ratio--9x16 .block-ratio__media > picture,
    .m4\:block-ratio--9x16 .block-ratio__media > img {
        position: absolute;
    }

    .m4\:block-ratio--4x3::before {
        padding-top: 75%;
    }

    .m4\:block-ratio--4x3 .block-ratio__media > video,
    .m4\:block-ratio--4x3 .block-ratio__media > picture,
    .m4\:block-ratio--4x3 .block-ratio__media > img {
        position: absolute;
    }

    .m4\:block-ratio--3x4::before {
        padding-top: 133.3333333333%;
    }

    .m4\:block-ratio--3x4 .block-ratio__media > video,
    .m4\:block-ratio--3x4 .block-ratio__media > picture,
    .m4\:block-ratio--3x4 .block-ratio__media > img {
        position: absolute;
    }

    .m4\:block-ratio--3x2::before {
        padding-top: 66.6666666667%;
    }

    .m4\:block-ratio--3x2 .block-ratio__media > video,
    .m4\:block-ratio--3x2 .block-ratio__media > picture,
    .m4\:block-ratio--3x2 .block-ratio__media > img {
        position: absolute;
    }

    .m4\:block-ratio--2x3::before {
        padding-top: 150%;
    }

    .m4\:block-ratio--2x3 .block-ratio__media > video,
    .m4\:block-ratio--2x3 .block-ratio__media > picture,
    .m4\:block-ratio--2x3 .block-ratio__media > img {
        position: absolute;
    }

    .m4\:block-ratio--2x1::before {
        padding-top: 50%;
    }

    .m4\:block-ratio--2x1 .block-ratio__media > video,
    .m4\:block-ratio--2x1 .block-ratio__media > picture,
    .m4\:block-ratio--2x1 .block-ratio__media > img {
        position: absolute;
    }

    .m4\:block-ratio--1x2::before {
        padding-top: 200%;
    }

    .m4\:block-ratio--1x2 .block-ratio__media > video,
    .m4\:block-ratio--1x2 .block-ratio__media > picture,
    .m4\:block-ratio--1x2 .block-ratio__media > img {
        position: absolute;
    }

    .m4\:block-ratio--1x1::before {
        padding-top: 100%;
    }

    .m4\:block-ratio--1x1 .block-ratio__media > video,
    .m4\:block-ratio--1x1 .block-ratio__media > picture,
    .m4\:block-ratio--1x1 .block-ratio__media > img {
        position: absolute;
    }

    .m4\:block-ratio--auto::before {
        padding-top: 0%;
    }

    .m4\:block-ratio--auto .block-ratio__media > video,
    .m4\:block-ratio--auto .block-ratio__media > picture,
    .m4\:block-ratio--auto .block-ratio__media > img {
        position: relative;
    }
}

@media (min-width: 640px) {
    .t1\:block-ratio--16x9::before {
        padding-top: 56.25%;
    }

    .t1\:block-ratio--16x9 .block-ratio__media > video,
    .t1\:block-ratio--16x9 .block-ratio__media > picture,
    .t1\:block-ratio--16x9 .block-ratio__media > img {
        position: absolute;
    }

    .t1\:block-ratio--9x16::before {
        padding-top: 177.7777777778%;
    }

    .t1\:block-ratio--9x16 .block-ratio__media > video,
    .t1\:block-ratio--9x16 .block-ratio__media > picture,
    .t1\:block-ratio--9x16 .block-ratio__media > img {
        position: absolute;
    }

    .t1\:block-ratio--4x3::before {
        padding-top: 75%;
    }

    .t1\:block-ratio--4x3 .block-ratio__media > video,
    .t1\:block-ratio--4x3 .block-ratio__media > picture,
    .t1\:block-ratio--4x3 .block-ratio__media > img {
        position: absolute;
    }

    .t1\:block-ratio--3x4::before {
        padding-top: 133.3333333333%;
    }

    .t1\:block-ratio--3x4 .block-ratio__media > video,
    .t1\:block-ratio--3x4 .block-ratio__media > picture,
    .t1\:block-ratio--3x4 .block-ratio__media > img {
        position: absolute;
    }

    .t1\:block-ratio--3x2::before {
        padding-top: 66.6666666667%;
    }

    .t1\:block-ratio--3x2 .block-ratio__media > video,
    .t1\:block-ratio--3x2 .block-ratio__media > picture,
    .t1\:block-ratio--3x2 .block-ratio__media > img {
        position: absolute;
    }

    .t1\:block-ratio--2x3::before {
        padding-top: 150%;
    }

    .t1\:block-ratio--2x3 .block-ratio__media > video,
    .t1\:block-ratio--2x3 .block-ratio__media > picture,
    .t1\:block-ratio--2x3 .block-ratio__media > img {
        position: absolute;
    }

    .t1\:block-ratio--2x1::before {
        padding-top: 50%;
    }

    .t1\:block-ratio--2x1 .block-ratio__media > video,
    .t1\:block-ratio--2x1 .block-ratio__media > picture,
    .t1\:block-ratio--2x1 .block-ratio__media > img {
        position: absolute;
    }

    .t1\:block-ratio--1x2::before {
        padding-top: 200%;
    }

    .t1\:block-ratio--1x2 .block-ratio__media > video,
    .t1\:block-ratio--1x2 .block-ratio__media > picture,
    .t1\:block-ratio--1x2 .block-ratio__media > img {
        position: absolute;
    }

    .t1\:block-ratio--1x1::before {
        padding-top: 100%;
    }

    .t1\:block-ratio--1x1 .block-ratio__media > video,
    .t1\:block-ratio--1x1 .block-ratio__media > picture,
    .t1\:block-ratio--1x1 .block-ratio__media > img {
        position: absolute;
    }

    .t1\:block-ratio--auto::before {
        padding-top: 0%;
    }

    .t1\:block-ratio--auto .block-ratio__media > video,
    .t1\:block-ratio--auto .block-ratio__media > picture,
    .t1\:block-ratio--auto .block-ratio__media > img {
        position: relative;
    }
}

@media (min-width: 768px) {
    .t2\:block-ratio--16x9::before {
        padding-top: 56.25%;
    }

    .t2\:block-ratio--16x9 .block-ratio__media > video,
    .t2\:block-ratio--16x9 .block-ratio__media > picture,
    .t2\:block-ratio--16x9 .block-ratio__media > img {
        position: absolute;
    }

    .t2\:block-ratio--9x16::before {
        padding-top: 177.7777777778%;
    }

    .t2\:block-ratio--9x16 .block-ratio__media > video,
    .t2\:block-ratio--9x16 .block-ratio__media > picture,
    .t2\:block-ratio--9x16 .block-ratio__media > img {
        position: absolute;
    }

    .t2\:block-ratio--4x3::before {
        padding-top: 75%;
    }

    .t2\:block-ratio--4x3 .block-ratio__media > video,
    .t2\:block-ratio--4x3 .block-ratio__media > picture,
    .t2\:block-ratio--4x3 .block-ratio__media > img {
        position: absolute;
    }

    .t2\:block-ratio--3x4::before {
        padding-top: 133.3333333333%;
    }

    .t2\:block-ratio--3x4 .block-ratio__media > video,
    .t2\:block-ratio--3x4 .block-ratio__media > picture,
    .t2\:block-ratio--3x4 .block-ratio__media > img {
        position: absolute;
    }

    .t2\:block-ratio--3x2::before {
        padding-top: 66.6666666667%;
    }

    .t2\:block-ratio--3x2 .block-ratio__media > video,
    .t2\:block-ratio--3x2 .block-ratio__media > picture,
    .t2\:block-ratio--3x2 .block-ratio__media > img {
        position: absolute;
    }

    .t2\:block-ratio--2x3::before {
        padding-top: 150%;
    }

    .t2\:block-ratio--2x3 .block-ratio__media > video,
    .t2\:block-ratio--2x3 .block-ratio__media > picture,
    .t2\:block-ratio--2x3 .block-ratio__media > img {
        position: absolute;
    }

    .t2\:block-ratio--2x1::before {
        padding-top: 50%;
    }

    .t2\:block-ratio--2x1 .block-ratio__media > video,
    .t2\:block-ratio--2x1 .block-ratio__media > picture,
    .t2\:block-ratio--2x1 .block-ratio__media > img {
        position: absolute;
    }

    .t2\:block-ratio--1x2::before {
        padding-top: 200%;
    }

    .t2\:block-ratio--1x2 .block-ratio__media > video,
    .t2\:block-ratio--1x2 .block-ratio__media > picture,
    .t2\:block-ratio--1x2 .block-ratio__media > img {
        position: absolute;
    }

    .t2\:block-ratio--1x1::before {
        padding-top: 100%;
    }

    .t2\:block-ratio--1x1 .block-ratio__media > video,
    .t2\:block-ratio--1x1 .block-ratio__media > picture,
    .t2\:block-ratio--1x1 .block-ratio__media > img {
        position: absolute;
    }

    .t2\:block-ratio--auto::before {
        padding-top: 0%;
    }

    .t2\:block-ratio--auto .block-ratio__media > video,
    .t2\:block-ratio--auto .block-ratio__media > picture,
    .t2\:block-ratio--auto .block-ratio__media > img {
        position: relative;
    }
}

@media (min-width: 812px) {
    .t3\:block-ratio--16x9::before {
        padding-top: 56.25%;
    }

    .t3\:block-ratio--16x9 .block-ratio__media > video,
    .t3\:block-ratio--16x9 .block-ratio__media > picture,
    .t3\:block-ratio--16x9 .block-ratio__media > img {
        position: absolute;
    }

    .t3\:block-ratio--9x16::before {
        padding-top: 177.7777777778%;
    }

    .t3\:block-ratio--9x16 .block-ratio__media > video,
    .t3\:block-ratio--9x16 .block-ratio__media > picture,
    .t3\:block-ratio--9x16 .block-ratio__media > img {
        position: absolute;
    }

    .t3\:block-ratio--4x3::before {
        padding-top: 75%;
    }

    .t3\:block-ratio--4x3 .block-ratio__media > video,
    .t3\:block-ratio--4x3 .block-ratio__media > picture,
    .t3\:block-ratio--4x3 .block-ratio__media > img {
        position: absolute;
    }

    .t3\:block-ratio--3x4::before {
        padding-top: 133.3333333333%;
    }

    .t3\:block-ratio--3x4 .block-ratio__media > video,
    .t3\:block-ratio--3x4 .block-ratio__media > picture,
    .t3\:block-ratio--3x4 .block-ratio__media > img {
        position: absolute;
    }

    .t3\:block-ratio--3x2::before {
        padding-top: 66.6666666667%;
    }

    .t3\:block-ratio--3x2 .block-ratio__media > video,
    .t3\:block-ratio--3x2 .block-ratio__media > picture,
    .t3\:block-ratio--3x2 .block-ratio__media > img {
        position: absolute;
    }

    .t3\:block-ratio--2x3::before {
        padding-top: 150%;
    }

    .t3\:block-ratio--2x3 .block-ratio__media > video,
    .t3\:block-ratio--2x3 .block-ratio__media > picture,
    .t3\:block-ratio--2x3 .block-ratio__media > img {
        position: absolute;
    }

    .t3\:block-ratio--2x1::before {
        padding-top: 50%;
    }

    .t3\:block-ratio--2x1 .block-ratio__media > video,
    .t3\:block-ratio--2x1 .block-ratio__media > picture,
    .t3\:block-ratio--2x1 .block-ratio__media > img {
        position: absolute;
    }

    .t3\:block-ratio--1x2::before {
        padding-top: 200%;
    }

    .t3\:block-ratio--1x2 .block-ratio__media > video,
    .t3\:block-ratio--1x2 .block-ratio__media > picture,
    .t3\:block-ratio--1x2 .block-ratio__media > img {
        position: absolute;
    }

    .t3\:block-ratio--1x1::before {
        padding-top: 100%;
    }

    .t3\:block-ratio--1x1 .block-ratio__media > video,
    .t3\:block-ratio--1x1 .block-ratio__media > picture,
    .t3\:block-ratio--1x1 .block-ratio__media > img {
        position: absolute;
    }

    .t3\:block-ratio--auto::before {
        padding-top: 0%;
    }

    .t3\:block-ratio--auto .block-ratio__media > video,
    .t3\:block-ratio--auto .block-ratio__media > picture,
    .t3\:block-ratio--auto .block-ratio__media > img {
        position: relative;
    }
}

@media (min-width: 992px) {
    .t4\:block-ratio--16x9::before {
        padding-top: 56.25%;
    }

    .t4\:block-ratio--16x9 .block-ratio__media > video,
    .t4\:block-ratio--16x9 .block-ratio__media > picture,
    .t4\:block-ratio--16x9 .block-ratio__media > img {
        position: absolute;
    }

    .t4\:block-ratio--9x16::before {
        padding-top: 177.7777777778%;
    }

    .t4\:block-ratio--9x16 .block-ratio__media > video,
    .t4\:block-ratio--9x16 .block-ratio__media > picture,
    .t4\:block-ratio--9x16 .block-ratio__media > img {
        position: absolute;
    }

    .t4\:block-ratio--4x3::before {
        padding-top: 75%;
    }

    .t4\:block-ratio--4x3 .block-ratio__media > video,
    .t4\:block-ratio--4x3 .block-ratio__media > picture,
    .t4\:block-ratio--4x3 .block-ratio__media > img {
        position: absolute;
    }

    .t4\:block-ratio--3x4::before {
        padding-top: 133.3333333333%;
    }

    .t4\:block-ratio--3x4 .block-ratio__media > video,
    .t4\:block-ratio--3x4 .block-ratio__media > picture,
    .t4\:block-ratio--3x4 .block-ratio__media > img {
        position: absolute;
    }

    .t4\:block-ratio--3x2::before {
        padding-top: 66.6666666667%;
    }

    .t4\:block-ratio--3x2 .block-ratio__media > video,
    .t4\:block-ratio--3x2 .block-ratio__media > picture,
    .t4\:block-ratio--3x2 .block-ratio__media > img {
        position: absolute;
    }

    .t4\:block-ratio--2x3::before {
        padding-top: 150%;
    }

    .t4\:block-ratio--2x3 .block-ratio__media > video,
    .t4\:block-ratio--2x3 .block-ratio__media > picture,
    .t4\:block-ratio--2x3 .block-ratio__media > img {
        position: absolute;
    }

    .t4\:block-ratio--2x1::before {
        padding-top: 50%;
    }

    .t4\:block-ratio--2x1 .block-ratio__media > video,
    .t4\:block-ratio--2x1 .block-ratio__media > picture,
    .t4\:block-ratio--2x1 .block-ratio__media > img {
        position: absolute;
    }

    .t4\:block-ratio--1x2::before {
        padding-top: 200%;
    }

    .t4\:block-ratio--1x2 .block-ratio__media > video,
    .t4\:block-ratio--1x2 .block-ratio__media > picture,
    .t4\:block-ratio--1x2 .block-ratio__media > img {
        position: absolute;
    }

    .t4\:block-ratio--1x1::before {
        padding-top: 100%;
    }

    .t4\:block-ratio--1x1 .block-ratio__media > video,
    .t4\:block-ratio--1x1 .block-ratio__media > picture,
    .t4\:block-ratio--1x1 .block-ratio__media > img {
        position: absolute;
    }

    .t4\:block-ratio--auto::before {
        padding-top: 0%;
    }

    .t4\:block-ratio--auto .block-ratio__media > video,
    .t4\:block-ratio--auto .block-ratio__media > picture,
    .t4\:block-ratio--auto .block-ratio__media > img {
        position: relative;
    }
}

@media (min-width: 1200px) {
    .d1\:block-ratio--16x9::before {
        padding-top: 56.25%;
    }

    .d1\:block-ratio--16x9 .block-ratio__media > video,
    .d1\:block-ratio--16x9 .block-ratio__media > picture,
    .d1\:block-ratio--16x9 .block-ratio__media > img {
        position: absolute;
    }

    .d1\:block-ratio--9x16::before {
        padding-top: 177.7777777778%;
    }

    .d1\:block-ratio--9x16 .block-ratio__media > video,
    .d1\:block-ratio--9x16 .block-ratio__media > picture,
    .d1\:block-ratio--9x16 .block-ratio__media > img {
        position: absolute;
    }

    .d1\:block-ratio--4x3::before {
        padding-top: 75%;
    }

    .d1\:block-ratio--4x3 .block-ratio__media > video,
    .d1\:block-ratio--4x3 .block-ratio__media > picture,
    .d1\:block-ratio--4x3 .block-ratio__media > img {
        position: absolute;
    }

    .d1\:block-ratio--3x4::before {
        padding-top: 133.3333333333%;
    }

    .d1\:block-ratio--3x4 .block-ratio__media > video,
    .d1\:block-ratio--3x4 .block-ratio__media > picture,
    .d1\:block-ratio--3x4 .block-ratio__media > img {
        position: absolute;
    }

    .d1\:block-ratio--3x2::before {
        padding-top: 66.6666666667%;
    }

    .d1\:block-ratio--3x2 .block-ratio__media > video,
    .d1\:block-ratio--3x2 .block-ratio__media > picture,
    .d1\:block-ratio--3x2 .block-ratio__media > img {
        position: absolute;
    }

    .d1\:block-ratio--2x3::before {
        padding-top: 150%;
    }

    .d1\:block-ratio--2x3 .block-ratio__media > video,
    .d1\:block-ratio--2x3 .block-ratio__media > picture,
    .d1\:block-ratio--2x3 .block-ratio__media > img {
        position: absolute;
    }

    .d1\:block-ratio--2x1::before {
        padding-top: 50%;
    }

    .d1\:block-ratio--2x1 .block-ratio__media > video,
    .d1\:block-ratio--2x1 .block-ratio__media > picture,
    .d1\:block-ratio--2x1 .block-ratio__media > img {
        position: absolute;
    }

    .d1\:block-ratio--1x2::before {
        padding-top: 200%;
    }

    .d1\:block-ratio--1x2 .block-ratio__media > video,
    .d1\:block-ratio--1x2 .block-ratio__media > picture,
    .d1\:block-ratio--1x2 .block-ratio__media > img {
        position: absolute;
    }

    .d1\:block-ratio--1x1::before {
        padding-top: 100%;
    }

    .d1\:block-ratio--1x1 .block-ratio__media > video,
    .d1\:block-ratio--1x1 .block-ratio__media > picture,
    .d1\:block-ratio--1x1 .block-ratio__media > img {
        position: absolute;
    }

    .d1\:block-ratio--auto::before {
        padding-top: 0%;
    }

    .d1\:block-ratio--auto .block-ratio__media > video,
    .d1\:block-ratio--auto .block-ratio__media > picture,
    .d1\:block-ratio--auto .block-ratio__media > img {
        position: relative;
    }
}

@media (min-width: 1400px) {
    .d2\:block-ratio--16x9::before {
        padding-top: 56.25%;
    }

    .d2\:block-ratio--16x9 .block-ratio__media > video,
    .d2\:block-ratio--16x9 .block-ratio__media > picture,
    .d2\:block-ratio--16x9 .block-ratio__media > img {
        position: absolute;
    }

    .d2\:block-ratio--9x16::before {
        padding-top: 177.7777777778%;
    }

    .d2\:block-ratio--9x16 .block-ratio__media > video,
    .d2\:block-ratio--9x16 .block-ratio__media > picture,
    .d2\:block-ratio--9x16 .block-ratio__media > img {
        position: absolute;
    }

    .d2\:block-ratio--4x3::before {
        padding-top: 75%;
    }

    .d2\:block-ratio--4x3 .block-ratio__media > video,
    .d2\:block-ratio--4x3 .block-ratio__media > picture,
    .d2\:block-ratio--4x3 .block-ratio__media > img {
        position: absolute;
    }

    .d2\:block-ratio--3x4::before {
        padding-top: 133.3333333333%;
    }

    .d2\:block-ratio--3x4 .block-ratio__media > video,
    .d2\:block-ratio--3x4 .block-ratio__media > picture,
    .d2\:block-ratio--3x4 .block-ratio__media > img {
        position: absolute;
    }

    .d2\:block-ratio--3x2::before {
        padding-top: 66.6666666667%;
    }

    .d2\:block-ratio--3x2 .block-ratio__media > video,
    .d2\:block-ratio--3x2 .block-ratio__media > picture,
    .d2\:block-ratio--3x2 .block-ratio__media > img {
        position: absolute;
    }

    .d2\:block-ratio--2x3::before {
        padding-top: 150%;
    }

    .d2\:block-ratio--2x3 .block-ratio__media > video,
    .d2\:block-ratio--2x3 .block-ratio__media > picture,
    .d2\:block-ratio--2x3 .block-ratio__media > img {
        position: absolute;
    }

    .d2\:block-ratio--2x1::before {
        padding-top: 50%;
    }

    .d2\:block-ratio--2x1 .block-ratio__media > video,
    .d2\:block-ratio--2x1 .block-ratio__media > picture,
    .d2\:block-ratio--2x1 .block-ratio__media > img {
        position: absolute;
    }

    .d2\:block-ratio--1x2::before {
        padding-top: 200%;
    }

    .d2\:block-ratio--1x2 .block-ratio__media > video,
    .d2\:block-ratio--1x2 .block-ratio__media > picture,
    .d2\:block-ratio--1x2 .block-ratio__media > img {
        position: absolute;
    }

    .d2\:block-ratio--1x1::before {
        padding-top: 100%;
    }

    .d2\:block-ratio--1x1 .block-ratio__media > video,
    .d2\:block-ratio--1x1 .block-ratio__media > picture,
    .d2\:block-ratio--1x1 .block-ratio__media > img {
        position: absolute;
    }

    .d2\:block-ratio--auto::before {
        padding-top: 0%;
    }

    .d2\:block-ratio--auto .block-ratio__media > video,
    .d2\:block-ratio--auto .block-ratio__media > picture,
    .d2\:block-ratio--auto .block-ratio__media > img {
        position: relative;
    }
}

@media (min-width: 1600px) {
    .d3\:block-ratio--16x9::before {
        padding-top: 56.25%;
    }

    .d3\:block-ratio--16x9 .block-ratio__media > video,
    .d3\:block-ratio--16x9 .block-ratio__media > picture,
    .d3\:block-ratio--16x9 .block-ratio__media > img {
        position: absolute;
    }

    .d3\:block-ratio--9x16::before {
        padding-top: 177.7777777778%;
    }

    .d3\:block-ratio--9x16 .block-ratio__media > video,
    .d3\:block-ratio--9x16 .block-ratio__media > picture,
    .d3\:block-ratio--9x16 .block-ratio__media > img {
        position: absolute;
    }

    .d3\:block-ratio--4x3::before {
        padding-top: 75%;
    }

    .d3\:block-ratio--4x3 .block-ratio__media > video,
    .d3\:block-ratio--4x3 .block-ratio__media > picture,
    .d3\:block-ratio--4x3 .block-ratio__media > img {
        position: absolute;
    }

    .d3\:block-ratio--3x4::before {
        padding-top: 133.3333333333%;
    }

    .d3\:block-ratio--3x4 .block-ratio__media > video,
    .d3\:block-ratio--3x4 .block-ratio__media > picture,
    .d3\:block-ratio--3x4 .block-ratio__media > img {
        position: absolute;
    }

    .d3\:block-ratio--3x2::before {
        padding-top: 66.6666666667%;
    }

    .d3\:block-ratio--3x2 .block-ratio__media > video,
    .d3\:block-ratio--3x2 .block-ratio__media > picture,
    .d3\:block-ratio--3x2 .block-ratio__media > img {
        position: absolute;
    }

    .d3\:block-ratio--2x3::before {
        padding-top: 150%;
    }

    .d3\:block-ratio--2x3 .block-ratio__media > video,
    .d3\:block-ratio--2x3 .block-ratio__media > picture,
    .d3\:block-ratio--2x3 .block-ratio__media > img {
        position: absolute;
    }

    .d3\:block-ratio--2x1::before {
        padding-top: 50%;
    }

    .d3\:block-ratio--2x1 .block-ratio__media > video,
    .d3\:block-ratio--2x1 .block-ratio__media > picture,
    .d3\:block-ratio--2x1 .block-ratio__media > img {
        position: absolute;
    }

    .d3\:block-ratio--1x2::before {
        padding-top: 200%;
    }

    .d3\:block-ratio--1x2 .block-ratio__media > video,
    .d3\:block-ratio--1x2 .block-ratio__media > picture,
    .d3\:block-ratio--1x2 .block-ratio__media > img {
        position: absolute;
    }

    .d3\:block-ratio--1x1::before {
        padding-top: 100%;
    }

    .d3\:block-ratio--1x1 .block-ratio__media > video,
    .d3\:block-ratio--1x1 .block-ratio__media > picture,
    .d3\:block-ratio--1x1 .block-ratio__media > img {
        position: absolute;
    }

    .d3\:block-ratio--auto::before {
        padding-top: 0%;
    }

    .d3\:block-ratio--auto .block-ratio__media > video,
    .d3\:block-ratio--auto .block-ratio__media > picture,
    .d3\:block-ratio--auto .block-ratio__media > img {
        position: relative;
    }
}

.sr-only,
.sr-only-focusable:not(:focus) {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
}

.u-overflow-auto {
    overflow: auto !important;
}

.u-overflow-hidden {
    overflow: hidden !important;
}

.u-d-none {
    display: none !important;
}

.u-d-inline {
    display: inline !important;
}

.u-d-inline-block {
    display: inline-block !important;
}

.u-d-block {
    display: block !important;
}

.u-d-table {
    display: table !important;
}

.u-d-table-row {
    display: table-row !important;
}

.u-d-table-cell {
    display: table-cell !important;
}

.u-d-flex {
    display: flex !important;
}

.u-d-inline-flex {
    display: inline-flex !important;
}

.u-pos-static {
    position: static !important;
}

.u-pos-relative {
    position: relative !important;
}

.u-pos-absolute {
    position: absolute !important;
}

.u-pos-fixed {
    position: fixed !important;
}

.u-pos-sticky {
    position: sticky !important;
}

.u-minw-auto {
    min-width: auto !important;
}

.u-minw-0 {
    min-width: 0 !important;
}

.u-minw-100 {
    min-width: 10rem !important;
}

.u-h-auto {
    height: auto !important;
}

.u-h-0 {
    height: 0 !important;
}

.u-h-100p {
    height: 100% !important;
}

.u-flex-fill {
    flex: 1 1 auto !important;
}

.u-flex-row {
    flex-direction: row !important;
}

.u-flex-column {
    flex-direction: column !important;
}

.u-flex-row-reverse {
    flex-direction: row-reverse !important;
}

.u-flex-column-reverse {
    flex-direction: column-reverse !important;
}

.u-flex-grow-0 {
    flex-grow: 0 !important;
}

.u-flex-grow-1 {
    flex-grow: 1 !important;
}

.u-flex-shrink-0 {
    flex-shrink: 0 !important;
}

.u-flex-shrink-1 {
    flex-shrink: 1 !important;
}

.u-flex-wrap {
    flex-wrap: wrap !important;
}

.u-flex-nowrap {
    flex-wrap: nowrap !important;
}

.u-flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
}

.u-jc-start {
    justify-content: flex-start !important;
}

.u-jc-end {
    justify-content: flex-end !important;
}

.u-jc-center {
    justify-content: center !important;
}

.u-jc-between {
    justify-content: space-between !important;
}

.u-jc-around {
    justify-content: space-around !important;
}

.u-jc-evenly {
    justify-content: space-evenly !important;
}

.u-ai-start {
    align-items: flex-start !important;
}

.u-ai-end {
    align-items: flex-end !important;
}

.u-ai-center {
    align-items: center !important;
}

.u-ai-baseline {
    align-items: baseline !important;
}

.u-ai-stretch {
    align-items: stretch !important;
}

.u-ac-start {
    align-content: flex-start !important;
}

.u-ac-end {
    align-content: flex-end !important;
}

.u-ac-center {
    align-content: center !important;
}

.u-ac-between {
    align-content: space-between !important;
}

.u-ac-around {
    align-content: space-around !important;
}

.u-ac-stretch {
    align-content: stretch !important;
}

.u-as-auto {
    align-self: auto !important;
}

.u-as-start {
    align-self: flex-start !important;
}

.u-as-end {
    align-self: flex-end !important;
}

.u-as-center {
    align-self: center !important;
}

.u-as-baseline {
    align-self: baseline !important;
}

.u-as-stretch {
    align-self: stretch !important;
}

.order-first {
    order: -1 !important;
}

.order-0 {
    order: 0 !important;
}

.order-1 {
    order: 1 !important;
}

.order-2 {
    order: 2 !important;
}

.order-3 {
    order: 3 !important;
}

.order-4 {
    order: 4 !important;
}

.order-5 {
    order: 5 !important;
}

.order-last {
    order: 6 !important;
}

.u-fw-100 {
    font-weight: 100 !important;
}

.u-fw-200 {
    font-weight: 200 !important;
}

.u-fw-300 {
    font-weight: 300 !important;
}

.u-fw-400 {
    font-weight: 400 !important;
}

.u-fw-500 {
    font-weight: 500 !important;
}

.u-fw-600 {
    font-weight: 600 !important;
}

.u-fw-700 {
    font-weight: 700 !important;
}

.u-fw-800 {
    font-weight: 800 !important;
}

.u-fw-900 {
    font-weight: 900 !important;
}

.u-fw-lighter {
    font-weight: lighter !important;
}

.u-fw-bolder {
    font-weight: bolder !important;
}

.u-tt-lower {
    text-transform: lowercase !important;
}

.u-tt-upper {
    text-transform: uppercase !important;
}

.u-tt-capital {
    text-transform: capitalize !important;
}

.u-ta-left {
    text-align: left !important;
}

.u-ta-right {
    text-align: right !important;
}

.u-ta-center {
    text-align: center !important;
}

.u-ws-wrap {
    white-space: normal !important;
}

.u-ws-nowrap {
    white-space: nowrap !important;
}

.u-td-none {
    text-decoration: none !important;
}

.u-td-underline {
    text-decoration: underline !important;
}

.u-td-line-through {
    text-decoration: line-through !important;
}

.u-fs-italic {
    font-style: italic !important;
}

.u-fs-normal {
    font-style: normal !important;
}

.u-ww-break {
    word-wrap: break-word !important;
    word-break: break-word !important;
}

.u-ff-serif {
    font-family: var(--font-family-serif) !important;
}

.u-ff-sans-serif {
    font-family: var(--font-family-sans-serif) !important;
}

.u-ff-button {
    font-family: var(--font-family-button) !important;
}

.u-ff-heading {
    font-family: var(--font-family-heading) !important;
}

.u-ff-monospace {
    font-family: var(--font-family-monospace) !important;
}

.u-us-all {
    user-select: all !important;
}

.u-us-auto {
    user-select: auto !important;
}

.u-us-none {
    user-select: none !important;
}

.u-pe-none {
    pointer-events: none !important;
}

.u-pe-auto {
    pointer-events: auto !important;
}

.visible {
    visibility: visible !important;
}

.invisible {
    visibility: hidden !important;
}

.m-0 {
    margin: 0 !important;
}

.mt-0,
.my-0 {
    margin-top: 0 !important;
}

.mr-0,
.mx-0 {
    margin-right: 0 !important;
}

.mb-0,
.my-0 {
    margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
    margin-left: 0 !important;
}

.m-4 {
    margin: 0.4rem !important;
}

.mt-4,
.my-4 {
    margin-top: 0.4rem !important;
}

.mr-4,
.mx-4 {
    margin-right: 0.4rem !important;
}

.mb-4,
.my-4 {
    margin-bottom: 0.4rem !important;
}

.ml-4,
.mx-4 {
    margin-left: 0.4rem !important;
}

.m-8 {
    margin: 0.8rem !important;
}

.mt-8,
.my-8 {
    margin-top: 0.8rem !important;
}

.mr-8,
.mx-8 {
    margin-right: 0.8rem !important;
}

.mb-8,
.my-8 {
    margin-bottom: 0.8rem !important;
}

.ml-8,
.mx-8 {
    margin-left: 0.8rem !important;
}

.p-0 {
    padding: 0 !important;
}

.pt-0,
.py-0 {
    padding-top: 0 !important;
}

.pr-0,
.px-0 {
    padding-right: 0 !important;
}

.pb-0,
.py-0 {
    padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
    padding-left: 0 !important;
}

.p-4 {
    padding: 0.4rem !important;
}

.pt-4,
.py-4 {
    padding-top: 0.4rem !important;
}

.pr-4,
.px-4 {
    padding-right: 0.4rem !important;
}

.pb-4,
.py-4 {
    padding-bottom: 0.4rem !important;
}

.pl-4,
.px-4 {
    padding-left: 0.4rem !important;
}

.p-8 {
    padding: 0.8rem !important;
}

.pt-8,
.py-8 {
    padding-top: 0.8rem !important;
}

.pr-8,
.px-8 {
    padding-right: 0.8rem !important;
}

.pb-8,
.py-8 {
    padding-bottom: 0.8rem !important;
}

.pl-8,
.px-8 {
    padding-left: 0.8rem !important;
}

.m-n4 {
    margin: -0.4rem !important;
}

.mt-n4,
.my-n4 {
    margin-top: -0.4rem !important;
}

.mr-n4,
.mx-n4 {
    margin-right: -0.4rem !important;
}

.mb-n4,
.my-n4 {
    margin-bottom: -0.4rem !important;
}

.ml-n4,
.mx-n4 {
    margin-left: -0.4rem !important;
}

.m-n8 {
    margin: -0.8rem !important;
}

.mt-n8,
.my-n8 {
    margin-top: -0.8rem !important;
}

.mr-n8,
.mx-n8 {
    margin-right: -0.8rem !important;
}

.mb-n8,
.my-n8 {
    margin-bottom: -0.8rem !important;
}

.ml-n8,
.mx-n8 {
    margin-left: -0.8rem !important;
}

.m-auto {
    margin: auto !important;
}

.mt-auto,
.my-auto {
    margin-top: auto !important;
}

.mr-auto,
.mx-auto {
    margin-right: auto !important;
}

.mb-auto,
.my-auto {
    margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
    margin-left: auto !important;
}

@media (min-width: 360px) {
    .m1\:u-d-none {
        display: none !important;
    }

    .m1\:u-d-inline {
        display: inline !important;
    }

    .m1\:u-d-inline-block {
        display: inline-block !important;
    }

    .m1\:u-d-block {
        display: block !important;
    }

    .m1\:u-d-table {
        display: table !important;
    }

    .m1\:u-d-table-row {
        display: table-row !important;
    }

    .m1\:u-d-table-cell {
        display: table-cell !important;
    }

    .m1\:u-d-flex {
        display: flex !important;
    }

    .m1\:u-d-inline-flex {
        display: inline-flex !important;
    }

    .m1\:u-minw-auto {
        min-width: auto !important;
    }

    .m1\:u-minw-0 {
        min-width: 0 !important;
    }

    .m1\:u-minw-100 {
        min-width: 10rem !important;
    }

    .m1\:u-h-auto {
        height: auto !important;
    }

    .m1\:u-h-0 {
        height: 0 !important;
    }

    .m1\:u-h-100p {
        height: 100% !important;
    }

    .m1\:u-flex-fill {
        flex: 1 1 auto !important;
    }

    .m1\:u-flex-row {
        flex-direction: row !important;
    }

    .m1\:u-flex-column {
        flex-direction: column !important;
    }

    .m1\:u-flex-row-reverse {
        flex-direction: row-reverse !important;
    }

    .m1\:u-flex-column-reverse {
        flex-direction: column-reverse !important;
    }

    .m1\:u-flex-grow-0 {
        flex-grow: 0 !important;
    }

    .m1\:u-flex-grow-1 {
        flex-grow: 1 !important;
    }

    .m1\:u-flex-shrink-0 {
        flex-shrink: 0 !important;
    }

    .m1\:u-flex-shrink-1 {
        flex-shrink: 1 !important;
    }

    .m1\:u-flex-wrap {
        flex-wrap: wrap !important;
    }

    .m1\:u-flex-nowrap {
        flex-wrap: nowrap !important;
    }

    .m1\:u-flex-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .m1\:u-jc-start {
        justify-content: flex-start !important;
    }

    .m1\:u-jc-end {
        justify-content: flex-end !important;
    }

    .m1\:u-jc-center {
        justify-content: center !important;
    }

    .m1\:u-jc-between {
        justify-content: space-between !important;
    }

    .m1\:u-jc-around {
        justify-content: space-around !important;
    }

    .m1\:u-jc-evenly {
        justify-content: space-evenly !important;
    }

    .m1\:u-ai-start {
        align-items: flex-start !important;
    }

    .m1\:u-ai-end {
        align-items: flex-end !important;
    }

    .m1\:u-ai-center {
        align-items: center !important;
    }

    .m1\:u-ai-baseline {
        align-items: baseline !important;
    }

    .m1\:u-ai-stretch {
        align-items: stretch !important;
    }

    .m1\:u-ac-start {
        align-content: flex-start !important;
    }

    .m1\:u-ac-end {
        align-content: flex-end !important;
    }

    .m1\:u-ac-center {
        align-content: center !important;
    }

    .m1\:u-ac-between {
        align-content: space-between !important;
    }

    .m1\:u-ac-around {
        align-content: space-around !important;
    }

    .m1\:u-ac-stretch {
        align-content: stretch !important;
    }

    .m1\:u-as-auto {
        align-self: auto !important;
    }

    .m1\:u-as-start {
        align-self: flex-start !important;
    }

    .m1\:u-as-end {
        align-self: flex-end !important;
    }

    .m1\:u-as-center {
        align-self: center !important;
    }

    .m1\:u-as-baseline {
        align-self: baseline !important;
    }

    .m1\:u-as-stretch {
        align-self: stretch !important;
    }

    .m1\:order-first {
        order: -1 !important;
    }

    .m1\:order-0 {
        order: 0 !important;
    }

    .m1\:order-1 {
        order: 1 !important;
    }

    .m1\:order-2 {
        order: 2 !important;
    }

    .m1\:order-3 {
        order: 3 !important;
    }

    .m1\:order-4 {
        order: 4 !important;
    }

    .m1\:order-5 {
        order: 5 !important;
    }

    .m1\:order-last {
        order: 6 !important;
    }

    .m1\:u-ta-left {
        text-align: left !important;
    }

    .m1\:u-ta-right {
        text-align: right !important;
    }

    .m1\:u-ta-center {
        text-align: center !important;
    }

    .m1\:m-0 {
        margin: 0 !important;
    }

    .m1\:mt-0,
    .m1\:my-0 {
        margin-top: 0 !important;
    }

    .m1\:mr-0,
    .m1\:mx-0 {
        margin-right: 0 !important;
    }

    .m1\:mb-0,
    .m1\:my-0 {
        margin-bottom: 0 !important;
    }

    .m1\:ml-0,
    .m1\:mx-0 {
        margin-left: 0 !important;
    }

    .m1\:m-4 {
        margin: 0.4rem !important;
    }

    .m1\:mt-4,
    .m1\:my-4 {
        margin-top: 0.4rem !important;
    }

    .m1\:mr-4,
    .m1\:mx-4 {
        margin-right: 0.4rem !important;
    }

    .m1\:mb-4,
    .m1\:my-4 {
        margin-bottom: 0.4rem !important;
    }

    .m1\:ml-4,
    .m1\:mx-4 {
        margin-left: 0.4rem !important;
    }

    .m1\:m-8 {
        margin: 0.8rem !important;
    }

    .m1\:mt-8,
    .m1\:my-8 {
        margin-top: 0.8rem !important;
    }

    .m1\:mr-8,
    .m1\:mx-8 {
        margin-right: 0.8rem !important;
    }

    .m1\:mb-8,
    .m1\:my-8 {
        margin-bottom: 0.8rem !important;
    }

    .m1\:ml-8,
    .m1\:mx-8 {
        margin-left: 0.8rem !important;
    }

    .m1\:p-0 {
        padding: 0 !important;
    }

    .m1\:pt-0,
    .m1\:py-0 {
        padding-top: 0 !important;
    }

    .m1\:pr-0,
    .m1\:px-0 {
        padding-right: 0 !important;
    }

    .m1\:pb-0,
    .m1\:py-0 {
        padding-bottom: 0 !important;
    }

    .m1\:pl-0,
    .m1\:px-0 {
        padding-left: 0 !important;
    }

    .m1\:p-4 {
        padding: 0.4rem !important;
    }

    .m1\:pt-4,
    .m1\:py-4 {
        padding-top: 0.4rem !important;
    }

    .m1\:pr-4,
    .m1\:px-4 {
        padding-right: 0.4rem !important;
    }

    .m1\:pb-4,
    .m1\:py-4 {
        padding-bottom: 0.4rem !important;
    }

    .m1\:pl-4,
    .m1\:px-4 {
        padding-left: 0.4rem !important;
    }

    .m1\:p-8 {
        padding: 0.8rem !important;
    }

    .m1\:pt-8,
    .m1\:py-8 {
        padding-top: 0.8rem !important;
    }

    .m1\:pr-8,
    .m1\:px-8 {
        padding-right: 0.8rem !important;
    }

    .m1\:pb-8,
    .m1\:py-8 {
        padding-bottom: 0.8rem !important;
    }

    .m1\:pl-8,
    .m1\:px-8 {
        padding-left: 0.8rem !important;
    }

    .m1\:m-n4 {
        margin: -0.4rem !important;
    }

    .m1\:mt-n4,
    .m1\:my-n4 {
        margin-top: -0.4rem !important;
    }

    .m1\:mr-n4,
    .m1\:mx-n4 {
        margin-right: -0.4rem !important;
    }

    .m1\:mb-n4,
    .m1\:my-n4 {
        margin-bottom: -0.4rem !important;
    }

    .m1\:ml-n4,
    .m1\:mx-n4 {
        margin-left: -0.4rem !important;
    }

    .m1\:m-n8 {
        margin: -0.8rem !important;
    }

    .m1\:mt-n8,
    .m1\:my-n8 {
        margin-top: -0.8rem !important;
    }

    .m1\:mr-n8,
    .m1\:mx-n8 {
        margin-right: -0.8rem !important;
    }

    .m1\:mb-n8,
    .m1\:my-n8 {
        margin-bottom: -0.8rem !important;
    }

    .m1\:ml-n8,
    .m1\:mx-n8 {
        margin-left: -0.8rem !important;
    }

    .m1\:m-auto {
        margin: auto !important;
    }

    .m1\:mt-auto,
    .m1\:my-auto {
        margin-top: auto !important;
    }

    .m1\:mr-auto,
    .m1\:mx-auto {
        margin-right: auto !important;
    }

    .m1\:mb-auto,
    .m1\:my-auto {
        margin-bottom: auto !important;
    }

    .m1\:ml-auto,
    .m1\:mx-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 414px) {
    .m2\:u-d-none {
        display: none !important;
    }

    .m2\:u-d-inline {
        display: inline !important;
    }

    .m2\:u-d-inline-block {
        display: inline-block !important;
    }

    .m2\:u-d-block {
        display: block !important;
    }

    .m2\:u-d-table {
        display: table !important;
    }

    .m2\:u-d-table-row {
        display: table-row !important;
    }

    .m2\:u-d-table-cell {
        display: table-cell !important;
    }

    .m2\:u-d-flex {
        display: flex !important;
    }

    .m2\:u-d-inline-flex {
        display: inline-flex !important;
    }

    .m2\:u-minw-auto {
        min-width: auto !important;
    }

    .m2\:u-minw-0 {
        min-width: 0 !important;
    }

    .m2\:u-minw-100 {
        min-width: 10rem !important;
    }

    .m2\:u-h-auto {
        height: auto !important;
    }

    .m2\:u-h-0 {
        height: 0 !important;
    }

    .m2\:u-h-100p {
        height: 100% !important;
    }

    .m2\:u-flex-fill {
        flex: 1 1 auto !important;
    }

    .m2\:u-flex-row {
        flex-direction: row !important;
    }

    .m2\:u-flex-column {
        flex-direction: column !important;
    }

    .m2\:u-flex-row-reverse {
        flex-direction: row-reverse !important;
    }

    .m2\:u-flex-column-reverse {
        flex-direction: column-reverse !important;
    }

    .m2\:u-flex-grow-0 {
        flex-grow: 0 !important;
    }

    .m2\:u-flex-grow-1 {
        flex-grow: 1 !important;
    }

    .m2\:u-flex-shrink-0 {
        flex-shrink: 0 !important;
    }

    .m2\:u-flex-shrink-1 {
        flex-shrink: 1 !important;
    }

    .m2\:u-flex-wrap {
        flex-wrap: wrap !important;
    }

    .m2\:u-flex-nowrap {
        flex-wrap: nowrap !important;
    }

    .m2\:u-flex-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .m2\:u-jc-start {
        justify-content: flex-start !important;
    }

    .m2\:u-jc-end {
        justify-content: flex-end !important;
    }

    .m2\:u-jc-center {
        justify-content: center !important;
    }

    .m2\:u-jc-between {
        justify-content: space-between !important;
    }

    .m2\:u-jc-around {
        justify-content: space-around !important;
    }

    .m2\:u-jc-evenly {
        justify-content: space-evenly !important;
    }

    .m2\:u-ai-start {
        align-items: flex-start !important;
    }

    .m2\:u-ai-end {
        align-items: flex-end !important;
    }

    .m2\:u-ai-center {
        align-items: center !important;
    }

    .m2\:u-ai-baseline {
        align-items: baseline !important;
    }

    .m2\:u-ai-stretch {
        align-items: stretch !important;
    }

    .m2\:u-ac-start {
        align-content: flex-start !important;
    }

    .m2\:u-ac-end {
        align-content: flex-end !important;
    }

    .m2\:u-ac-center {
        align-content: center !important;
    }

    .m2\:u-ac-between {
        align-content: space-between !important;
    }

    .m2\:u-ac-around {
        align-content: space-around !important;
    }

    .m2\:u-ac-stretch {
        align-content: stretch !important;
    }

    .m2\:u-as-auto {
        align-self: auto !important;
    }

    .m2\:u-as-start {
        align-self: flex-start !important;
    }

    .m2\:u-as-end {
        align-self: flex-end !important;
    }

    .m2\:u-as-center {
        align-self: center !important;
    }

    .m2\:u-as-baseline {
        align-self: baseline !important;
    }

    .m2\:u-as-stretch {
        align-self: stretch !important;
    }

    .m2\:order-first {
        order: -1 !important;
    }

    .m2\:order-0 {
        order: 0 !important;
    }

    .m2\:order-1 {
        order: 1 !important;
    }

    .m2\:order-2 {
        order: 2 !important;
    }

    .m2\:order-3 {
        order: 3 !important;
    }

    .m2\:order-4 {
        order: 4 !important;
    }

    .m2\:order-5 {
        order: 5 !important;
    }

    .m2\:order-last {
        order: 6 !important;
    }

    .m2\:u-ta-left {
        text-align: left !important;
    }

    .m2\:u-ta-right {
        text-align: right !important;
    }

    .m2\:u-ta-center {
        text-align: center !important;
    }

    .m2\:m-0 {
        margin: 0 !important;
    }

    .m2\:mt-0,
    .m2\:my-0 {
        margin-top: 0 !important;
    }

    .m2\:mr-0,
    .m2\:mx-0 {
        margin-right: 0 !important;
    }

    .m2\:mb-0,
    .m2\:my-0 {
        margin-bottom: 0 !important;
    }

    .m2\:ml-0,
    .m2\:mx-0 {
        margin-left: 0 !important;
    }

    .m2\:m-4 {
        margin: 0.4rem !important;
    }

    .m2\:mt-4,
    .m2\:my-4 {
        margin-top: 0.4rem !important;
    }

    .m2\:mr-4,
    .m2\:mx-4 {
        margin-right: 0.4rem !important;
    }

    .m2\:mb-4,
    .m2\:my-4 {
        margin-bottom: 0.4rem !important;
    }

    .m2\:ml-4,
    .m2\:mx-4 {
        margin-left: 0.4rem !important;
    }

    .m2\:m-8 {
        margin: 0.8rem !important;
    }

    .m2\:mt-8,
    .m2\:my-8 {
        margin-top: 0.8rem !important;
    }

    .m2\:mr-8,
    .m2\:mx-8 {
        margin-right: 0.8rem !important;
    }

    .m2\:mb-8,
    .m2\:my-8 {
        margin-bottom: 0.8rem !important;
    }

    .m2\:ml-8,
    .m2\:mx-8 {
        margin-left: 0.8rem !important;
    }

    .m2\:p-0 {
        padding: 0 !important;
    }

    .m2\:pt-0,
    .m2\:py-0 {
        padding-top: 0 !important;
    }

    .m2\:pr-0,
    .m2\:px-0 {
        padding-right: 0 !important;
    }

    .m2\:pb-0,
    .m2\:py-0 {
        padding-bottom: 0 !important;
    }

    .m2\:pl-0,
    .m2\:px-0 {
        padding-left: 0 !important;
    }

    .m2\:p-4 {
        padding: 0.4rem !important;
    }

    .m2\:pt-4,
    .m2\:py-4 {
        padding-top: 0.4rem !important;
    }

    .m2\:pr-4,
    .m2\:px-4 {
        padding-right: 0.4rem !important;
    }

    .m2\:pb-4,
    .m2\:py-4 {
        padding-bottom: 0.4rem !important;
    }

    .m2\:pl-4,
    .m2\:px-4 {
        padding-left: 0.4rem !important;
    }

    .m2\:p-8 {
        padding: 0.8rem !important;
    }

    .m2\:pt-8,
    .m2\:py-8 {
        padding-top: 0.8rem !important;
    }

    .m2\:pr-8,
    .m2\:px-8 {
        padding-right: 0.8rem !important;
    }

    .m2\:pb-8,
    .m2\:py-8 {
        padding-bottom: 0.8rem !important;
    }

    .m2\:pl-8,
    .m2\:px-8 {
        padding-left: 0.8rem !important;
    }

    .m2\:m-n4 {
        margin: -0.4rem !important;
    }

    .m2\:mt-n4,
    .m2\:my-n4 {
        margin-top: -0.4rem !important;
    }

    .m2\:mr-n4,
    .m2\:mx-n4 {
        margin-right: -0.4rem !important;
    }

    .m2\:mb-n4,
    .m2\:my-n4 {
        margin-bottom: -0.4rem !important;
    }

    .m2\:ml-n4,
    .m2\:mx-n4 {
        margin-left: -0.4rem !important;
    }

    .m2\:m-n8 {
        margin: -0.8rem !important;
    }

    .m2\:mt-n8,
    .m2\:my-n8 {
        margin-top: -0.8rem !important;
    }

    .m2\:mr-n8,
    .m2\:mx-n8 {
        margin-right: -0.8rem !important;
    }

    .m2\:mb-n8,
    .m2\:my-n8 {
        margin-bottom: -0.8rem !important;
    }

    .m2\:ml-n8,
    .m2\:mx-n8 {
        margin-left: -0.8rem !important;
    }

    .m2\:m-auto {
        margin: auto !important;
    }

    .m2\:mt-auto,
    .m2\:my-auto {
        margin-top: auto !important;
    }

    .m2\:mr-auto,
    .m2\:mx-auto {
        margin-right: auto !important;
    }

    .m2\:mb-auto,
    .m2\:my-auto {
        margin-bottom: auto !important;
    }

    .m2\:ml-auto,
    .m2\:mx-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 480px) {
    .m3\:u-d-none {
        display: none !important;
    }

    .m3\:u-d-inline {
        display: inline !important;
    }

    .m3\:u-d-inline-block {
        display: inline-block !important;
    }

    .m3\:u-d-block {
        display: block !important;
    }

    .m3\:u-d-table {
        display: table !important;
    }

    .m3\:u-d-table-row {
        display: table-row !important;
    }

    .m3\:u-d-table-cell {
        display: table-cell !important;
    }

    .m3\:u-d-flex {
        display: flex !important;
    }

    .m3\:u-d-inline-flex {
        display: inline-flex !important;
    }

    .m3\:u-minw-auto {
        min-width: auto !important;
    }

    .m3\:u-minw-0 {
        min-width: 0 !important;
    }

    .m3\:u-minw-100 {
        min-width: 10rem !important;
    }

    .m3\:u-h-auto {
        height: auto !important;
    }

    .m3\:u-h-0 {
        height: 0 !important;
    }

    .m3\:u-h-100p {
        height: 100% !important;
    }

    .m3\:u-flex-fill {
        flex: 1 1 auto !important;
    }

    .m3\:u-flex-row {
        flex-direction: row !important;
    }

    .m3\:u-flex-column {
        flex-direction: column !important;
    }

    .m3\:u-flex-row-reverse {
        flex-direction: row-reverse !important;
    }

    .m3\:u-flex-column-reverse {
        flex-direction: column-reverse !important;
    }

    .m3\:u-flex-grow-0 {
        flex-grow: 0 !important;
    }

    .m3\:u-flex-grow-1 {
        flex-grow: 1 !important;
    }

    .m3\:u-flex-shrink-0 {
        flex-shrink: 0 !important;
    }

    .m3\:u-flex-shrink-1 {
        flex-shrink: 1 !important;
    }

    .m3\:u-flex-wrap {
        flex-wrap: wrap !important;
    }

    .m3\:u-flex-nowrap {
        flex-wrap: nowrap !important;
    }

    .m3\:u-flex-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .m3\:u-jc-start {
        justify-content: flex-start !important;
    }

    .m3\:u-jc-end {
        justify-content: flex-end !important;
    }

    .m3\:u-jc-center {
        justify-content: center !important;
    }

    .m3\:u-jc-between {
        justify-content: space-between !important;
    }

    .m3\:u-jc-around {
        justify-content: space-around !important;
    }

    .m3\:u-jc-evenly {
        justify-content: space-evenly !important;
    }

    .m3\:u-ai-start {
        align-items: flex-start !important;
    }

    .m3\:u-ai-end {
        align-items: flex-end !important;
    }

    .m3\:u-ai-center {
        align-items: center !important;
    }

    .m3\:u-ai-baseline {
        align-items: baseline !important;
    }

    .m3\:u-ai-stretch {
        align-items: stretch !important;
    }

    .m3\:u-ac-start {
        align-content: flex-start !important;
    }

    .m3\:u-ac-end {
        align-content: flex-end !important;
    }

    .m3\:u-ac-center {
        align-content: center !important;
    }

    .m3\:u-ac-between {
        align-content: space-between !important;
    }

    .m3\:u-ac-around {
        align-content: space-around !important;
    }

    .m3\:u-ac-stretch {
        align-content: stretch !important;
    }

    .m3\:u-as-auto {
        align-self: auto !important;
    }

    .m3\:u-as-start {
        align-self: flex-start !important;
    }

    .m3\:u-as-end {
        align-self: flex-end !important;
    }

    .m3\:u-as-center {
        align-self: center !important;
    }

    .m3\:u-as-baseline {
        align-self: baseline !important;
    }

    .m3\:u-as-stretch {
        align-self: stretch !important;
    }

    .m3\:order-first {
        order: -1 !important;
    }

    .m3\:order-0 {
        order: 0 !important;
    }

    .m3\:order-1 {
        order: 1 !important;
    }

    .m3\:order-2 {
        order: 2 !important;
    }

    .m3\:order-3 {
        order: 3 !important;
    }

    .m3\:order-4 {
        order: 4 !important;
    }

    .m3\:order-5 {
        order: 5 !important;
    }

    .m3\:order-last {
        order: 6 !important;
    }

    .m3\:u-ta-left {
        text-align: left !important;
    }

    .m3\:u-ta-right {
        text-align: right !important;
    }

    .m3\:u-ta-center {
        text-align: center !important;
    }

    .m3\:m-0 {
        margin: 0 !important;
    }

    .m3\:mt-0,
    .m3\:my-0 {
        margin-top: 0 !important;
    }

    .m3\:mr-0,
    .m3\:mx-0 {
        margin-right: 0 !important;
    }

    .m3\:mb-0,
    .m3\:my-0 {
        margin-bottom: 0 !important;
    }

    .m3\:ml-0,
    .m3\:mx-0 {
        margin-left: 0 !important;
    }

    .m3\:m-4 {
        margin: 0.4rem !important;
    }

    .m3\:mt-4,
    .m3\:my-4 {
        margin-top: 0.4rem !important;
    }

    .m3\:mr-4,
    .m3\:mx-4 {
        margin-right: 0.4rem !important;
    }

    .m3\:mb-4,
    .m3\:my-4 {
        margin-bottom: 0.4rem !important;
    }

    .m3\:ml-4,
    .m3\:mx-4 {
        margin-left: 0.4rem !important;
    }

    .m3\:m-8 {
        margin: 0.8rem !important;
    }

    .m3\:mt-8,
    .m3\:my-8 {
        margin-top: 0.8rem !important;
    }

    .m3\:mr-8,
    .m3\:mx-8 {
        margin-right: 0.8rem !important;
    }

    .m3\:mb-8,
    .m3\:my-8 {
        margin-bottom: 0.8rem !important;
    }

    .m3\:ml-8,
    .m3\:mx-8 {
        margin-left: 0.8rem !important;
    }

    .m3\:p-0 {
        padding: 0 !important;
    }

    .m3\:pt-0,
    .m3\:py-0 {
        padding-top: 0 !important;
    }

    .m3\:pr-0,
    .m3\:px-0 {
        padding-right: 0 !important;
    }

    .m3\:pb-0,
    .m3\:py-0 {
        padding-bottom: 0 !important;
    }

    .m3\:pl-0,
    .m3\:px-0 {
        padding-left: 0 !important;
    }

    .m3\:p-4 {
        padding: 0.4rem !important;
    }

    .m3\:pt-4,
    .m3\:py-4 {
        padding-top: 0.4rem !important;
    }

    .m3\:pr-4,
    .m3\:px-4 {
        padding-right: 0.4rem !important;
    }

    .m3\:pb-4,
    .m3\:py-4 {
        padding-bottom: 0.4rem !important;
    }

    .m3\:pl-4,
    .m3\:px-4 {
        padding-left: 0.4rem !important;
    }

    .m3\:p-8 {
        padding: 0.8rem !important;
    }

    .m3\:pt-8,
    .m3\:py-8 {
        padding-top: 0.8rem !important;
    }

    .m3\:pr-8,
    .m3\:px-8 {
        padding-right: 0.8rem !important;
    }

    .m3\:pb-8,
    .m3\:py-8 {
        padding-bottom: 0.8rem !important;
    }

    .m3\:pl-8,
    .m3\:px-8 {
        padding-left: 0.8rem !important;
    }

    .m3\:m-n4 {
        margin: -0.4rem !important;
    }

    .m3\:mt-n4,
    .m3\:my-n4 {
        margin-top: -0.4rem !important;
    }

    .m3\:mr-n4,
    .m3\:mx-n4 {
        margin-right: -0.4rem !important;
    }

    .m3\:mb-n4,
    .m3\:my-n4 {
        margin-bottom: -0.4rem !important;
    }

    .m3\:ml-n4,
    .m3\:mx-n4 {
        margin-left: -0.4rem !important;
    }

    .m3\:m-n8 {
        margin: -0.8rem !important;
    }

    .m3\:mt-n8,
    .m3\:my-n8 {
        margin-top: -0.8rem !important;
    }

    .m3\:mr-n8,
    .m3\:mx-n8 {
        margin-right: -0.8rem !important;
    }

    .m3\:mb-n8,
    .m3\:my-n8 {
        margin-bottom: -0.8rem !important;
    }

    .m3\:ml-n8,
    .m3\:mx-n8 {
        margin-left: -0.8rem !important;
    }

    .m3\:m-auto {
        margin: auto !important;
    }

    .m3\:mt-auto,
    .m3\:my-auto {
        margin-top: auto !important;
    }

    .m3\:mr-auto,
    .m3\:mx-auto {
        margin-right: auto !important;
    }

    .m3\:mb-auto,
    .m3\:my-auto {
        margin-bottom: auto !important;
    }

    .m3\:ml-auto,
    .m3\:mx-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 576px) {
    .m4\:u-d-none {
        display: none !important;
    }

    .m4\:u-d-inline {
        display: inline !important;
    }

    .m4\:u-d-inline-block {
        display: inline-block !important;
    }

    .m4\:u-d-block {
        display: block !important;
    }

    .m4\:u-d-table {
        display: table !important;
    }

    .m4\:u-d-table-row {
        display: table-row !important;
    }

    .m4\:u-d-table-cell {
        display: table-cell !important;
    }

    .m4\:u-d-flex {
        display: flex !important;
    }

    .m4\:u-d-inline-flex {
        display: inline-flex !important;
    }

    .m4\:u-minw-auto {
        min-width: auto !important;
    }

    .m4\:u-minw-0 {
        min-width: 0 !important;
    }

    .m4\:u-minw-100 {
        min-width: 10rem !important;
    }

    .m4\:u-h-auto {
        height: auto !important;
    }

    .m4\:u-h-0 {
        height: 0 !important;
    }

    .m4\:u-h-100p {
        height: 100% !important;
    }

    .m4\:u-flex-fill {
        flex: 1 1 auto !important;
    }

    .m4\:u-flex-row {
        flex-direction: row !important;
    }

    .m4\:u-flex-column {
        flex-direction: column !important;
    }

    .m4\:u-flex-row-reverse {
        flex-direction: row-reverse !important;
    }

    .m4\:u-flex-column-reverse {
        flex-direction: column-reverse !important;
    }

    .m4\:u-flex-grow-0 {
        flex-grow: 0 !important;
    }

    .m4\:u-flex-grow-1 {
        flex-grow: 1 !important;
    }

    .m4\:u-flex-shrink-0 {
        flex-shrink: 0 !important;
    }

    .m4\:u-flex-shrink-1 {
        flex-shrink: 1 !important;
    }

    .m4\:u-flex-wrap {
        flex-wrap: wrap !important;
    }

    .m4\:u-flex-nowrap {
        flex-wrap: nowrap !important;
    }

    .m4\:u-flex-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .m4\:u-jc-start {
        justify-content: flex-start !important;
    }

    .m4\:u-jc-end {
        justify-content: flex-end !important;
    }

    .m4\:u-jc-center {
        justify-content: center !important;
    }

    .m4\:u-jc-between {
        justify-content: space-between !important;
    }

    .m4\:u-jc-around {
        justify-content: space-around !important;
    }

    .m4\:u-jc-evenly {
        justify-content: space-evenly !important;
    }

    .m4\:u-ai-start {
        align-items: flex-start !important;
    }

    .m4\:u-ai-end {
        align-items: flex-end !important;
    }

    .m4\:u-ai-center {
        align-items: center !important;
    }

    .m4\:u-ai-baseline {
        align-items: baseline !important;
    }

    .m4\:u-ai-stretch {
        align-items: stretch !important;
    }

    .m4\:u-ac-start {
        align-content: flex-start !important;
    }

    .m4\:u-ac-end {
        align-content: flex-end !important;
    }

    .m4\:u-ac-center {
        align-content: center !important;
    }

    .m4\:u-ac-between {
        align-content: space-between !important;
    }

    .m4\:u-ac-around {
        align-content: space-around !important;
    }

    .m4\:u-ac-stretch {
        align-content: stretch !important;
    }

    .m4\:u-as-auto {
        align-self: auto !important;
    }

    .m4\:u-as-start {
        align-self: flex-start !important;
    }

    .m4\:u-as-end {
        align-self: flex-end !important;
    }

    .m4\:u-as-center {
        align-self: center !important;
    }

    .m4\:u-as-baseline {
        align-self: baseline !important;
    }

    .m4\:u-as-stretch {
        align-self: stretch !important;
    }

    .m4\:order-first {
        order: -1 !important;
    }

    .m4\:order-0 {
        order: 0 !important;
    }

    .m4\:order-1 {
        order: 1 !important;
    }

    .m4\:order-2 {
        order: 2 !important;
    }

    .m4\:order-3 {
        order: 3 !important;
    }

    .m4\:order-4 {
        order: 4 !important;
    }

    .m4\:order-5 {
        order: 5 !important;
    }

    .m4\:order-last {
        order: 6 !important;
    }

    .m4\:u-ta-left {
        text-align: left !important;
    }

    .m4\:u-ta-right {
        text-align: right !important;
    }

    .m4\:u-ta-center {
        text-align: center !important;
    }

    .m4\:m-0 {
        margin: 0 !important;
    }

    .m4\:mt-0,
    .m4\:my-0 {
        margin-top: 0 !important;
    }

    .m4\:mr-0,
    .m4\:mx-0 {
        margin-right: 0 !important;
    }

    .m4\:mb-0,
    .m4\:my-0 {
        margin-bottom: 0 !important;
    }

    .m4\:ml-0,
    .m4\:mx-0 {
        margin-left: 0 !important;
    }

    .m4\:m-4 {
        margin: 0.4rem !important;
    }

    .m4\:mt-4,
    .m4\:my-4 {
        margin-top: 0.4rem !important;
    }

    .m4\:mr-4,
    .m4\:mx-4 {
        margin-right: 0.4rem !important;
    }

    .m4\:mb-4,
    .m4\:my-4 {
        margin-bottom: 0.4rem !important;
    }

    .m4\:ml-4,
    .m4\:mx-4 {
        margin-left: 0.4rem !important;
    }

    .m4\:m-8 {
        margin: 0.8rem !important;
    }

    .m4\:mt-8,
    .m4\:my-8 {
        margin-top: 0.8rem !important;
    }

    .m4\:mr-8,
    .m4\:mx-8 {
        margin-right: 0.8rem !important;
    }

    .m4\:mb-8,
    .m4\:my-8 {
        margin-bottom: 0.8rem !important;
    }

    .m4\:ml-8,
    .m4\:mx-8 {
        margin-left: 0.8rem !important;
    }

    .m4\:p-0 {
        padding: 0 !important;
    }

    .m4\:pt-0,
    .m4\:py-0 {
        padding-top: 0 !important;
    }

    .m4\:pr-0,
    .m4\:px-0 {
        padding-right: 0 !important;
    }

    .m4\:pb-0,
    .m4\:py-0 {
        padding-bottom: 0 !important;
    }

    .m4\:pl-0,
    .m4\:px-0 {
        padding-left: 0 !important;
    }

    .m4\:p-4 {
        padding: 0.4rem !important;
    }

    .m4\:pt-4,
    .m4\:py-4 {
        padding-top: 0.4rem !important;
    }

    .m4\:pr-4,
    .m4\:px-4 {
        padding-right: 0.4rem !important;
    }

    .m4\:pb-4,
    .m4\:py-4 {
        padding-bottom: 0.4rem !important;
    }

    .m4\:pl-4,
    .m4\:px-4 {
        padding-left: 0.4rem !important;
    }

    .m4\:p-8 {
        padding: 0.8rem !important;
    }

    .m4\:pt-8,
    .m4\:py-8 {
        padding-top: 0.8rem !important;
    }

    .m4\:pr-8,
    .m4\:px-8 {
        padding-right: 0.8rem !important;
    }

    .m4\:pb-8,
    .m4\:py-8 {
        padding-bottom: 0.8rem !important;
    }

    .m4\:pl-8,
    .m4\:px-8 {
        padding-left: 0.8rem !important;
    }

    .m4\:m-n4 {
        margin: -0.4rem !important;
    }

    .m4\:mt-n4,
    .m4\:my-n4 {
        margin-top: -0.4rem !important;
    }

    .m4\:mr-n4,
    .m4\:mx-n4 {
        margin-right: -0.4rem !important;
    }

    .m4\:mb-n4,
    .m4\:my-n4 {
        margin-bottom: -0.4rem !important;
    }

    .m4\:ml-n4,
    .m4\:mx-n4 {
        margin-left: -0.4rem !important;
    }

    .m4\:m-n8 {
        margin: -0.8rem !important;
    }

    .m4\:mt-n8,
    .m4\:my-n8 {
        margin-top: -0.8rem !important;
    }

    .m4\:mr-n8,
    .m4\:mx-n8 {
        margin-right: -0.8rem !important;
    }

    .m4\:mb-n8,
    .m4\:my-n8 {
        margin-bottom: -0.8rem !important;
    }

    .m4\:ml-n8,
    .m4\:mx-n8 {
        margin-left: -0.8rem !important;
    }

    .m4\:m-auto {
        margin: auto !important;
    }

    .m4\:mt-auto,
    .m4\:my-auto {
        margin-top: auto !important;
    }

    .m4\:mr-auto,
    .m4\:mx-auto {
        margin-right: auto !important;
    }

    .m4\:mb-auto,
    .m4\:my-auto {
        margin-bottom: auto !important;
    }

    .m4\:ml-auto,
    .m4\:mx-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 640px) {
    .t1\:u-d-none {
        display: none !important;
    }

    .t1\:u-d-inline {
        display: inline !important;
    }

    .t1\:u-d-inline-block {
        display: inline-block !important;
    }

    .t1\:u-d-block {
        display: block !important;
    }

    .t1\:u-d-table {
        display: table !important;
    }

    .t1\:u-d-table-row {
        display: table-row !important;
    }

    .t1\:u-d-table-cell {
        display: table-cell !important;
    }

    .t1\:u-d-flex {
        display: flex !important;
    }

    .t1\:u-d-inline-flex {
        display: inline-flex !important;
    }

    .t1\:u-minw-auto {
        min-width: auto !important;
    }

    .t1\:u-minw-0 {
        min-width: 0 !important;
    }

    .t1\:u-minw-100 {
        min-width: 10rem !important;
    }

    .t1\:u-h-auto {
        height: auto !important;
    }

    .t1\:u-h-0 {
        height: 0 !important;
    }

    .t1\:u-h-100p {
        height: 100% !important;
    }

    .t1\:u-flex-fill {
        flex: 1 1 auto !important;
    }

    .t1\:u-flex-row {
        flex-direction: row !important;
    }

    .t1\:u-flex-column {
        flex-direction: column !important;
    }

    .t1\:u-flex-row-reverse {
        flex-direction: row-reverse !important;
    }

    .t1\:u-flex-column-reverse {
        flex-direction: column-reverse !important;
    }

    .t1\:u-flex-grow-0 {
        flex-grow: 0 !important;
    }

    .t1\:u-flex-grow-1 {
        flex-grow: 1 !important;
    }

    .t1\:u-flex-shrink-0 {
        flex-shrink: 0 !important;
    }

    .t1\:u-flex-shrink-1 {
        flex-shrink: 1 !important;
    }

    .t1\:u-flex-wrap {
        flex-wrap: wrap !important;
    }

    .t1\:u-flex-nowrap {
        flex-wrap: nowrap !important;
    }

    .t1\:u-flex-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .t1\:u-jc-start {
        justify-content: flex-start !important;
    }

    .t1\:u-jc-end {
        justify-content: flex-end !important;
    }

    .t1\:u-jc-center {
        justify-content: center !important;
    }

    .t1\:u-jc-between {
        justify-content: space-between !important;
    }

    .t1\:u-jc-around {
        justify-content: space-around !important;
    }

    .t1\:u-jc-evenly {
        justify-content: space-evenly !important;
    }

    .t1\:u-ai-start {
        align-items: flex-start !important;
    }

    .t1\:u-ai-end {
        align-items: flex-end !important;
    }

    .t1\:u-ai-center {
        align-items: center !important;
    }

    .t1\:u-ai-baseline {
        align-items: baseline !important;
    }

    .t1\:u-ai-stretch {
        align-items: stretch !important;
    }

    .t1\:u-ac-start {
        align-content: flex-start !important;
    }

    .t1\:u-ac-end {
        align-content: flex-end !important;
    }

    .t1\:u-ac-center {
        align-content: center !important;
    }

    .t1\:u-ac-between {
        align-content: space-between !important;
    }

    .t1\:u-ac-around {
        align-content: space-around !important;
    }

    .t1\:u-ac-stretch {
        align-content: stretch !important;
    }

    .t1\:u-as-auto {
        align-self: auto !important;
    }

    .t1\:u-as-start {
        align-self: flex-start !important;
    }

    .t1\:u-as-end {
        align-self: flex-end !important;
    }

    .t1\:u-as-center {
        align-self: center !important;
    }

    .t1\:u-as-baseline {
        align-self: baseline !important;
    }

    .t1\:u-as-stretch {
        align-self: stretch !important;
    }

    .t1\:order-first {
        order: -1 !important;
    }

    .t1\:order-0 {
        order: 0 !important;
    }

    .t1\:order-1 {
        order: 1 !important;
    }

    .t1\:order-2 {
        order: 2 !important;
    }

    .t1\:order-3 {
        order: 3 !important;
    }

    .t1\:order-4 {
        order: 4 !important;
    }

    .t1\:order-5 {
        order: 5 !important;
    }

    .t1\:order-last {
        order: 6 !important;
    }

    .t1\:u-ta-left {
        text-align: left !important;
    }

    .t1\:u-ta-right {
        text-align: right !important;
    }

    .t1\:u-ta-center {
        text-align: center !important;
    }

    .t1\:m-0 {
        margin: 0 !important;
    }

    .t1\:mt-0,
    .t1\:my-0 {
        margin-top: 0 !important;
    }

    .t1\:mr-0,
    .t1\:mx-0 {
        margin-right: 0 !important;
    }

    .t1\:mb-0,
    .t1\:my-0 {
        margin-bottom: 0 !important;
    }

    .t1\:ml-0,
    .t1\:mx-0 {
        margin-left: 0 !important;
    }

    .t1\:m-4 {
        margin: 0.4rem !important;
    }

    .t1\:mt-4,
    .t1\:my-4 {
        margin-top: 0.4rem !important;
    }

    .t1\:mr-4,
    .t1\:mx-4 {
        margin-right: 0.4rem !important;
    }

    .t1\:mb-4,
    .t1\:my-4 {
        margin-bottom: 0.4rem !important;
    }

    .t1\:ml-4,
    .t1\:mx-4 {
        margin-left: 0.4rem !important;
    }

    .t1\:m-8 {
        margin: 0.8rem !important;
    }

    .t1\:mt-8,
    .t1\:my-8 {
        margin-top: 0.8rem !important;
    }

    .t1\:mr-8,
    .t1\:mx-8 {
        margin-right: 0.8rem !important;
    }

    .t1\:mb-8,
    .t1\:my-8 {
        margin-bottom: 0.8rem !important;
    }

    .t1\:ml-8,
    .t1\:mx-8 {
        margin-left: 0.8rem !important;
    }

    .t1\:p-0 {
        padding: 0 !important;
    }

    .t1\:pt-0,
    .t1\:py-0 {
        padding-top: 0 !important;
    }

    .t1\:pr-0,
    .t1\:px-0 {
        padding-right: 0 !important;
    }

    .t1\:pb-0,
    .t1\:py-0 {
        padding-bottom: 0 !important;
    }

    .t1\:pl-0,
    .t1\:px-0 {
        padding-left: 0 !important;
    }

    .t1\:p-4 {
        padding: 0.4rem !important;
    }

    .t1\:pt-4,
    .t1\:py-4 {
        padding-top: 0.4rem !important;
    }

    .t1\:pr-4,
    .t1\:px-4 {
        padding-right: 0.4rem !important;
    }

    .t1\:pb-4,
    .t1\:py-4 {
        padding-bottom: 0.4rem !important;
    }

    .t1\:pl-4,
    .t1\:px-4 {
        padding-left: 0.4rem !important;
    }

    .t1\:p-8 {
        padding: 0.8rem !important;
    }

    .t1\:pt-8,
    .t1\:py-8 {
        padding-top: 0.8rem !important;
    }

    .t1\:pr-8,
    .t1\:px-8 {
        padding-right: 0.8rem !important;
    }

    .t1\:pb-8,
    .t1\:py-8 {
        padding-bottom: 0.8rem !important;
    }

    .t1\:pl-8,
    .t1\:px-8 {
        padding-left: 0.8rem !important;
    }

    .t1\:m-n4 {
        margin: -0.4rem !important;
    }

    .t1\:mt-n4,
    .t1\:my-n4 {
        margin-top: -0.4rem !important;
    }

    .t1\:mr-n4,
    .t1\:mx-n4 {
        margin-right: -0.4rem !important;
    }

    .t1\:mb-n4,
    .t1\:my-n4 {
        margin-bottom: -0.4rem !important;
    }

    .t1\:ml-n4,
    .t1\:mx-n4 {
        margin-left: -0.4rem !important;
    }

    .t1\:m-n8 {
        margin: -0.8rem !important;
    }

    .t1\:mt-n8,
    .t1\:my-n8 {
        margin-top: -0.8rem !important;
    }

    .t1\:mr-n8,
    .t1\:mx-n8 {
        margin-right: -0.8rem !important;
    }

    .t1\:mb-n8,
    .t1\:my-n8 {
        margin-bottom: -0.8rem !important;
    }

    .t1\:ml-n8,
    .t1\:mx-n8 {
        margin-left: -0.8rem !important;
    }

    .t1\:m-auto {
        margin: auto !important;
    }

    .t1\:mt-auto,
    .t1\:my-auto {
        margin-top: auto !important;
    }

    .t1\:mr-auto,
    .t1\:mx-auto {
        margin-right: auto !important;
    }

    .t1\:mb-auto,
    .t1\:my-auto {
        margin-bottom: auto !important;
    }

    .t1\:ml-auto,
    .t1\:mx-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 768px) {
    .t2\:u-d-none {
        display: none !important;
    }

    .t2\:u-d-inline {
        display: inline !important;
    }

    .t2\:u-d-inline-block {
        display: inline-block !important;
    }

    .t2\:u-d-block {
        display: block !important;
    }

    .t2\:u-d-table {
        display: table !important;
    }

    .t2\:u-d-table-row {
        display: table-row !important;
    }

    .t2\:u-d-table-cell {
        display: table-cell !important;
    }

    .t2\:u-d-flex {
        display: flex !important;
    }

    .t2\:u-d-inline-flex {
        display: inline-flex !important;
    }

    .t2\:u-minw-auto {
        min-width: auto !important;
    }

    .t2\:u-minw-0 {
        min-width: 0 !important;
    }

    .t2\:u-minw-100 {
        min-width: 10rem !important;
    }

    .t2\:u-h-auto {
        height: auto !important;
    }

    .t2\:u-h-0 {
        height: 0 !important;
    }

    .t2\:u-h-100p {
        height: 100% !important;
    }

    .t2\:u-flex-fill {
        flex: 1 1 auto !important;
    }

    .t2\:u-flex-row {
        flex-direction: row !important;
    }

    .t2\:u-flex-column {
        flex-direction: column !important;
    }

    .t2\:u-flex-row-reverse {
        flex-direction: row-reverse !important;
    }

    .t2\:u-flex-column-reverse {
        flex-direction: column-reverse !important;
    }

    .t2\:u-flex-grow-0 {
        flex-grow: 0 !important;
    }

    .t2\:u-flex-grow-1 {
        flex-grow: 1 !important;
    }

    .t2\:u-flex-shrink-0 {
        flex-shrink: 0 !important;
    }

    .t2\:u-flex-shrink-1 {
        flex-shrink: 1 !important;
    }

    .t2\:u-flex-wrap {
        flex-wrap: wrap !important;
    }

    .t2\:u-flex-nowrap {
        flex-wrap: nowrap !important;
    }

    .t2\:u-flex-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .t2\:u-jc-start {
        justify-content: flex-start !important;
    }

    .t2\:u-jc-end {
        justify-content: flex-end !important;
    }

    .t2\:u-jc-center {
        justify-content: center !important;
    }

    .t2\:u-jc-between {
        justify-content: space-between !important;
    }

    .t2\:u-jc-around {
        justify-content: space-around !important;
    }

    .t2\:u-jc-evenly {
        justify-content: space-evenly !important;
    }

    .t2\:u-ai-start {
        align-items: flex-start !important;
    }

    .t2\:u-ai-end {
        align-items: flex-end !important;
    }

    .t2\:u-ai-center {
        align-items: center !important;
    }

    .t2\:u-ai-baseline {
        align-items: baseline !important;
    }

    .t2\:u-ai-stretch {
        align-items: stretch !important;
    }

    .t2\:u-ac-start {
        align-content: flex-start !important;
    }

    .t2\:u-ac-end {
        align-content: flex-end !important;
    }

    .t2\:u-ac-center {
        align-content: center !important;
    }

    .t2\:u-ac-between {
        align-content: space-between !important;
    }

    .t2\:u-ac-around {
        align-content: space-around !important;
    }

    .t2\:u-ac-stretch {
        align-content: stretch !important;
    }

    .t2\:u-as-auto {
        align-self: auto !important;
    }

    .t2\:u-as-start {
        align-self: flex-start !important;
    }

    .t2\:u-as-end {
        align-self: flex-end !important;
    }

    .t2\:u-as-center {
        align-self: center !important;
    }

    .t2\:u-as-baseline {
        align-self: baseline !important;
    }

    .t2\:u-as-stretch {
        align-self: stretch !important;
    }

    .t2\:order-first {
        order: -1 !important;
    }

    .t2\:order-0 {
        order: 0 !important;
    }

    .t2\:order-1 {
        order: 1 !important;
    }

    .t2\:order-2 {
        order: 2 !important;
    }

    .t2\:order-3 {
        order: 3 !important;
    }

    .t2\:order-4 {
        order: 4 !important;
    }

    .t2\:order-5 {
        order: 5 !important;
    }

    .t2\:order-last {
        order: 6 !important;
    }

    .t2\:u-ta-left {
        text-align: left !important;
    }

    .t2\:u-ta-right {
        text-align: right !important;
    }

    .t2\:u-ta-center {
        text-align: center !important;
    }

    .t2\:m-0 {
        margin: 0 !important;
    }

    .t2\:mt-0,
    .t2\:my-0 {
        margin-top: 0 !important;
    }

    .t2\:mr-0,
    .t2\:mx-0 {
        margin-right: 0 !important;
    }

    .t2\:mb-0,
    .t2\:my-0 {
        margin-bottom: 0 !important;
    }

    .t2\:ml-0,
    .t2\:mx-0 {
        margin-left: 0 !important;
    }

    .t2\:m-4 {
        margin: 0.4rem !important;
    }

    .t2\:mt-4,
    .t2\:my-4 {
        margin-top: 0.4rem !important;
    }

    .t2\:mr-4,
    .t2\:mx-4 {
        margin-right: 0.4rem !important;
    }

    .t2\:mb-4,
    .t2\:my-4 {
        margin-bottom: 0.4rem !important;
    }

    .t2\:ml-4,
    .t2\:mx-4 {
        margin-left: 0.4rem !important;
    }

    .t2\:m-8 {
        margin: 0.8rem !important;
    }

    .t2\:mt-8,
    .t2\:my-8 {
        margin-top: 0.8rem !important;
    }

    .t2\:mr-8,
    .t2\:mx-8 {
        margin-right: 0.8rem !important;
    }

    .t2\:mb-8,
    .t2\:my-8 {
        margin-bottom: 0.8rem !important;
    }

    .t2\:ml-8,
    .t2\:mx-8 {
        margin-left: 0.8rem !important;
    }

    .t2\:p-0 {
        padding: 0 !important;
    }

    .t2\:pt-0,
    .t2\:py-0 {
        padding-top: 0 !important;
    }

    .t2\:pr-0,
    .t2\:px-0 {
        padding-right: 0 !important;
    }

    .t2\:pb-0,
    .t2\:py-0 {
        padding-bottom: 0 !important;
    }

    .t2\:pl-0,
    .t2\:px-0 {
        padding-left: 0 !important;
    }

    .t2\:p-4 {
        padding: 0.4rem !important;
    }

    .t2\:pt-4,
    .t2\:py-4 {
        padding-top: 0.4rem !important;
    }

    .t2\:pr-4,
    .t2\:px-4 {
        padding-right: 0.4rem !important;
    }

    .t2\:pb-4,
    .t2\:py-4 {
        padding-bottom: 0.4rem !important;
    }

    .t2\:pl-4,
    .t2\:px-4 {
        padding-left: 0.4rem !important;
    }

    .t2\:p-8 {
        padding: 0.8rem !important;
    }

    .t2\:pt-8,
    .t2\:py-8 {
        padding-top: 0.8rem !important;
    }

    .t2\:pr-8,
    .t2\:px-8 {
        padding-right: 0.8rem !important;
    }

    .t2\:pb-8,
    .t2\:py-8 {
        padding-bottom: 0.8rem !important;
    }

    .t2\:pl-8,
    .t2\:px-8 {
        padding-left: 0.8rem !important;
    }

    .t2\:m-n4 {
        margin: -0.4rem !important;
    }

    .t2\:mt-n4,
    .t2\:my-n4 {
        margin-top: -0.4rem !important;
    }

    .t2\:mr-n4,
    .t2\:mx-n4 {
        margin-right: -0.4rem !important;
    }

    .t2\:mb-n4,
    .t2\:my-n4 {
        margin-bottom: -0.4rem !important;
    }

    .t2\:ml-n4,
    .t2\:mx-n4 {
        margin-left: -0.4rem !important;
    }

    .t2\:m-n8 {
        margin: -0.8rem !important;
    }

    .t2\:mt-n8,
    .t2\:my-n8 {
        margin-top: -0.8rem !important;
    }

    .t2\:mr-n8,
    .t2\:mx-n8 {
        margin-right: -0.8rem !important;
    }

    .t2\:mb-n8,
    .t2\:my-n8 {
        margin-bottom: -0.8rem !important;
    }

    .t2\:ml-n8,
    .t2\:mx-n8 {
        margin-left: -0.8rem !important;
    }

    .t2\:m-auto {
        margin: auto !important;
    }

    .t2\:mt-auto,
    .t2\:my-auto {
        margin-top: auto !important;
    }

    .t2\:mr-auto,
    .t2\:mx-auto {
        margin-right: auto !important;
    }

    .t2\:mb-auto,
    .t2\:my-auto {
        margin-bottom: auto !important;
    }

    .t2\:ml-auto,
    .t2\:mx-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 812px) {
    .t3\:u-d-none {
        display: none !important;
    }

    .t3\:u-d-inline {
        display: inline !important;
    }

    .t3\:u-d-inline-block {
        display: inline-block !important;
    }

    .t3\:u-d-block {
        display: block !important;
    }

    .t3\:u-d-table {
        display: table !important;
    }

    .t3\:u-d-table-row {
        display: table-row !important;
    }

    .t3\:u-d-table-cell {
        display: table-cell !important;
    }

    .t3\:u-d-flex {
        display: flex !important;
    }

    .t3\:u-d-inline-flex {
        display: inline-flex !important;
    }

    .t3\:u-minw-auto {
        min-width: auto !important;
    }

    .t3\:u-minw-0 {
        min-width: 0 !important;
    }

    .t3\:u-minw-100 {
        min-width: 10rem !important;
    }

    .t3\:u-h-auto {
        height: auto !important;
    }

    .t3\:u-h-0 {
        height: 0 !important;
    }

    .t3\:u-h-100p {
        height: 100% !important;
    }

    .t3\:u-flex-fill {
        flex: 1 1 auto !important;
    }

    .t3\:u-flex-row {
        flex-direction: row !important;
    }

    .t3\:u-flex-column {
        flex-direction: column !important;
    }

    .t3\:u-flex-row-reverse {
        flex-direction: row-reverse !important;
    }

    .t3\:u-flex-column-reverse {
        flex-direction: column-reverse !important;
    }

    .t3\:u-flex-grow-0 {
        flex-grow: 0 !important;
    }

    .t3\:u-flex-grow-1 {
        flex-grow: 1 !important;
    }

    .t3\:u-flex-shrink-0 {
        flex-shrink: 0 !important;
    }

    .t3\:u-flex-shrink-1 {
        flex-shrink: 1 !important;
    }

    .t3\:u-flex-wrap {
        flex-wrap: wrap !important;
    }

    .t3\:u-flex-nowrap {
        flex-wrap: nowrap !important;
    }

    .t3\:u-flex-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .t3\:u-jc-start {
        justify-content: flex-start !important;
    }

    .t3\:u-jc-end {
        justify-content: flex-end !important;
    }

    .t3\:u-jc-center {
        justify-content: center !important;
    }

    .t3\:u-jc-between {
        justify-content: space-between !important;
    }

    .t3\:u-jc-around {
        justify-content: space-around !important;
    }

    .t3\:u-jc-evenly {
        justify-content: space-evenly !important;
    }

    .t3\:u-ai-start {
        align-items: flex-start !important;
    }

    .t3\:u-ai-end {
        align-items: flex-end !important;
    }

    .t3\:u-ai-center {
        align-items: center !important;
    }

    .t3\:u-ai-baseline {
        align-items: baseline !important;
    }

    .t3\:u-ai-stretch {
        align-items: stretch !important;
    }

    .t3\:u-ac-start {
        align-content: flex-start !important;
    }

    .t3\:u-ac-end {
        align-content: flex-end !important;
    }

    .t3\:u-ac-center {
        align-content: center !important;
    }

    .t3\:u-ac-between {
        align-content: space-between !important;
    }

    .t3\:u-ac-around {
        align-content: space-around !important;
    }

    .t3\:u-ac-stretch {
        align-content: stretch !important;
    }

    .t3\:u-as-auto {
        align-self: auto !important;
    }

    .t3\:u-as-start {
        align-self: flex-start !important;
    }

    .t3\:u-as-end {
        align-self: flex-end !important;
    }

    .t3\:u-as-center {
        align-self: center !important;
    }

    .t3\:u-as-baseline {
        align-self: baseline !important;
    }

    .t3\:u-as-stretch {
        align-self: stretch !important;
    }

    .t3\:order-first {
        order: -1 !important;
    }

    .t3\:order-0 {
        order: 0 !important;
    }

    .t3\:order-1 {
        order: 1 !important;
    }

    .t3\:order-2 {
        order: 2 !important;
    }

    .t3\:order-3 {
        order: 3 !important;
    }

    .t3\:order-4 {
        order: 4 !important;
    }

    .t3\:order-5 {
        order: 5 !important;
    }

    .t3\:order-last {
        order: 6 !important;
    }

    .t3\:u-ta-left {
        text-align: left !important;
    }

    .t3\:u-ta-right {
        text-align: right !important;
    }

    .t3\:u-ta-center {
        text-align: center !important;
    }

    .t3\:m-0 {
        margin: 0 !important;
    }

    .t3\:mt-0,
    .t3\:my-0 {
        margin-top: 0 !important;
    }

    .t3\:mr-0,
    .t3\:mx-0 {
        margin-right: 0 !important;
    }

    .t3\:mb-0,
    .t3\:my-0 {
        margin-bottom: 0 !important;
    }

    .t3\:ml-0,
    .t3\:mx-0 {
        margin-left: 0 !important;
    }

    .t3\:m-4 {
        margin: 0.4rem !important;
    }

    .t3\:mt-4,
    .t3\:my-4 {
        margin-top: 0.4rem !important;
    }

    .t3\:mr-4,
    .t3\:mx-4 {
        margin-right: 0.4rem !important;
    }

    .t3\:mb-4,
    .t3\:my-4 {
        margin-bottom: 0.4rem !important;
    }

    .t3\:ml-4,
    .t3\:mx-4 {
        margin-left: 0.4rem !important;
    }

    .t3\:m-8 {
        margin: 0.8rem !important;
    }

    .t3\:mt-8,
    .t3\:my-8 {
        margin-top: 0.8rem !important;
    }

    .t3\:mr-8,
    .t3\:mx-8 {
        margin-right: 0.8rem !important;
    }

    .t3\:mb-8,
    .t3\:my-8 {
        margin-bottom: 0.8rem !important;
    }

    .t3\:ml-8,
    .t3\:mx-8 {
        margin-left: 0.8rem !important;
    }

    .t3\:p-0 {
        padding: 0 !important;
    }

    .t3\:pt-0,
    .t3\:py-0 {
        padding-top: 0 !important;
    }

    .t3\:pr-0,
    .t3\:px-0 {
        padding-right: 0 !important;
    }

    .t3\:pb-0,
    .t3\:py-0 {
        padding-bottom: 0 !important;
    }

    .t3\:pl-0,
    .t3\:px-0 {
        padding-left: 0 !important;
    }

    .t3\:p-4 {
        padding: 0.4rem !important;
    }

    .t3\:pt-4,
    .t3\:py-4 {
        padding-top: 0.4rem !important;
    }

    .t3\:pr-4,
    .t3\:px-4 {
        padding-right: 0.4rem !important;
    }

    .t3\:pb-4,
    .t3\:py-4 {
        padding-bottom: 0.4rem !important;
    }

    .t3\:pl-4,
    .t3\:px-4 {
        padding-left: 0.4rem !important;
    }

    .t3\:p-8 {
        padding: 0.8rem !important;
    }

    .t3\:pt-8,
    .t3\:py-8 {
        padding-top: 0.8rem !important;
    }

    .t3\:pr-8,
    .t3\:px-8 {
        padding-right: 0.8rem !important;
    }

    .t3\:pb-8,
    .t3\:py-8 {
        padding-bottom: 0.8rem !important;
    }

    .t3\:pl-8,
    .t3\:px-8 {
        padding-left: 0.8rem !important;
    }

    .t3\:m-n4 {
        margin: -0.4rem !important;
    }

    .t3\:mt-n4,
    .t3\:my-n4 {
        margin-top: -0.4rem !important;
    }

    .t3\:mr-n4,
    .t3\:mx-n4 {
        margin-right: -0.4rem !important;
    }

    .t3\:mb-n4,
    .t3\:my-n4 {
        margin-bottom: -0.4rem !important;
    }

    .t3\:ml-n4,
    .t3\:mx-n4 {
        margin-left: -0.4rem !important;
    }

    .t3\:m-n8 {
        margin: -0.8rem !important;
    }

    .t3\:mt-n8,
    .t3\:my-n8 {
        margin-top: -0.8rem !important;
    }

    .t3\:mr-n8,
    .t3\:mx-n8 {
        margin-right: -0.8rem !important;
    }

    .t3\:mb-n8,
    .t3\:my-n8 {
        margin-bottom: -0.8rem !important;
    }

    .t3\:ml-n8,
    .t3\:mx-n8 {
        margin-left: -0.8rem !important;
    }

    .t3\:m-auto {
        margin: auto !important;
    }

    .t3\:mt-auto,
    .t3\:my-auto {
        margin-top: auto !important;
    }

    .t3\:mr-auto,
    .t3\:mx-auto {
        margin-right: auto !important;
    }

    .t3\:mb-auto,
    .t3\:my-auto {
        margin-bottom: auto !important;
    }

    .t3\:ml-auto,
    .t3\:mx-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 992px) {
    .t4\:u-d-none {
        display: none !important;
    }

    .t4\:u-d-inline {
        display: inline !important;
    }

    .t4\:u-d-inline-block {
        display: inline-block !important;
    }

    .t4\:u-d-block {
        display: block !important;
    }

    .t4\:u-d-table {
        display: table !important;
    }

    .t4\:u-d-table-row {
        display: table-row !important;
    }

    .t4\:u-d-table-cell {
        display: table-cell !important;
    }

    .t4\:u-d-flex {
        display: flex !important;
    }

    .t4\:u-d-inline-flex {
        display: inline-flex !important;
    }

    .t4\:u-minw-auto {
        min-width: auto !important;
    }

    .t4\:u-minw-0 {
        min-width: 0 !important;
    }

    .t4\:u-minw-100 {
        min-width: 10rem !important;
    }

    .t4\:u-h-auto {
        height: auto !important;
    }

    .t4\:u-h-0 {
        height: 0 !important;
    }

    .t4\:u-h-100p {
        height: 100% !important;
    }

    .t4\:u-flex-fill {
        flex: 1 1 auto !important;
    }

    .t4\:u-flex-row {
        flex-direction: row !important;
    }

    .t4\:u-flex-column {
        flex-direction: column !important;
    }

    .t4\:u-flex-row-reverse {
        flex-direction: row-reverse !important;
    }

    .t4\:u-flex-column-reverse {
        flex-direction: column-reverse !important;
    }

    .t4\:u-flex-grow-0 {
        flex-grow: 0 !important;
    }

    .t4\:u-flex-grow-1 {
        flex-grow: 1 !important;
    }

    .t4\:u-flex-shrink-0 {
        flex-shrink: 0 !important;
    }

    .t4\:u-flex-shrink-1 {
        flex-shrink: 1 !important;
    }

    .t4\:u-flex-wrap {
        flex-wrap: wrap !important;
    }

    .t4\:u-flex-nowrap {
        flex-wrap: nowrap !important;
    }

    .t4\:u-flex-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .t4\:u-jc-start {
        justify-content: flex-start !important;
    }

    .t4\:u-jc-end {
        justify-content: flex-end !important;
    }

    .t4\:u-jc-center {
        justify-content: center !important;
    }

    .t4\:u-jc-between {
        justify-content: space-between !important;
    }

    .t4\:u-jc-around {
        justify-content: space-around !important;
    }

    .t4\:u-jc-evenly {
        justify-content: space-evenly !important;
    }

    .t4\:u-ai-start {
        align-items: flex-start !important;
    }

    .t4\:u-ai-end {
        align-items: flex-end !important;
    }

    .t4\:u-ai-center {
        align-items: center !important;
    }

    .t4\:u-ai-baseline {
        align-items: baseline !important;
    }

    .t4\:u-ai-stretch {
        align-items: stretch !important;
    }

    .t4\:u-ac-start {
        align-content: flex-start !important;
    }

    .t4\:u-ac-end {
        align-content: flex-end !important;
    }

    .t4\:u-ac-center {
        align-content: center !important;
    }

    .t4\:u-ac-between {
        align-content: space-between !important;
    }

    .t4\:u-ac-around {
        align-content: space-around !important;
    }

    .t4\:u-ac-stretch {
        align-content: stretch !important;
    }

    .t4\:u-as-auto {
        align-self: auto !important;
    }

    .t4\:u-as-start {
        align-self: flex-start !important;
    }

    .t4\:u-as-end {
        align-self: flex-end !important;
    }

    .t4\:u-as-center {
        align-self: center !important;
    }

    .t4\:u-as-baseline {
        align-self: baseline !important;
    }

    .t4\:u-as-stretch {
        align-self: stretch !important;
    }

    .t4\:order-first {
        order: -1 !important;
    }

    .t4\:order-0 {
        order: 0 !important;
    }

    .t4\:order-1 {
        order: 1 !important;
    }

    .t4\:order-2 {
        order: 2 !important;
    }

    .t4\:order-3 {
        order: 3 !important;
    }

    .t4\:order-4 {
        order: 4 !important;
    }

    .t4\:order-5 {
        order: 5 !important;
    }

    .t4\:order-last {
        order: 6 !important;
    }

    .t4\:u-ta-left {
        text-align: left !important;
    }

    .t4\:u-ta-right {
        text-align: right !important;
    }

    .t4\:u-ta-center {
        text-align: center !important;
    }

    .t4\:m-0 {
        margin: 0 !important;
    }

    .t4\:mt-0,
    .t4\:my-0 {
        margin-top: 0 !important;
    }

    .t4\:mr-0,
    .t4\:mx-0 {
        margin-right: 0 !important;
    }

    .t4\:mb-0,
    .t4\:my-0 {
        margin-bottom: 0 !important;
    }

    .t4\:ml-0,
    .t4\:mx-0 {
        margin-left: 0 !important;
    }

    .t4\:m-4 {
        margin: 0.4rem !important;
    }

    .t4\:mt-4,
    .t4\:my-4 {
        margin-top: 0.4rem !important;
    }

    .t4\:mr-4,
    .t4\:mx-4 {
        margin-right: 0.4rem !important;
    }

    .t4\:mb-4,
    .t4\:my-4 {
        margin-bottom: 0.4rem !important;
    }

    .t4\:ml-4,
    .t4\:mx-4 {
        margin-left: 0.4rem !important;
    }

    .t4\:m-8 {
        margin: 0.8rem !important;
    }

    .t4\:mt-8,
    .t4\:my-8 {
        margin-top: 0.8rem !important;
    }

    .t4\:mr-8,
    .t4\:mx-8 {
        margin-right: 0.8rem !important;
    }

    .t4\:mb-8,
    .t4\:my-8 {
        margin-bottom: 0.8rem !important;
    }

    .t4\:ml-8,
    .t4\:mx-8 {
        margin-left: 0.8rem !important;
    }

    .t4\:p-0 {
        padding: 0 !important;
    }

    .t4\:pt-0,
    .t4\:py-0 {
        padding-top: 0 !important;
    }

    .t4\:pr-0,
    .t4\:px-0 {
        padding-right: 0 !important;
    }

    .t4\:pb-0,
    .t4\:py-0 {
        padding-bottom: 0 !important;
    }

    .t4\:pl-0,
    .t4\:px-0 {
        padding-left: 0 !important;
    }

    .t4\:p-4 {
        padding: 0.4rem !important;
    }

    .t4\:pt-4,
    .t4\:py-4 {
        padding-top: 0.4rem !important;
    }

    .t4\:pr-4,
    .t4\:px-4 {
        padding-right: 0.4rem !important;
    }

    .t4\:pb-4,
    .t4\:py-4 {
        padding-bottom: 0.4rem !important;
    }

    .t4\:pl-4,
    .t4\:px-4 {
        padding-left: 0.4rem !important;
    }

    .t4\:p-8 {
        padding: 0.8rem !important;
    }

    .t4\:pt-8,
    .t4\:py-8 {
        padding-top: 0.8rem !important;
    }

    .t4\:pr-8,
    .t4\:px-8 {
        padding-right: 0.8rem !important;
    }

    .t4\:pb-8,
    .t4\:py-8 {
        padding-bottom: 0.8rem !important;
    }

    .t4\:pl-8,
    .t4\:px-8 {
        padding-left: 0.8rem !important;
    }

    .t4\:m-n4 {
        margin: -0.4rem !important;
    }

    .t4\:mt-n4,
    .t4\:my-n4 {
        margin-top: -0.4rem !important;
    }

    .t4\:mr-n4,
    .t4\:mx-n4 {
        margin-right: -0.4rem !important;
    }

    .t4\:mb-n4,
    .t4\:my-n4 {
        margin-bottom: -0.4rem !important;
    }

    .t4\:ml-n4,
    .t4\:mx-n4 {
        margin-left: -0.4rem !important;
    }

    .t4\:m-n8 {
        margin: -0.8rem !important;
    }

    .t4\:mt-n8,
    .t4\:my-n8 {
        margin-top: -0.8rem !important;
    }

    .t4\:mr-n8,
    .t4\:mx-n8 {
        margin-right: -0.8rem !important;
    }

    .t4\:mb-n8,
    .t4\:my-n8 {
        margin-bottom: -0.8rem !important;
    }

    .t4\:ml-n8,
    .t4\:mx-n8 {
        margin-left: -0.8rem !important;
    }

    .t4\:m-auto {
        margin: auto !important;
    }

    .t4\:mt-auto,
    .t4\:my-auto {
        margin-top: auto !important;
    }

    .t4\:mr-auto,
    .t4\:mx-auto {
        margin-right: auto !important;
    }

    .t4\:mb-auto,
    .t4\:my-auto {
        margin-bottom: auto !important;
    }

    .t4\:ml-auto,
    .t4\:mx-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 1200px) {
    .d1\:u-d-none {
        display: none !important;
    }

    .d1\:u-d-inline {
        display: inline !important;
    }

    .d1\:u-d-inline-block {
        display: inline-block !important;
    }

    .d1\:u-d-block {
        display: block !important;
    }

    .d1\:u-d-table {
        display: table !important;
    }

    .d1\:u-d-table-row {
        display: table-row !important;
    }

    .d1\:u-d-table-cell {
        display: table-cell !important;
    }

    .d1\:u-d-flex {
        display: flex !important;
    }

    .d1\:u-d-inline-flex {
        display: inline-flex !important;
    }

    .d1\:u-minw-auto {
        min-width: auto !important;
    }

    .d1\:u-minw-0 {
        min-width: 0 !important;
    }

    .d1\:u-minw-100 {
        min-width: 10rem !important;
    }

    .d1\:u-h-auto {
        height: auto !important;
    }

    .d1\:u-h-0 {
        height: 0 !important;
    }

    .d1\:u-h-100p {
        height: 100% !important;
    }

    .d1\:u-flex-fill {
        flex: 1 1 auto !important;
    }

    .d1\:u-flex-row {
        flex-direction: row !important;
    }

    .d1\:u-flex-column {
        flex-direction: column !important;
    }

    .d1\:u-flex-row-reverse {
        flex-direction: row-reverse !important;
    }

    .d1\:u-flex-column-reverse {
        flex-direction: column-reverse !important;
    }

    .d1\:u-flex-grow-0 {
        flex-grow: 0 !important;
    }

    .d1\:u-flex-grow-1 {
        flex-grow: 1 !important;
    }

    .d1\:u-flex-shrink-0 {
        flex-shrink: 0 !important;
    }

    .d1\:u-flex-shrink-1 {
        flex-shrink: 1 !important;
    }

    .d1\:u-flex-wrap {
        flex-wrap: wrap !important;
    }

    .d1\:u-flex-nowrap {
        flex-wrap: nowrap !important;
    }

    .d1\:u-flex-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .d1\:u-jc-start {
        justify-content: flex-start !important;
    }

    .d1\:u-jc-end {
        justify-content: flex-end !important;
    }

    .d1\:u-jc-center {
        justify-content: center !important;
    }

    .d1\:u-jc-between {
        justify-content: space-between !important;
    }

    .d1\:u-jc-around {
        justify-content: space-around !important;
    }

    .d1\:u-jc-evenly {
        justify-content: space-evenly !important;
    }

    .d1\:u-ai-start {
        align-items: flex-start !important;
    }

    .d1\:u-ai-end {
        align-items: flex-end !important;
    }

    .d1\:u-ai-center {
        align-items: center !important;
    }

    .d1\:u-ai-baseline {
        align-items: baseline !important;
    }

    .d1\:u-ai-stretch {
        align-items: stretch !important;
    }

    .d1\:u-ac-start {
        align-content: flex-start !important;
    }

    .d1\:u-ac-end {
        align-content: flex-end !important;
    }

    .d1\:u-ac-center {
        align-content: center !important;
    }

    .d1\:u-ac-between {
        align-content: space-between !important;
    }

    .d1\:u-ac-around {
        align-content: space-around !important;
    }

    .d1\:u-ac-stretch {
        align-content: stretch !important;
    }

    .d1\:u-as-auto {
        align-self: auto !important;
    }

    .d1\:u-as-start {
        align-self: flex-start !important;
    }

    .d1\:u-as-end {
        align-self: flex-end !important;
    }

    .d1\:u-as-center {
        align-self: center !important;
    }

    .d1\:u-as-baseline {
        align-self: baseline !important;
    }

    .d1\:u-as-stretch {
        align-self: stretch !important;
    }

    .d1\:order-first {
        order: -1 !important;
    }

    .d1\:order-0 {
        order: 0 !important;
    }

    .d1\:order-1 {
        order: 1 !important;
    }

    .d1\:order-2 {
        order: 2 !important;
    }

    .d1\:order-3 {
        order: 3 !important;
    }

    .d1\:order-4 {
        order: 4 !important;
    }

    .d1\:order-5 {
        order: 5 !important;
    }

    .d1\:order-last {
        order: 6 !important;
    }

    .d1\:u-ta-left {
        text-align: left !important;
    }

    .d1\:u-ta-right {
        text-align: right !important;
    }

    .d1\:u-ta-center {
        text-align: center !important;
    }

    .d1\:m-0 {
        margin: 0 !important;
    }

    .d1\:mt-0,
    .d1\:my-0 {
        margin-top: 0 !important;
    }

    .d1\:mr-0,
    .d1\:mx-0 {
        margin-right: 0 !important;
    }

    .d1\:mb-0,
    .d1\:my-0 {
        margin-bottom: 0 !important;
    }

    .d1\:ml-0,
    .d1\:mx-0 {
        margin-left: 0 !important;
    }

    .d1\:m-4 {
        margin: 0.4rem !important;
    }

    .d1\:mt-4,
    .d1\:my-4 {
        margin-top: 0.4rem !important;
    }

    .d1\:mr-4,
    .d1\:mx-4 {
        margin-right: 0.4rem !important;
    }

    .d1\:mb-4,
    .d1\:my-4 {
        margin-bottom: 0.4rem !important;
    }

    .d1\:ml-4,
    .d1\:mx-4 {
        margin-left: 0.4rem !important;
    }

    .d1\:m-8 {
        margin: 0.8rem !important;
    }

    .d1\:mt-8,
    .d1\:my-8 {
        margin-top: 0.8rem !important;
    }

    .d1\:mr-8,
    .d1\:mx-8 {
        margin-right: 0.8rem !important;
    }

    .d1\:mb-8,
    .d1\:my-8 {
        margin-bottom: 0.8rem !important;
    }

    .d1\:ml-8,
    .d1\:mx-8 {
        margin-left: 0.8rem !important;
    }

    .d1\:p-0 {
        padding: 0 !important;
    }

    .d1\:pt-0,
    .d1\:py-0 {
        padding-top: 0 !important;
    }

    .d1\:pr-0,
    .d1\:px-0 {
        padding-right: 0 !important;
    }

    .d1\:pb-0,
    .d1\:py-0 {
        padding-bottom: 0 !important;
    }

    .d1\:pl-0,
    .d1\:px-0 {
        padding-left: 0 !important;
    }

    .d1\:p-4 {
        padding: 0.4rem !important;
    }

    .d1\:pt-4,
    .d1\:py-4 {
        padding-top: 0.4rem !important;
    }

    .d1\:pr-4,
    .d1\:px-4 {
        padding-right: 0.4rem !important;
    }

    .d1\:pb-4,
    .d1\:py-4 {
        padding-bottom: 0.4rem !important;
    }

    .d1\:pl-4,
    .d1\:px-4 {
        padding-left: 0.4rem !important;
    }

    .d1\:p-8 {
        padding: 0.8rem !important;
    }

    .d1\:pt-8,
    .d1\:py-8 {
        padding-top: 0.8rem !important;
    }

    .d1\:pr-8,
    .d1\:px-8 {
        padding-right: 0.8rem !important;
    }

    .d1\:pb-8,
    .d1\:py-8 {
        padding-bottom: 0.8rem !important;
    }

    .d1\:pl-8,
    .d1\:px-8 {
        padding-left: 0.8rem !important;
    }

    .d1\:m-n4 {
        margin: -0.4rem !important;
    }

    .d1\:mt-n4,
    .d1\:my-n4 {
        margin-top: -0.4rem !important;
    }

    .d1\:mr-n4,
    .d1\:mx-n4 {
        margin-right: -0.4rem !important;
    }

    .d1\:mb-n4,
    .d1\:my-n4 {
        margin-bottom: -0.4rem !important;
    }

    .d1\:ml-n4,
    .d1\:mx-n4 {
        margin-left: -0.4rem !important;
    }

    .d1\:m-n8 {
        margin: -0.8rem !important;
    }

    .d1\:mt-n8,
    .d1\:my-n8 {
        margin-top: -0.8rem !important;
    }

    .d1\:mr-n8,
    .d1\:mx-n8 {
        margin-right: -0.8rem !important;
    }

    .d1\:mb-n8,
    .d1\:my-n8 {
        margin-bottom: -0.8rem !important;
    }

    .d1\:ml-n8,
    .d1\:mx-n8 {
        margin-left: -0.8rem !important;
    }

    .d1\:m-auto {
        margin: auto !important;
    }

    .d1\:mt-auto,
    .d1\:my-auto {
        margin-top: auto !important;
    }

    .d1\:mr-auto,
    .d1\:mx-auto {
        margin-right: auto !important;
    }

    .d1\:mb-auto,
    .d1\:my-auto {
        margin-bottom: auto !important;
    }

    .d1\:ml-auto,
    .d1\:mx-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 1400px) {
    .d2\:u-d-none {
        display: none !important;
    }

    .d2\:u-d-inline {
        display: inline !important;
    }

    .d2\:u-d-inline-block {
        display: inline-block !important;
    }

    .d2\:u-d-block {
        display: block !important;
    }

    .d2\:u-d-table {
        display: table !important;
    }

    .d2\:u-d-table-row {
        display: table-row !important;
    }

    .d2\:u-d-table-cell {
        display: table-cell !important;
    }

    .d2\:u-d-flex {
        display: flex !important;
    }

    .d2\:u-d-inline-flex {
        display: inline-flex !important;
    }

    .d2\:u-minw-auto {
        min-width: auto !important;
    }

    .d2\:u-minw-0 {
        min-width: 0 !important;
    }

    .d2\:u-minw-100 {
        min-width: 10rem !important;
    }

    .d2\:u-h-auto {
        height: auto !important;
    }

    .d2\:u-h-0 {
        height: 0 !important;
    }

    .d2\:u-h-100p {
        height: 100% !important;
    }

    .d2\:u-flex-fill {
        flex: 1 1 auto !important;
    }

    .d2\:u-flex-row {
        flex-direction: row !important;
    }

    .d2\:u-flex-column {
        flex-direction: column !important;
    }

    .d2\:u-flex-row-reverse {
        flex-direction: row-reverse !important;
    }

    .d2\:u-flex-column-reverse {
        flex-direction: column-reverse !important;
    }

    .d2\:u-flex-grow-0 {
        flex-grow: 0 !important;
    }

    .d2\:u-flex-grow-1 {
        flex-grow: 1 !important;
    }

    .d2\:u-flex-shrink-0 {
        flex-shrink: 0 !important;
    }

    .d2\:u-flex-shrink-1 {
        flex-shrink: 1 !important;
    }

    .d2\:u-flex-wrap {
        flex-wrap: wrap !important;
    }

    .d2\:u-flex-nowrap {
        flex-wrap: nowrap !important;
    }

    .d2\:u-flex-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .d2\:u-jc-start {
        justify-content: flex-start !important;
    }

    .d2\:u-jc-end {
        justify-content: flex-end !important;
    }

    .d2\:u-jc-center {
        justify-content: center !important;
    }

    .d2\:u-jc-between {
        justify-content: space-between !important;
    }

    .d2\:u-jc-around {
        justify-content: space-around !important;
    }

    .d2\:u-jc-evenly {
        justify-content: space-evenly !important;
    }

    .d2\:u-ai-start {
        align-items: flex-start !important;
    }

    .d2\:u-ai-end {
        align-items: flex-end !important;
    }

    .d2\:u-ai-center {
        align-items: center !important;
    }

    .d2\:u-ai-baseline {
        align-items: baseline !important;
    }

    .d2\:u-ai-stretch {
        align-items: stretch !important;
    }

    .d2\:u-ac-start {
        align-content: flex-start !important;
    }

    .d2\:u-ac-end {
        align-content: flex-end !important;
    }

    .d2\:u-ac-center {
        align-content: center !important;
    }

    .d2\:u-ac-between {
        align-content: space-between !important;
    }

    .d2\:u-ac-around {
        align-content: space-around !important;
    }

    .d2\:u-ac-stretch {
        align-content: stretch !important;
    }

    .d2\:u-as-auto {
        align-self: auto !important;
    }

    .d2\:u-as-start {
        align-self: flex-start !important;
    }

    .d2\:u-as-end {
        align-self: flex-end !important;
    }

    .d2\:u-as-center {
        align-self: center !important;
    }

    .d2\:u-as-baseline {
        align-self: baseline !important;
    }

    .d2\:u-as-stretch {
        align-self: stretch !important;
    }

    .d2\:order-first {
        order: -1 !important;
    }

    .d2\:order-0 {
        order: 0 !important;
    }

    .d2\:order-1 {
        order: 1 !important;
    }

    .d2\:order-2 {
        order: 2 !important;
    }

    .d2\:order-3 {
        order: 3 !important;
    }

    .d2\:order-4 {
        order: 4 !important;
    }

    .d2\:order-5 {
        order: 5 !important;
    }

    .d2\:order-last {
        order: 6 !important;
    }

    .d2\:u-ta-left {
        text-align: left !important;
    }

    .d2\:u-ta-right {
        text-align: right !important;
    }

    .d2\:u-ta-center {
        text-align: center !important;
    }

    .d2\:m-0 {
        margin: 0 !important;
    }

    .d2\:mt-0,
    .d2\:my-0 {
        margin-top: 0 !important;
    }

    .d2\:mr-0,
    .d2\:mx-0 {
        margin-right: 0 !important;
    }

    .d2\:mb-0,
    .d2\:my-0 {
        margin-bottom: 0 !important;
    }

    .d2\:ml-0,
    .d2\:mx-0 {
        margin-left: 0 !important;
    }

    .d2\:m-4 {
        margin: 0.4rem !important;
    }

    .d2\:mt-4,
    .d2\:my-4 {
        margin-top: 0.4rem !important;
    }

    .d2\:mr-4,
    .d2\:mx-4 {
        margin-right: 0.4rem !important;
    }

    .d2\:mb-4,
    .d2\:my-4 {
        margin-bottom: 0.4rem !important;
    }

    .d2\:ml-4,
    .d2\:mx-4 {
        margin-left: 0.4rem !important;
    }

    .d2\:m-8 {
        margin: 0.8rem !important;
    }

    .d2\:mt-8,
    .d2\:my-8 {
        margin-top: 0.8rem !important;
    }

    .d2\:mr-8,
    .d2\:mx-8 {
        margin-right: 0.8rem !important;
    }

    .d2\:mb-8,
    .d2\:my-8 {
        margin-bottom: 0.8rem !important;
    }

    .d2\:ml-8,
    .d2\:mx-8 {
        margin-left: 0.8rem !important;
    }

    .d2\:p-0 {
        padding: 0 !important;
    }

    .d2\:pt-0,
    .d2\:py-0 {
        padding-top: 0 !important;
    }

    .d2\:pr-0,
    .d2\:px-0 {
        padding-right: 0 !important;
    }

    .d2\:pb-0,
    .d2\:py-0 {
        padding-bottom: 0 !important;
    }

    .d2\:pl-0,
    .d2\:px-0 {
        padding-left: 0 !important;
    }

    .d2\:p-4 {
        padding: 0.4rem !important;
    }

    .d2\:pt-4,
    .d2\:py-4 {
        padding-top: 0.4rem !important;
    }

    .d2\:pr-4,
    .d2\:px-4 {
        padding-right: 0.4rem !important;
    }

    .d2\:pb-4,
    .d2\:py-4 {
        padding-bottom: 0.4rem !important;
    }

    .d2\:pl-4,
    .d2\:px-4 {
        padding-left: 0.4rem !important;
    }

    .d2\:p-8 {
        padding: 0.8rem !important;
    }

    .d2\:pt-8,
    .d2\:py-8 {
        padding-top: 0.8rem !important;
    }

    .d2\:pr-8,
    .d2\:px-8 {
        padding-right: 0.8rem !important;
    }

    .d2\:pb-8,
    .d2\:py-8 {
        padding-bottom: 0.8rem !important;
    }

    .d2\:pl-8,
    .d2\:px-8 {
        padding-left: 0.8rem !important;
    }

    .d2\:m-n4 {
        margin: -0.4rem !important;
    }

    .d2\:mt-n4,
    .d2\:my-n4 {
        margin-top: -0.4rem !important;
    }

    .d2\:mr-n4,
    .d2\:mx-n4 {
        margin-right: -0.4rem !important;
    }

    .d2\:mb-n4,
    .d2\:my-n4 {
        margin-bottom: -0.4rem !important;
    }

    .d2\:ml-n4,
    .d2\:mx-n4 {
        margin-left: -0.4rem !important;
    }

    .d2\:m-n8 {
        margin: -0.8rem !important;
    }

    .d2\:mt-n8,
    .d2\:my-n8 {
        margin-top: -0.8rem !important;
    }

    .d2\:mr-n8,
    .d2\:mx-n8 {
        margin-right: -0.8rem !important;
    }

    .d2\:mb-n8,
    .d2\:my-n8 {
        margin-bottom: -0.8rem !important;
    }

    .d2\:ml-n8,
    .d2\:mx-n8 {
        margin-left: -0.8rem !important;
    }

    .d2\:m-auto {
        margin: auto !important;
    }

    .d2\:mt-auto,
    .d2\:my-auto {
        margin-top: auto !important;
    }

    .d2\:mr-auto,
    .d2\:mx-auto {
        margin-right: auto !important;
    }

    .d2\:mb-auto,
    .d2\:my-auto {
        margin-bottom: auto !important;
    }

    .d2\:ml-auto,
    .d2\:mx-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 1600px) {
    .d3\:u-d-none {
        display: none !important;
    }

    .d3\:u-d-inline {
        display: inline !important;
    }

    .d3\:u-d-inline-block {
        display: inline-block !important;
    }

    .d3\:u-d-block {
        display: block !important;
    }

    .d3\:u-d-table {
        display: table !important;
    }

    .d3\:u-d-table-row {
        display: table-row !important;
    }

    .d3\:u-d-table-cell {
        display: table-cell !important;
    }

    .d3\:u-d-flex {
        display: flex !important;
    }

    .d3\:u-d-inline-flex {
        display: inline-flex !important;
    }

    .d3\:u-minw-auto {
        min-width: auto !important;
    }

    .d3\:u-minw-0 {
        min-width: 0 !important;
    }

    .d3\:u-minw-100 {
        min-width: 10rem !important;
    }

    .d3\:u-h-auto {
        height: auto !important;
    }

    .d3\:u-h-0 {
        height: 0 !important;
    }

    .d3\:u-h-100p {
        height: 100% !important;
    }

    .d3\:u-flex-fill {
        flex: 1 1 auto !important;
    }

    .d3\:u-flex-row {
        flex-direction: row !important;
    }

    .d3\:u-flex-column {
        flex-direction: column !important;
    }

    .d3\:u-flex-row-reverse {
        flex-direction: row-reverse !important;
    }

    .d3\:u-flex-column-reverse {
        flex-direction: column-reverse !important;
    }

    .d3\:u-flex-grow-0 {
        flex-grow: 0 !important;
    }

    .d3\:u-flex-grow-1 {
        flex-grow: 1 !important;
    }

    .d3\:u-flex-shrink-0 {
        flex-shrink: 0 !important;
    }

    .d3\:u-flex-shrink-1 {
        flex-shrink: 1 !important;
    }

    .d3\:u-flex-wrap {
        flex-wrap: wrap !important;
    }

    .d3\:u-flex-nowrap {
        flex-wrap: nowrap !important;
    }

    .d3\:u-flex-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .d3\:u-jc-start {
        justify-content: flex-start !important;
    }

    .d3\:u-jc-end {
        justify-content: flex-end !important;
    }

    .d3\:u-jc-center {
        justify-content: center !important;
    }

    .d3\:u-jc-between {
        justify-content: space-between !important;
    }

    .d3\:u-jc-around {
        justify-content: space-around !important;
    }

    .d3\:u-jc-evenly {
        justify-content: space-evenly !important;
    }

    .d3\:u-ai-start {
        align-items: flex-start !important;
    }

    .d3\:u-ai-end {
        align-items: flex-end !important;
    }

    .d3\:u-ai-center {
        align-items: center !important;
    }

    .d3\:u-ai-baseline {
        align-items: baseline !important;
    }

    .d3\:u-ai-stretch {
        align-items: stretch !important;
    }

    .d3\:u-ac-start {
        align-content: flex-start !important;
    }

    .d3\:u-ac-end {
        align-content: flex-end !important;
    }

    .d3\:u-ac-center {
        align-content: center !important;
    }

    .d3\:u-ac-between {
        align-content: space-between !important;
    }

    .d3\:u-ac-around {
        align-content: space-around !important;
    }

    .d3\:u-ac-stretch {
        align-content: stretch !important;
    }

    .d3\:u-as-auto {
        align-self: auto !important;
    }

    .d3\:u-as-start {
        align-self: flex-start !important;
    }

    .d3\:u-as-end {
        align-self: flex-end !important;
    }

    .d3\:u-as-center {
        align-self: center !important;
    }

    .d3\:u-as-baseline {
        align-self: baseline !important;
    }

    .d3\:u-as-stretch {
        align-self: stretch !important;
    }

    .d3\:order-first {
        order: -1 !important;
    }

    .d3\:order-0 {
        order: 0 !important;
    }

    .d3\:order-1 {
        order: 1 !important;
    }

    .d3\:order-2 {
        order: 2 !important;
    }

    .d3\:order-3 {
        order: 3 !important;
    }

    .d3\:order-4 {
        order: 4 !important;
    }

    .d3\:order-5 {
        order: 5 !important;
    }

    .d3\:order-last {
        order: 6 !important;
    }

    .d3\:u-ta-left {
        text-align: left !important;
    }

    .d3\:u-ta-right {
        text-align: right !important;
    }

    .d3\:u-ta-center {
        text-align: center !important;
    }

    .d3\:m-0 {
        margin: 0 !important;
    }

    .d3\:mt-0,
    .d3\:my-0 {
        margin-top: 0 !important;
    }

    .d3\:mr-0,
    .d3\:mx-0 {
        margin-right: 0 !important;
    }

    .d3\:mb-0,
    .d3\:my-0 {
        margin-bottom: 0 !important;
    }

    .d3\:ml-0,
    .d3\:mx-0 {
        margin-left: 0 !important;
    }

    .d3\:m-4 {
        margin: 0.4rem !important;
    }

    .d3\:mt-4,
    .d3\:my-4 {
        margin-top: 0.4rem !important;
    }

    .d3\:mr-4,
    .d3\:mx-4 {
        margin-right: 0.4rem !important;
    }

    .d3\:mb-4,
    .d3\:my-4 {
        margin-bottom: 0.4rem !important;
    }

    .d3\:ml-4,
    .d3\:mx-4 {
        margin-left: 0.4rem !important;
    }

    .d3\:m-8 {
        margin: 0.8rem !important;
    }

    .d3\:mt-8,
    .d3\:my-8 {
        margin-top: 0.8rem !important;
    }

    .d3\:mr-8,
    .d3\:mx-8 {
        margin-right: 0.8rem !important;
    }

    .d3\:mb-8,
    .d3\:my-8 {
        margin-bottom: 0.8rem !important;
    }

    .d3\:ml-8,
    .d3\:mx-8 {
        margin-left: 0.8rem !important;
    }

    .d3\:p-0 {
        padding: 0 !important;
    }

    .d3\:pt-0,
    .d3\:py-0 {
        padding-top: 0 !important;
    }

    .d3\:pr-0,
    .d3\:px-0 {
        padding-right: 0 !important;
    }

    .d3\:pb-0,
    .d3\:py-0 {
        padding-bottom: 0 !important;
    }

    .d3\:pl-0,
    .d3\:px-0 {
        padding-left: 0 !important;
    }

    .d3\:p-4 {
        padding: 0.4rem !important;
    }

    .d3\:pt-4,
    .d3\:py-4 {
        padding-top: 0.4rem !important;
    }

    .d3\:pr-4,
    .d3\:px-4 {
        padding-right: 0.4rem !important;
    }

    .d3\:pb-4,
    .d3\:py-4 {
        padding-bottom: 0.4rem !important;
    }

    .d3\:pl-4,
    .d3\:px-4 {
        padding-left: 0.4rem !important;
    }

    .d3\:p-8 {
        padding: 0.8rem !important;
    }

    .d3\:pt-8,
    .d3\:py-8 {
        padding-top: 0.8rem !important;
    }

    .d3\:pr-8,
    .d3\:px-8 {
        padding-right: 0.8rem !important;
    }

    .d3\:pb-8,
    .d3\:py-8 {
        padding-bottom: 0.8rem !important;
    }

    .d3\:pl-8,
    .d3\:px-8 {
        padding-left: 0.8rem !important;
    }

    .d3\:m-n4 {
        margin: -0.4rem !important;
    }

    .d3\:mt-n4,
    .d3\:my-n4 {
        margin-top: -0.4rem !important;
    }

    .d3\:mr-n4,
    .d3\:mx-n4 {
        margin-right: -0.4rem !important;
    }

    .d3\:mb-n4,
    .d3\:my-n4 {
        margin-bottom: -0.4rem !important;
    }

    .d3\:ml-n4,
    .d3\:mx-n4 {
        margin-left: -0.4rem !important;
    }

    .d3\:m-n8 {
        margin: -0.8rem !important;
    }

    .d3\:mt-n8,
    .d3\:my-n8 {
        margin-top: -0.8rem !important;
    }

    .d3\:mr-n8,
    .d3\:mx-n8 {
        margin-right: -0.8rem !important;
    }

    .d3\:mb-n8,
    .d3\:my-n8 {
        margin-bottom: -0.8rem !important;
    }

    .d3\:ml-n8,
    .d3\:mx-n8 {
        margin-left: -0.8rem !important;
    }

    .d3\:m-auto {
        margin: auto !important;
    }

    .d3\:mt-auto,
    .d3\:my-auto {
        margin-top: auto !important;
    }

    .d3\:mr-auto,
    .d3\:mx-auto {
        margin-right: auto !important;
    }

    .d3\:mb-auto,
    .d3\:my-auto {
        margin-bottom: auto !important;
    }

    .d3\:ml-auto,
    .d3\:mx-auto {
        margin-left: auto !important;
    }
}

@media print {
    .-printu-d-none {
        display: none !important;
    }

    .-printu-d-inline {
        display: inline !important;
    }

    .-printu-d-inline-block {
        display: inline-block !important;
    }

    .-printu-d-block {
        display: block !important;
    }

    .-printu-d-table {
        display: table !important;
    }

    .-printu-d-table-row {
        display: table-row !important;
    }

    .-printu-d-table-cell {
        display: table-cell !important;
    }

    .-printu-d-flex {
        display: flex !important;
    }

    .-printu-d-inline-flex {
        display: inline-flex !important;
    }
}

.c-footer {
    padding-top: 4rem;
    padding-bottom: 4.8rem;
}

.c-footer__copyright {
    font-size: 1rem;
    line-height: 2;
}

@media (min-width: 768px) {
    .c-footer__copyright {
        font-size: 1rem;
    }
}

@media (min-width: 1200px) {
    .c-footer__copyright {
        font-size: 1.2rem;
    }
}

.c-get-token {
    margin-top: 4.8rem;
}

@media (min-width: 768px) {
    .c-get-token {
        margin-top: 6.4rem;
    }
}

.c-get-token__wrapper {
    padding: 2.8rem 3.2rem;
    background-color: rgb(var(--color-primary-500));
    color: rgb(var(--color-white));
}

@media (min-width: 768px) {
    .c-get-token__wrapper {
        padding: 4rem 4.8rem;
    }
}

@media (min-width: 812px) {
    .c-get-token__wrapper {
        display: flex;
        align-items: center;
    }
}

@media (min-width: 1200px) {
    .c-get-token__wrapper {
        padding: 5.4rem 6.4rem;
    }
}

.c-get-token__heading {
    flex: 1 1 auto;
    min-width: 0;
    max-width: 100%;
}

@media (min-width: 812px) {
    .c-get-token__heading {
        margin-right: 3.2rem;
    }
}

.c-get-token__btns {
    flex: 0 0 auto;
    min-width: 0;
    max-width: 100%;
}

@media (max-width: 811.98px) {
    .c-get-token__btns {
        margin-top: 3.2rem;
    }
}

.c-get-token__btns .btn {
    background-color: rgb(var(--color-white));
    color: rgb(var(--color-primary-500));
}

.c-get-token__btns .btn:hover {
    background-color: rgb(var(--color-secondary-500));
    color: rgb(var(--color-black));
}

.c-get-token__btns .btn:active {
    background-color: rgb(var(--color-secondary-400));
    color: rgb(var(--color-black));
}

.c-get-token__btns .btn:focus-visible {
    background-color: rgb(var(--color-secondary-500));
    color: rgb(var(--color-black));
}

:root {
    --header-height: 8rem;
    --header-height-scrolled: 7.2rem;
}

@media (min-width: 1200px) {
    :root {
        --header-height: 12.8rem;
        --header-height-scrolled: 8rem;
    }
}

@media (min-width: 1200px) {
    :root {
        --header-height: 14.8rem;
        --header-height-scrolled: 8rem;
    }
}

.c-header-placeholder {
    height: var(--header-height);
}

.c-header {
    pointer-events: none;
    position: fixed;
    z-index: 2000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    --header-mobile-bar-bg: var(--color-black);
    --header-mobile-bar-color: var(--color-white);
    --header-mobile-menu-bg: transparent;
    --header-mobile-menu-color: var(--color-white);
    --header-mobile-menu-bg-hover: transparent;
    --header-mobile-menu-color-hover: var(--color-secondary-500);
    --header-mobile-bg: var(--color-black);
    --header-mobile-color: var(--color-white);
    --header-mobile-link: var(--color-white);
    --header-mobile-link-hover: var(--color-secondary-500);
    --header-mobile-link-active: var(--color-secondary-500);
    --header-desktop-bg: var(--color-black);
    --header-desktop-color: var(--color-white);
    --header-desktop-link: var(--color-white);
    --header-desktop-link-hover: var(--color-secondary-500);
    --header-desktop-link-active: var(--color-secondary-500);
    --header-desktop-submenu-bg: var(--color-white);
    --header-desktop-submenu-color: var(--color-black);
    --header-desktop-submenu-link: var(--color-black);
    --header-desktop-submenu-link-hover: var(--color-secondary-500);
    --header-desktop-submenu-link-active: var(--color-secondary-500);
}

@media (min-width: 1200px) {
    html:not([data-scrolled=true]) .c-header {
        --header-desktop-bg: transparent;
    }
}

html:not([data-scrolled=true]):not([data-header=active]) .c-header {
    --header-mobile-bar-bg: transparent;
}

[data-header=active] .c-header,
.c-header.sticky {
    position: fixed;
    top: 0;
}

@media (min-width: 1200px) {
    .c-header-mobile-bar,
    .c-header-mobile {
        display: none !important;
    }
}

@media (max-width: 1199.98px) {
    .c-header-desktop,
    .c-header-desktop-nav-sticky {
        display: none !important;
    }
}

.c-header-desktop {
    flex: 0 0 auto;
    pointer-events: auto;
    background-color: rgb(var(--header-desktop-bg));
    color: rgb(var(--header-desktop-color));
    height: var(--header-height);
    display: flex;
    align-items: center;
    transition-property: background-color, border-color, color, margin-top, height, box-shadow;
    transition-duration: 250ms;
    transition-timing-function: ease;
    transform: translate3d(0, 0, 0);
}

[data-scrolled=true] .c-header-desktop {
    height: var(--header-height-scrolled);
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
}

.c-header-desktop__wrapper {
    position: relative;
    z-index: 200;
}

.c-header-desktop__wrapper {
    flex: 1 1 100%;
    min-width: 0;
    width: 100%;
}

.c-header-desktop__container {
    max-width: 170rem;
}

.c-header-desktop__row {
    display: flex;
    align-items: center;
}

.c-header-desktop__logo {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    margin-right: 1.6rem;
}

.c-header-desktop__logo a {
    flex: 0 0 auto;
    min-width: 0;
    max-width: 100%;
    display: block;
    transition: color 250ms ease;
}

.c-header-desktop__logo a:hover, .c-header-desktop__logo a:focus-visible {
    color: rgb(var(--color-quaternary-500));
}

.c-header-desktop__logo img,
.c-header-desktop__logo svg {
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    height: 6.4rem;
}

@media (min-width: 1400px) {
    .c-header-desktop__logo img,
    .c-header-desktop__logo svg {
        height: 8.2rem;
    }
}

[data-scrolled=true] .c-header-desktop__logo img,
[data-scrolled=true] .c-header-desktop__logo svg {
    max-height: 5.6rem;
}

.c-header-desktop__nav {
    margin-left: auto;
    flex: 0 1 auto;
    min-width: 0;
    max-width: 100%;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 2.6rem;
}

.c-header-desktop__nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 1;
}

.c-header-desktop__nav ul > li {
    position: relative;
    z-index: 1;
    white-space: normal;
    word-break: normal;
}

.c-header-desktop__nav > ul {
    display: flex;
    align-items: center;
    position: relative;
}

.c-header-desktop__nav > ul > li {
    min-width: 0;
    max-width: 100%;
    flex: 0 1 auto;
}

.c-header-desktop__nav > ul > li > a {
    max-width: 100%;
    display: block;
    padding: 0.8rem 1.2rem;
    color: rgb(var(--header-desktop-link));
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition-property: background-color, color;
    transition-duration: 250ms;
    transition-timing-function: ease;
}

@media (min-width: 1400px) {
    .c-header-desktop__nav > ul > li > a {
        padding: 0.8rem 1.6rem;
    }
}

.c-header-desktop__nav > ul > li > a:not(:only-child)::after {
    content: " " "\e901";
    font-family: "icomoon";
    font-size: 1em;
    line-height: 1;
    vertical-align: -0.08125em;
}

.c-header-desktop__nav > ul > li > a:hover, .c-header-desktop__nav > ul > li > a:focus-visible {
    color: rgb(var(--header-desktop-link-hover));
}

.c-header-desktop__nav > ul > li > ul {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: auto;
    min-width: 100%;
    background-color: rgb(var(--header-desktop-submenu-bg));
    color: rgb(var(--header-desktop-submenu-color));
    padding: 0.8rem 0;
    box-shadow: inset 0 0 0.8rem rgba(var(--color-gray-900), 0.04);
}

.c-header-desktop__nav > ul > li > ul > li > a {
    display: block;
    padding: 0.8rem 2rem;
    color: rgb(var(--header-desktop-submenu-link));
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 25ch;
    transition-property: background-color, color;
    transition-duration: 250ms;
    transition-timing-function: ease;
}

.c-header-desktop__nav > ul > li > ul > li > a:hover, .c-header-desktop__nav > ul > li > ul > li > a:focus-visible {
    color: rgb(var(--header-desktop-submenu-link-hover));
}

.c-header-desktop__nav > ul > li > ul > li > a[aria-current] {
    color: rgb(var(--header-desktop-submenu-link-active));
}

.c-header-desktop__nav > ul > li:hover > ul,
.c-header-desktop__nav > ul > li:focus-within > ul {
    display: block;
}

.c-header-desktop__nav > ul > li > ul > li > ul {
    display: none;
    position: absolute;
    top: -0.8rem;
    left: 100%;
    right: auto;
    background-color: rgb(var(--header-desktop-submenu-bg));
    color: rgb(var(--header-desktop-submenu-color));
    padding: 0.8rem 0;
    box-shadow: inset 0 0 0.8rem rgba(var(--color-gray-900), 0.04);
}

.c-header-desktop__nav > ul > li > ul > li:hover > ul,
.c-header-desktop__nav > ul > li > ul > li:focus-within > ul {
    display: block;
}

.c-header-desktop__nav > ul > li > ul > li > ul > li > a {
    display: block;
    padding: 0.8rem 2rem;
    color: rgb(var(--header-desktop-submenu-link));
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 25ch;
    transition-property: background-color, color;
    transition-duration: 250ms;
    transition-timing-function: ease;
}

.c-header-desktop__nav > ul > li > ul > li > ul > li > a:hover, .c-header-desktop__nav > ul > li > ul > li > ul > li > a:focus-visible {
    color: rgb(var(--header-desktop-submenu-link-hover));
}

.c-header-desktop__nav > ul > li > ul > li > ul > li > a[aria-current] {
    color: rgb(var(--header-desktop-submenu-link-active));
}

.c-header-desktop__nav > ul ul ul ul {
    display: none !important;
}

.c-header-desktop__nav:last-of-type > ul > li:nth-last-child(-n+2) > li > ul {
    left: auto;
    right: 0;
}

.c-header-desktop__nav:last-of-type > ul > li:nth-last-child(-n+2) > li > ul > li > ul {
    left: auto;
    right: 100%;
}

.c-header-desktop__btns {
    margin-left: 3.2rem;
}

@media (min-width: 1400px) {
    .c-header-desktop__btns {
        margin-left: 4rem;
    }
}

@media (min-width: 1600px) {
    .c-header-desktop__btns {
        margin-left: 4.8rem;
    }
}

@media (max-width: 1199.98px) {
    .c-header {
        transition-property: background-color, color, opacity;
        transition-duration: 250ms;
        transition-timing-function: ease;
        transform: translate3d(0, 0, 0);
    }

    .c-header::before {
        content: "";
        position: fixed;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgb(var(--header-mobile-bg));
        pointer-events: none;
        opacity: 0;
        transition-property: background-color, color, opacity;
        transition-duration: 250ms;
        transition-timing-function: ease;
    }

    .c-header-mobile-bar {
        position: relative;
        z-index: 10;
        pointer-events: auto;
        display: flex;
        align-items: center;
        background-color: rgb(var(--header-mobile-bar-bg));
        color: rgb(var(--header-mobile-bar-color));
        transition-property: background-color, color, margin-top;
        transition-duration: 250ms;
        transition-timing-function: ease;
        transform: translate3d(0, 0, 0);
    }

    .c-header-mobile-bar__row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        min-height: var(--header-height);
        padding: 0.8rem 2.4rem;
        transition: min-height 250ms ease;
    }

    [data-scrolled=true] .c-header-mobile-bar__row {
        min-height: var(--header-height-scrolled);
    }

    .c-header-mobile-bar__logo {
        flex: 0 0 auto;
        margin-right: auto;
        display: flex;
        align-items: center;
    }

    .c-header-mobile-bar__logo a {
        display: block;
        flex: 0 0 auto;
        transition: color 250ms ease;
    }

    .c-header-mobile-bar__logo a:hover, .c-header-mobile-bar__logo a:focus-visible {
        color: rgb(var(--header-mobile-menu-color-hover));
    }

    .c-header-mobile-bar__logo img,
    .c-header-mobile-bar__logo svg {
        display: block;
        width: auto;
        height: 100%;
        max-width: 100%;
        max-height: 4rem;
    }

    .c-header-mobile-bar__toggle {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 2.4rem;
        width: 4rem;
        height: 4rem;
        background-color: rgb(var(--header-mobile-menu-bg));
        color: rgb(var(--header-mobile-menu-color));
        transition-property: background-color, color;
        transition-duration: 250ms;
        transition-timing-function: ease;
    }

    .c-header-mobile-bar__toggle:hover, .c-header-mobile-bar__toggle:focus-visible {
        background-color: rgb(var(--header-mobile-menu-bg-hover));
        color: rgb(var(--header-mobile-menu-color-hover));
    }

    .c-header-mobile {
        position: relative;
        z-index: 5;
        transform: translateY(-3.2rem);
        opacity: 0;
        pointer-events: none;
        flex: 1 1 100%;
        min-height: 0;
        max-height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        transition-property: min-height, background-color, color, opacity, transform;
        transition-duration: 250ms;
        transition-timing-function: ease;
    }

    [data-header=active] {
        overflow: hidden;
    }

    [data-header=active] .c-header {
        --header-mobile-bar-bg: transparent;
        overflow-y: scroll;
        pointer-events: auto;
        background-color: rgb(var(--header-mobile-bg));
        color: rgb(var(--header-mobile-color));
    }

    [data-header=active] .c-header::before {
        opacity: 1;
    }

    [data-header=active] .c-header-mobile {
        transform: translateY(0);
        opacity: 1;
        pointer-events: auto;
    }

    .c-header-mobile__nav {
        flex: 0 0 auto;
        padding: 3.4rem 0;
        margin-top: auto;
        margin-bottom: auto;
        font-family: var(--font-family-heading);
        font-size: 2rem;
        font-weight: 500;
        line-height: 2;
        text-align: center;
    }

    .c-header-mobile__nav ul {
        list-style: none;
        padding: 0;
        margin: 0;
        z-index: 1;
        position: relative;
    }

    .c-header-mobile__nav ul > li {
        flex: 0 0 auto;
    }

    .c-header-mobile__nav ul > li > a {
        display: block;
        padding: 0.8rem 2.4rem;
        color: rgb(var(--header-mobile-link));
        transition-property: background-color, color;
        transition-duration: 250ms;
        transition-timing-function: ease;
        position: relative;
        z-index: 1;
    }

    .c-header-mobile__nav ul > li > a:hover, .c-header-mobile__nav ul > li > a:focus-visible {
        color: rgb(var(--header-mobile-link-hover));
    }

    .c-header-mobile__nav ul > li > a[aria-currentcurrent] {
        color: rgb(var(--header-mobile-link-hover));
    }

    .c-header-mobile__nav ul > li > a .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 4.8rem;
    }

    .c-header-mobile__nav [data-hideable-nav-item] > ul {
        padding-left: 2.4rem;
        padding-bottom: 2.4rem;
    }

    .c-header-mobile__nav [data-hideable-nav-item=inactive] > ul {
        display: none;
    }

    .c-header-mobile__btns {
        padding: 2.4rem;
    }
}

.c-header-desktop__social {
    margin-left: 1.6rem;
}

.c-footer__social ul,
.c-header-mobile__social ul,
.c-header-desktop__social ul {
    list-style: none;
    display: flex;
    margin: -0.4rem;
    padding: 0;
}

.c-footer__social ul > li,
.c-header-mobile__social ul > li,
.c-header-desktop__social ul > li {
    flex: 0 0 auto;
    padding: 0.4rem;
}

.c-footer__social ul > li > a,
.c-header-mobile__social ul > li > a,
.c-header-desktop__social ul > li > a {
    display: flex;
    padding: 0.6rem;
    font-size: 2rem;
    height: 3.6rem;
    min-width: 3.6rem;
    align-items: center;
    justify-content: center;
    transition-property: background-color, color, border-color;
    transition-duration: 250ms;
    transition-timing-function: ease;
}

.c-footer__social ul > li > a img,
.c-header-mobile__social ul > li > a img,
.c-header-desktop__social ul > li > a img {
    display: block;
    height: 1em;
    width: auto;
}

.c-header-mobile__social {
    margin-right: 1.2rem;
}

.c-header-mobile__social ul > li > a {
    font-size: 1.8rem;
    min-width: 3.2rem;
    height: 3.2rem;
    padding: 0.4rem;
}

@media (max-width: 350px) {
    .c-header-mobile__social ul > li > a {
        font-size: 1.7rem;
        min-width: 2.8rem;
        height: 2.8rem;
    }
}

.c-header-mobile__social + .c-header-mobile__btns {
    padding-top: 0;
}

.c-header-mobile__btns + .c-header-mobile__social {
    padding-top: 0;
}

.c-social-media {
    margin-top: 4.8rem;
}

@media (min-width: 768px) {
    .c-social-media {
        margin-top: 6.4rem;
    }
}

.c-social-media__box-wrapper {
    overflow: visible;
    height: 100%;
}

.c-social-media__box-wrapper::before {
    padding-top: 56.25%;
}

@media (min-width: 640px) {
    .c-social-media__box-wrapper::before {
        padding-top: 70.1754385965%;
    }
}

.c-social-media__box-wrapper .block-ratio__link:hover ~ .block-ratio__content, .c-social-media__box-wrapper .block-ratio__link:focus-visible ~ .block-ratio__content {
    border-color: rgb(var(--color-secondary-500));
    box-shadow: 0 0.3rem 3rem rgba(var(--color-secondary-500));
}

.c-social-media__box-wrapper .block-ratio__content {
    background-color: rgb(var(--color-black));
    border: 0.2rem solid rgb(var(--color-primary-500));
    box-shadow: 0 0.3rem 3rem rgba(var(--color-primary-500));
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: clamp(2.4rem, 6%, 13.6rem) clamp(2rem, 8%, 13.6rem);
    transition-property: color, background-color, border-color, box-shadow;
    transition-duration: 250ms;
    transition-timing-function: ease;
}

.c-social-media__box-wrapper .block-ratio__content img {
    display: block;
    height: 7.2rem;
    width: auto;
    max-width: 80%;
    object-fit: contain;
    object-position: center;
    margin-bottom: 3.4rem;
}

@media (min-width: 768px) {
    .c-social-media__box-wrapper .block-ratio__content img {
        height: 10.2rem;
        margin-bottom: 4.4rem;
    }
}

@media (min-width: 1200px) {
    .c-social-media__box-wrapper .block-ratio__content img {
        height: 13.2rem;
        margin-bottom: 5.4rem;
    }
}

.c-social-media__box-description {
    min-width: 0;
    max-width: calc(100% + 0.8rem);
    font-size: 0.75rem;
    word-break: break-word;
}

@media (min-width: 414px) {
    .c-social-media__box-description {
        font-size: 0.8rem;
    }
}

@media (min-width: 1200px) {
    .c-social-media__box-description {
        font-size: 1rem;
    }
}

.c-social-media__box-description span {
    text-decoration: underline;
    transition: color 250ms ease;
    color: rgb(var(--color-secondary-500));
}







